// @flow

import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";
const buttonType = {
  button: "button",
  reset: "reset",
  submit: "submit"
};

const buttonTheme = {
  primary: "button__primary",
  secondary: "button__secondary",
  danger: "button__danger",
  rounded: "rounded",
  image: "button__image",
  text: "button__text-only"
};

const buttonSize = {
  small: "button--small",
  medium: "button--medium",
  large: "button--large"
};

type Props = {
  type: string,
  theme: string,
  size: string,
  onClick: Function,
  children?: any,
  className: string,
  disabled: boolean,
  dataToggle: string,
  dataTarget: string,
  unrounded: boolean,
  loading: boolean
};

class Button extends React.Component<Props> {
  static defaultProps = {
    type: "",
    theme: "",
    size: "",
    onClick: () => {},
    children: null,
    className: "",
    disabled: false,
    dataToggle: "",
    dataTarget: "",
    unrounded: false,
    loading: false
  };

  render() {
    const {
      type,
      onClick,
      children,
      theme,
      size,
      className,
      loading,
      disabled,
      dataTarget,
      dataToggle,
      unrounded,
      buttonLoading
    } = this.props;

    const classProps: string = classnames(
      "button",
      buttonSize[size],
      buttonTheme[theme],
      buttonType[type],
      {
        "button--loading": loading
      },
      {
        "button--unrounded": unrounded
      },
      className
    );

    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={classProps}
        data-target={dataTarget}
        data-toggle={dataToggle}
      >
        {buttonLoading == true ? (
          <i className="fa fa-spinner fa-spin m-r-10" />
        ) : null}
        {children}
      </button>
    );
  }
}

export default connect(
  null,
  null
)(Button);
