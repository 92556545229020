// @flow
import React,{Fragment} from 'react'
import {connect} from 'react-redux'
import TosContent from '../partials/TosContent'
import { Link } from 'react-router-dom'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import $ from 'jquery'
class TermOfServicesNew extends React.Component<Props>{
    constructor(props) {
    super(props)

    // bindAll(this, [
    //  'handleCheckInterviewCode'
    // ])
  }
    componentDidMount() {
    window.scrollTo(0, 0)
        $('#public').attr('id','not-public')
    }

  componentWillUnmount(){
    $('#not-public').attr('id','public')
  }
    render(){
        return(
            <div id="misc">
            <HeaderNewClear />
            <div className="pad-top desktop-hide" />
            <section className='sec-con'>
              <div className="misc-tit">Terms of Service.</div>
              <ul className="list-misc">
                <li>
                  Terms of service
                  <p>Please read the terms of service (the "<strong>Terms of Service</strong>") set forth below, as they apply to your access and use of our services, including our various websites, APIs, email notifications, applications buttons and widgets (the "<strong>Services</strong>"), and any information, text, graphics, photos or other materials uploaded, downloaded or appearing on the Services (collectively referred to as the "<strong>Content</strong>"). Your access to and use of the Services signifies your acceptance of these Terms of Service and agreement to be bound by them and any and all other applicable terms referenced herein absolutely.</p>
                </li>
                <li>
                  Other applicable terms
                  <ul>
                    <li>These Terms of Service refer to the additional terms in our Privacy Policy Statement, which also apply to your use of our Services.</li>
                    <li>Our Privacy Policy Statement sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our Services, you consent to such processing and you warrant that all data provided by you is accurate. You understand that through your use of the Services you consent to the collection and use (as set forth in the Privacy Policy) of this information, including the transfer of this information both within and outside of Singapore for storage, processing and use by us. As part of providing you the Services, we may need to provide you with certain communications, such as service announcements and administrative messages. These communications are considered part of the Services and your <a href="https://astrnt.co/">http://www.astrnt.co/</a> account, which you may not be able to opt-out from receiving.</li>
                  </ul>
                </li>
                <li>
                  Information about us
                  <p>The Services are provided and operated by <strong>Astronaut Technologies Pte. Ltd.</strong> We are a limited liability company registered in Singapore with Unique Entity Number 201531470Z and have our registered office at 02-19, 78 Moh Guan Terrace, Singapore, Singapore.</p>
                </li>
                <li>
                  Changes of terms
                  <p>We may amend the Terms of Service from time to time at our sole discretion without notice or liability to you. By continuing to use the Services following such amendments to the Terms of Service, you agree to be bound by such amendments.</p>
                </li>
                <li>
                  Changes to our services
                  <p>We may amend the Terms of Service from time to time at our sole discretion without notice or liability to you. By continuing to use the Services following such amendments to the Terms of Service, you agree to be bound by such amendments.</p>
                  <ul>
                    <li>The Services that we provide are always evolving and the form and nature of the Services that we provide may change from time to time without prior notice to you. In addition, we may stop (permanently or temporarily) providing the Services (or any features within the Services) to you or to users generally and not be able to provide you with prior notice. We also retain the right to create limits on use and storage at our sole discretion at any time and without prior notice to you.</li>
                    <li>We make no representations, warranties or guarantees, whether express or implied, that our Services or any content on our site is accurate, complete, up-to-date or free from errors or omissions.</li>
                  </ul>
                </li>
                <li>
                  Accessing our services
                  <ul>
                    <li>Our Services are made available free of charge.</li>
                    <li>We do not guarantee that our Services, or any content, will always be available or be uninterrupted. Access to our Services is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our Services without notice. We will not be liable to you if for any reason our Services are unavailable at any time or for any period.</li>
                    <li>You are responsible for making all arrangements necessary for you to have access to our Services.</li>
                    <li>You are also responsible for ensuring that all persons who access our Services through your internet connection are aware of these Terms of Service and other applicable terms and conditions, and that they comply with them.</li>
                  </ul>
                </li>
                <li>
                  Your account and password
                  <ul>
                    <li>You are responsible for safeguarding the password that you use to access the Services and for any activities or actions under your password. We encourage you to use "strong" passwords (passwords that use a combination of upper and lower case letters, numbers and symbols) with your account. You must treat such information as confidential. You must not disclose it to any third party. <strong>Astronaut Technologies Pte. Ltd.</strong> cannot and will not be liable for any loss or damage arising from your failure to comply with the above.</li>
                    <li>We have the right to disable any password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these Terms of Service.</li>
                    <li>If you know or suspect that anyone other than you knows your password, you must promptly notify us at <a href="mailto:help@astrnt.co">help@astrnt.co</a>.</li>
                    <li>You are also responsible for ensuring that all persons who access our Services through your internet connection are aware of these Terms of Service and other applicable terms and conditions, and that they comply with them.</li>
                  </ul>
                </li>
                <li>
                  Your licence to use the services
                  <p><strong>Astronaut Technologies Pte. Ltd.</strong> gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive licence to use the software that is provided to you by us as part of the Services. This licence is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by <strong>Astronaut Technologies Pte. Ltd.</strong>, in the manner permitted by these Terms of Service.</p>
                </li>
                <li>
                  Our Intellectual property rights
                  <ul>
                    <li>All intellectual property rights subsisting in respect of the Services belong to <strong>Astronaut Technologies Pte. Ltd.</strong> or have been lawfully licensed to <strong>Astronaut Technologies Pte. Ltd.</strong> for use in connection with the Services. All rights under applicable laws are hereby reserved. You are not allowed to upload, post, publish, reproduce, transmit or distribute in any way any component of the website itself or create derivative works with respect thereto, as the website is copyrighted under applicable laws.</li>
                    <li>You agree that we are free to use, disclose, adopt and modify all and any ideas, concepts, knowhow, proposals, suggestions, comments and other communications and information provided by you to us (the "<strong>Feedback</strong>") in connection with the Services and/or your use of the Services without any payment to you. You hereby waive and agree to waive all and any rights and claims for any consideration, fees, royalties, charges and/or other payments in relation to our use, disclosure, adoption and/or modification of any or all of your Feedback.</li>
                  </ul>
                </li>
                <li>
                  Your rights
                  <ul>
                    <li>You retain your rights to any Content you submit, post or display on or through the Services. By submitting, posting or displaying Content on or through the Services, you grant us a worldwide, non-exclusive, royalty-free licence (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such Content in any and all media or distribution methods (now known or later developed).</li>
                    <li>You agree that this licence includes the right for us to provide, promote, and improve the Services and to make Content submitted to or through the Services available to other companies, organisations or individuals who partner with <strong>Astronaut Technologies Pte. Ltd.</strong> for the syndication, broadcast, distribution or publication of such Content on other media and services, subject to our terms and conditions for such Content use.</li>
                    <li>Such additional uses by <strong>Astronaut Technologies Pte. Ltd.</strong> or other companies, organisations or individuals who partner with <strong>Astronaut Technologies Pte. Ltd.</strong> may be made with no compensation paid to you with respect to the Content that you submit, post, transmit or otherwise make available through the Services.</li>
                    <li>We may modify or adapt your Content in order to transmit, display or distribute it over computer networks and in various media and/or make changes to your Content as are necessary to conform and adapt that Content to any requirements or limitations of any networks, devices, services or media.</li>
                    <li>You are responsible for your use of the Services, for any Content you provide, and for any consequences thereof, including the use of your Content by other users and our third party partners. You understand that your Content may be syndicated, broadcast, distributed, or published by our partners and if you do not have the right to submit Content for such use, it may subject you to liability. <strong>Astronaut Technologies Pte. Ltd.</strong> will not be responsible or liable for any use of your Content by <strong>Astronaut Technologies Pte. Ltd.</strong> in accordance with these Terms. You represent and warrant that you have all the rights, power and authority necessary to grant the rights granted herein to any Content that you submit.</li>
                  </ul>
                </li>
                <li>
                  Limited liability and warranty
                  <p>Please read this section carefully since it limits the liability of <strong>Astronaut Technologies Pte. Ltd.</strong> and its parents, subsidiaries, affiliates, related companies, officers, directors, employees, agents, representatives, partners, and licensors (collectively, the "<strong>Entities of Astronaut Technologies Pte. Ltd.</strong>"). Each of the subsections below only applies up to the maximum extent permitted under applicable law. Some jurisdictions do not allow the disclaimer of implied warranties or the limitation of liability in contracts, and as a result the contents of this section may not apply to you. Nothing in this section excludes or limits our liability for death or personal injury arising from our negligence or is intended to limit any rights you may have which may not be lawfully limited. All Information is for your general reference only. We do not accept any responsibility whatsoever in respect of such information.</p>
                  <ul>
                    <li><strong>YOUR ACCESS TO AND USE OF OUR SERVICES IS AT YOUR SOLE RISK AND IS PROVIDED “AS IS”, “AS AVAILABLE”. THE SERVICES ARE FOR YOUR PERSONAL USE ONLY AND THE ENTITIES OF Astronaut Technologies Pte. Ltd. MAKE NO REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES ON MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE OR NON-INFRINGEMENT.</strong></li>
                    <li><strong>THE ENTIRE LIABILITY OF THE ENTITIES OF Astronaut Technologies Pte. Ltd. ENTITIES AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE SERVICES OR OTHERWISE, IS RE-PERFORMANCE OF DEFECTIVE SERVICES. IN JURISDICTIONS WHICH DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN TYPES OF LIABILITY, OUR LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW. WE DO NOT ENDORSE, WARRANT OR GUARANTEE ANY MATERIAL, PRODUCT OR SERVICE OFFERED THROUGH US OR OUR SERVICES. WE ARE NOT AND WILL NOT BE A PARTY TO ANY TRANSACTION BETWEEN YOU AND ANY THIRD PARTY.</strong></li>
                    <li><strong>IN NO EVENT SHALL THE AGGREGATE LIABILITY OF THE ENTITIES OF Astronaut Technologies Pte. Ltd. EXCEED ONE HUNDRED SINGAPORE DOLLARS (SGD 100).</strong></li>
                    <li>
                      The Entities of <strong>Astronaut Technologies Pte. Ltd.</strong> shall not guarantee or assume any responsibility that:
                      <ul>
                        <li>the information presented in our Services is accurate, adequate, current or reliable, or may be used for any purpose other than for general reference;</li>
                        <li>the information presented in our Services is free of defect, error, omission, virus or anything which may change, erase, add to or damage your software, data or equipment;</li>
                        <li>messages sent through the internet including in connection with the services will be free from interception, corruption, error, delay or loss;</li>
                        <li>access to the Services will be available or be uninterrupted;</li>
                        <li>use of the Services will achieve any particular result; or</li>
                        <li>defects in the Services will be corrected.</li>
                      </ul>
                    </li>
                    <li>Without limiting the generality of the foregoing, in no event will the Entities of <strong>Astronaut Technologies Pte. Ltd.</strong> be liable to you or any other person for any direct, indirect, incidental, special, punitive or consequential loss or damages, including any loss of business, profit, goodwill or reputation arising out of any use, or inability to use, the information or the services, even if any of the Entities of <strong>Astronaut Technologies Pte. Ltd.</strong> has been advised of the possibility of such loss or damages.</li>
                    <li>You will exercise and rely solely on your own skill and judgment in your use and interpretation of the information and use of the services. You are responsible to ensure that your use of the information and the Services complies with all applicable legal requirements.</li>
                    <li>Without prejudice to the foregoing, if your use of the Services does not proceed satisfactorily and/or where applicable you do not receive appropriate responses to such use from us, as set out in these Terms of Service or otherwise, you are advised to contact us at <a href="mailto:help@astrnt.co">help@astrnt.co</a>. No such lack of response shall be deemed to constitute any acquiescence or waiver.</li>
                    <li>The limitation of liability contained in these Terms of Service will apply to the fullest extent permitted by applicable laws.</li>
                  </ul>
                </li>
                <li>
                  Our Intellectual property rights
                  <ul>
                    <li>All intellectual property rights subsisting in respect of the Services belong to <strong>Astronaut Technologies Pte. Ltd.</strong> or have been lawfully licensed to <strong>Astronaut Technologies Pte. Ltd.</strong> for use in connection with the Services. All rights under applicable laws are hereby reserved. You are not allowed to upload, post, publish, reproduce, transmit or distribute in any way any component of the website itself or create derivative works with respect thereto, as the website is copyrighted under applicable laws.</li>
                    <li>You agree that we are free to use, disclose, adopt and modify all and any ideas, concepts, knowhow, proposals, suggestions, comments and other communications and information provided by you to us (the "<strong>Feedback</strong>") in connection with the Services and/or your use of the Services without any payment to you. You hereby waive and agree to waive all and any rights and claims for any consideration, fees, royalties, charges and/or other payments in relation to our use, disclosure, adoption and/or modification of any or all of your Feedback.</li>
                  </ul>
                </li>
                <li>
                  Content on the services
                  <ul>
                    <li>All Content, whether publicly posted or privately transmitted, is the sole responsibility of the person who originated such Content.</li>
                    <li>You warrant that any such contribution does comply with those standards, and you will be liable to us and indemnify us for any breach of that warranty and you will be responsible for any loss or damage we suffer as a result of your breach of warranty.</li>
                    <li>Any content you upload to our site will be considered non-confidential and non-proprietary, and we have the right to use, copy, distribute and disclose to third parties any such content for any purpose. We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right to privacy. We will not be responsible, or liable to any third party, for the content or accuracy of any content posted by you or any other user of our site.</li>
                    <li>The views expressed by other users on our site do not represent our views or values. We do maintain the right to remove any posting you make on our site if, in our opinion, your post does not comply with our content standards. We do not endorse, support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any Content or communications posted via the Services or endorse any opinions expressed via the Services. You understand that by using the Services, you may be exposed to Content that might be offensive, harmful, inaccurate or otherwise inappropriate, or in some cases, postings that have been mislabelled or are otherwise deceptive.</li>
                    <li>Under no circumstances will we be liable in any way for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind, incurred directly or indirectly as a result of the use of the Services by any third party, including without limitation any defamatory, offensive, or illegal conduct of the third party, or the use of any Content posted, emailed, transmitted, or otherwise made available via the Services or broadcast elsewhere.</li>
                    <li>We may not monitor or control the Content posted via the Services and, we cannot take responsibility for such Content. Any use or reliance on any Content or materials posted via the Services or obtained by you through the Services is at your own risk.</li>
                  </ul>
                </li>
                <li>
                  Content copyright policy
                  <ul>
                    <li><strong>Astronaut Technologies Pte. Ltd.</strong> respects the intellectual property rights of others and expects users of the Services to do the same. We will respond to notices of alleged copyright infringement that comply with applicable law and are properly provided to us. If you believe that your Content has been copied in a way that constitutes copyright infringement, please provide us with the following information: (i) a physical or electronic signature of the copyright owner or a person authorised to act on their behalf; (ii) identification of the copyrighted work claimed to have been infringed; (iii) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material; (iv) your contact information, including your address, telephone number, and an email address; (v) a statement by you that you have a good faith belief that use of the material in the manner complained of is not authorised by the copyright owner, its agent, or the law; and (vi) a statement that the information in the notification is accurate, and that you are authorised to act on behalf of the copyright owner.</li>
                    <li>We reserve the right to remove Content alleged to be infringing without prior notice, at our sole discretion, and without liability to you. In appropriate circumstances, we will also terminate a user's account if the user is determined to be a repeat infringer. Our address for notice of alleged copyright infringement appearing on the Services is <a href="mailto:help@astrnt.co">help@astrnt.co</a>.</li>
                  </ul>
                </li>
                <li>
                  Use of the services
                  <ul>
                    <li>We reserve the right at all times (but will not have an obligation) to remove or refuse to distribute any Content on the Services , to suspend or terminate users, and to reclaim usernames without liability to you. We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce the Terms of Service, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect the rights, property or safety of <strong>Astronaut Technologies Pte. Ltd.</strong> its users and the public.</li>
                    <li>We do not guarantee that our Services will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform in order to access our site. You should use your own virus protection software.</li>
                    <li>You may not do any of the following while accessing or using the Services: (i) access, tamper with, or use non-public areas of the Services, <strong>Astronaut Technologies Pte. Ltd.</strong>'s computer systems, or the technical delivery systems of <strong>Astronaut Technologies Pte. Ltd.</strong>'s providers; (ii) probe, scan, or test the vulnerability of any system or network or breach or circumvent any security or authentication measures; (iii) access or search or attempt to access or search the Services by any means (automated or otherwise) other than through our currently available, published interfaces that are provided by us (and only pursuant to those terms and conditions), (scraping the Services without our prior consent is expressly prohibited); (iv) forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Services to send altered, deceptive or false source-identifying information; or (v) interfere with, or disrupt, (or attempt to do so), the access of any user, host or network, including, without limitation, sending a virus, trojan, worm, logic bomb or other material which is malicious or technologically harmful, overloading, flooding, spamming, mail-bombing the Services, or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Services.</li>
                    <li>We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Services will cease immediately.</li>
                  </ul>
                </li>
                <li>
                  Linking to us
                  <p>You may link to our Services provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link to our Services in any website that is not owned by you. We reserve the right to withdraw linking permission without notice.</p>
                </li>
                <li>
                  Third party links and resources in our site
                  <ul>
                    <li>The links from the Services may take you to other sites or services and you acknowledge and agree that <strong>Astronaut Technologies Pte. Ltd.</strong> has no responsibility for the accuracy or availability of any Information provided by third parties services and websites.</li>
                    <li>The Services may include advertisements, which may be targeted to the Content or information on the Services, queries made through the Services, or other information. The types and extent of advertising by <strong>Astronaut Technologies Pte. Ltd.</strong> on the Services are subject to change. In consideration for us granting you access to and use of the Services, you agree that <strong>Astronaut Technologies Pte. Ltd.</strong> and its third party providers and partners may place such advertising on the Services or in connection with the display of Content or information from the Services whether submitted by you or others.</li>
                    <li>Links to other websites and services do not constitute an endorsement by us of such websites or services, or the Information, products, advertising or other materials available made available by such third parties.</li>
                  </ul>
                </li>
                <li>
                  Indemnity
                  <p>You agree to defend, indemnify and hold us harmless from and against all liabilities, damages, claims, actions, costs and expenses (including without limitation legal fees), in connection with or arising from your breach of any of these Terms of Service and/or your use of the website. We may, if necessary, participate in the defence of any claim or action and any negotiations for settlement. No settlement which may adversely affect our rights or obligations shall be made without our prior written approval. We reserve the right, at our own expense and on notice to you, to assume exclusive defence and control of any claim or action.</p>
                </li>
                <li>
                  Severance
                  <p>The illegality, invalidity or unenforceability of any provision of these Terms of Service under the law of any jurisdiction shall not affect its legality, validity or enforceability under the laws of any other jurisdiction nor the legality, validity or enforceability of any other provision.</p>
                </li>
                <li>
                  Several users
                  <p>If there are two or more persons adhering to these Terms of Service as user, their liability under the Terms of Service is joint and several, and their rights are joint.</p>
                </li>
                <li>
                  Waiver
                  <p>No failure or delay by a party to exercise any right or remedy provided under this agreement or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy.</p>
                </li>
                <li>
                  Termination
                  <ul>
                    <li>The Terms of Service will continue to apply until terminated by either you or us as follows.</li>
                    <li>You may end your agreement with us at any time for any reason by deactivating your accounts and discontinuing your use of the Services. You do not need to specifically inform us when you stop using the Services.</li>
                    <li>We may suspend or terminate your accounts or cease providing you with all or part of the Services at any time for any reason, including, but not limited to, if we reasonably believe: (i) you have violated these Terms of Service or (ii) you create risk or possible legal exposure for us; or (iii) our provision of the Services to you is no longer commercially viable. We will make reasonable efforts to notify you by the email address associated with your account or the next time you attempt to access your account.</li>
                    <li>In all such cases, any provision of these Terms of Service that expressly or by implication is intended to come into or continue in force on or after termination of this agreement shall remain in full force and effect.</li>
                    <li>Nothing in this section shall affect our rights to change, limit or stop the provision of the Services without prior notice, as provided above in Clause 5 <strong>Changes to our services</strong>.</li>
                  </ul>
                </li>
                <li>
                  Governing law and jurisdiction
                  <p>These Terms of Service shall be governed by Singapore law. You agree to submit to the exclusive jurisdiction of the Singapore courts.</p>
                </li>
                <li>
                  Languages
                  <p>This agreement is drafted in the English language. If this agreement is translated into any other language, the English language version shall prevail.</p>
                </li>
              </ul>
              {/* <div className="last-update">Last updated: <strong>27 June 2016</strong></div> */}
            </section>
            <FooterNew />
          </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
    
  }
}


export default connect(mapStateToProps, null)(TermOfServicesNew)