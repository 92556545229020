import { List, Map, fromJS } from 'immutable'

const initFilterData = fromJS({
	page: '',
	selected_ids: [],
	is_update: false,
	sort: '',
	fullname: '',
	job_id:''
})

const initHiringTeamFilterData = fromJS({
	page: '',
	last_page: '',
	selected_ids: [],
	fullname: ''
})

const initFormData = Map({
	is_update: false,
	is_access_inc_jobs: false,
	is_access_existing_job: false,
	is_disable_fullname: false
})

const initNavigationData = Map({
	page: 1
})

const initUserEmail = Map({
	email: ''
})

export function usersHasErrored(state = false, action) {
	switch (action.type) {
		case 'USERS_HAS_ERRORED':
			return action.hasErrored
		default:
			return state
	}
}

export function usersSaveDataError(state = Map({}), action) {
	switch(action.type) {
		case 'USERS_SAVE_DATA_ERROR':
			return Map(action.errors)
		default:
			return state
	}
}

export function usersPagination(state = Map({}), action) {
	switch(action.type) {
		case 'USERS_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}

export function usersFilterData(state = initFilterData, action) {
	switch(action.type) {
		case 'USERS_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'USERS_ADD_FILTER_SELECTED_IDS_SUCCESS':
			return state.set('selected_ids', List(action.selected_ids))
		case 'USERS_ADD_FILTER_IS_UPDATE_SUCCESS':
			return state.set('is_update', action.is_update)
		case 'USERS_ADD_FILTER_SORT_SUCCESS':
			return state.set('sort', action.sort)
		case 'USERS_ADD_FILTER_FULLNAME_SUCCESS':
			return state.set('fullname', action.fullname)
		case 'USERS_ADD_FILTER_JOB_ID_SUCCESS':
			return state.set('job_id', action.job_id)
		case 'USERS_CLEAR_FILTER_DATA_SUCCESS':
			return fromJS({
				page: '',
				selected_ids: [],
				is_update: false,
				sort: '',
				fullname: '',
				job_id:''
			})
		default:
			return state
	}
}

export function usersHiringTeamFilterData(state = initHiringTeamFilterData, action) {
	switch(action.type) {
		case 'USERS_ADD_HIRING_TEAM_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'USERS_ADD_HIRING_TEAM_FILTER_LAST_PAGE_NUMBER_SUCCESS':
			return state.set('last_page', action.last_page)
		case 'USERS_ADD_HIRING_TEAM_FILTER_SELECTED_IDS_SUCCESS':
			return state.set('selected_ids', List(action.selected_ids))
		case 'USERS_ADD_HIRING_TEAM_FILTER_FULLNAME_SUCCESS':
			return state.set('fullname', action.fullname)
		case 'USERS_CLEAR_HIRING_TEAM_FILTER_DATA_SUCCESS':
			return fromJS({
				page: '',
				last_page: '',
				selected_ids: []
			})
		default:
			return state
	}
}

export function usersFormData(state = initFormData, action) {
	switch(action.type) {
		case 'USERS_ADD_FORM_IS_UPDATE_SUCCESS':
			return state.set('is_update', action.is_update)
		case 'USERS_ADD_FORM_IS_access_inc_jobs_SUCCESS':
			return state.set('is_access_inc_jobs', action.is_access_inc_jobs)
		case 'USERS_ADD_FORM_IS_ACCESS_EXISTING_JOB_SUCCESS':
			return state.set('is_access_existing_job', action.is_access_existing_job)
		case 'USERS_ADD_FORM_IS_DISABLE_FULLNAME_SUCCESS':
			return state.set('is_disable_fullname', action.is_disable_fullname)
		case 'USERS_CLEAR_FORM_DATA_SUCCESS':
			return Map({
				is_update: false,
				is_access_inc_jobs: false,
				is_access_existing_job: false,
				is_disable_fullname: false
			})
		default:
			return state
	}
}

export function users(state = List([]), action) {
	switch(action.type) {
		case 'USERS_FETCH_DATA_SUCCESS':
			return fromJS(action.users)
		default:
			return state
	}
}

export function usersHiringTeam(state = List([]), action) {
	switch(action.type) {
		case 'USERS_FETCH_DATA_HIRING_TEAM_SUCCESS':
			return fromJS(action.users)
		default:
			return state
	}
}

export function user(state = Map({}), action) {
	switch(action.type) {
		case 'USERS_GET_DETAIL_SUCCESS':
			return fromJS(action.user)
		default:
			return state
	}
}

export function userTemp(state = Map({}), action) {
	switch(action.type) {
		case 'USERS_GET_DETAIL_TEMP_SUCCESS':
			return fromJS(action.user)
		default:
			return state
	}
}

export function userIds(state = List([]), action) {
  switch(action.type) {
    case 'USERS_FETCH_IDS_SUCCESS':
    	return List(action.ids)
    default:
    	return state
  }
}


export function usersDropdown(state = List([]), action) {
	switch(action.type) {
		case 'USERS_FETCH_DATA_DROPDOWN_SUCCESS':
			return fromJS(action.users)
		default:
			return state
	}
}

export function usersNavigationData(state = initNavigationData, action) {
	switch(action.type) {
		case 'USERS_ADD_NAVIGATION_PAGE_SUCCESS':
			return state.set('page', action.page)
		default:
			return state
	}
}

export function userTempEmail(state = initUserEmail, action) {
	switch (action.type) {
		case 'USER_TEMP_EMAIL':
			return state.set('email',action.email)
		default:
			return state
	}
}

export function csrfToken(state = '', action) {
	switch (action.type) {
		case 'USERS_ADD_CSRF_TOKEN_SUCCESS':
			return action.csrf_token
		default:
			return state
	}
}
