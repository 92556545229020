// @flow

import React from 'react'
import { Link } from 'react-router-dom'
import logoAstrnt from '../../images/public/logo-astrnt-black.svg'
import { withRouter } from "react-router";
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import initializers from '../../dependencies/initializers'
type Props = {
	isNavbarFixed: boolean
};

class Header extends React.Component<Props> {
	constructor(props) {
		super(props)
		this.state = {
		menu: false
		};

		bindAll(this, [
			'handleRegisterLink',
			'toggleMenu'
			
		])
	}
	static defaultProps: Object;

	toggleMenu(){
    this.setState({ menu: !this.state.menu })
  }	

	handleLoginLink(e: Object): void {
		e.preventDefault()
			window.location = initializers.recruiter_host+'/auth/login'
	}

	handleRegisterLink(e: Object): void {
		e.preventDefault()
		const{
			history
		}=this.props
		
		history.push('/register')
		
	}

  render() {
	const show = (this.state.menu) ? "show" : "" ;
    return (
			<header>
				<nav className={`navbar public-page ${this.props.isNavbarFixed ? 'navbar-fixed-top' : ''}`}>
					<div className="container">
						<div className="navbar-header">
							<button type="button" className="navbar-toggle collapsed"onClick={this.toggleMenu} aria-expanded="false" aria-controls="navbar">
								<span className="sr-only">Toggle navigation</span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
							<Link
								to="/"
								className="navbar-brand">
								<img
									src={logoAstrnt}
									alt="ASTRNT" />
							</Link>
						</div>
						<div id="navbar" className={"navbar-collapse collapse"+ show}>
							<ul className="nav navbar-nav navbar-top navbar-right">
								<li className="dropdown">
									<a
										className="dropdown-toggle"
										data-toggle="dropdown"
										id="dropdownFor"
										role="button"
										aria-haspopup="true"
										aria-expanded="false">ASTRNT for.. <span className="caret"></span></a>
									<ul className="dropdown-menu" aria-labelledby="dropdownFor">
										<li><Link to={`/astronaut-for/campuses`}>Campuses</Link></li>
										<li><Link to={`/astronaut-for/professionals`}>Professionals</Link></li>
										<li><Link to={`/astronaut-for/technical-hiring`}>Technical hiring</Link></li>
										<li><Link to={`/astronaut-for/remote-and-field-hiring`}>Remote & Field hiring</Link></li>
										<li><Link to={`/astronaut-for/global-hiring`}>Global hiring</Link></li>
										<li><Link to={`/astronaut-for/partner-via-api`}>Partners via API</Link></li>
									</ul>
								</li>
								<li><Link to={`/how-it-works`}>How it works</Link></li>
								<li className="dropdown">
									<a
										className="dropdown-toggle"
										data-toggle="dropdown"
										id="dropdownFor"
										role="button"
										aria-haspopup="true"
										aria-expanded="false">Get started<span className="caret"></span></a>
									<ul className="dropdown-menu" aria-labelledby="dropdownFor">
										<li><Link to={{pathname: `/register`, query: { plan: 'es2t' }}}>Try for free</Link></li>
										<li><Link to={`/plans`}>Pricing</Link></li>
									</ul>
								</li>
								<li className="call-to-action">
									<button onClick={this.handleLoginLink} className="button button__primary">Login</button>
								</li>
								<li className="call-to-action">
									<button onClick={this.handleRegisterLink} className="button button__primary">Try For Free</button>
								</li>
							</ul>
						</div>
					</div>
				</nav>
			</header>
		)
  }
}

Header.defaultProps = {
	isNavbarFixed: true
}

const mapStateToProps = (state, ownProps) => {
	return {
		
	}
}

export default withRouter(connect(mapStateToProps,null)(Header))
