import axios from 'axios'
import NProgress from 'nprogress/nprogress.js'
import Promise from 'bluebird'
import initializers from '../../dependencies/initializers'

require('es6-promise').polyfill()

export function getFAQ(params, url, lang) {
	url = window.location.hostname
	if (url == 'astrnt.id') {
		lang = 'id';
	} else if (url == 'test.astrnt.id') {
		lang = 'id';
	} else {
		lang = 'en';
	}
	return (dispatch) => {

		return new Promise((resolve, reject) => {
			NProgress.start()
			axios({
				url: initializers.api_host+`/faq/filter`,
				timeout: 20000,
				method: 'post',
				responseType: 'json',
				data: {
					search: params,
					lang: lang,
				}
			})
			.then((response) => {
				let faq = response.data
				dispatch(getFAQSuccess(faq))

				NProgress.done()
				resolve(faq)
			})
			.catch((error) => {
				reject(error)
				NProgress.done()
			})
		})
	}
}

export function getFAQSuccess(faq) {
	return {
		type: 'PUBLIC_FAQ_FETCH_DATA_SUCCESS',
		faq
	}
}
