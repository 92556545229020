import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import imgFeatureList1 from '../../images/public/users.png'
import imgFeatureList2 from '../../images/public/star.png'
import imgFeatureList3 from '../../images/public/badge.png'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import BlogListNew from '../partials/BlogListNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'

import CallToActRegister from '../partials/CallToActRegister.jsx'
import BlogList from '../partials/BlogList.jsx'

import homeIMG from '../../images/solution-4-big.png'

import personReview from '../../images/person_nus.png'
import logoReview from '../../images/logo_nus.png'

import imgSec1 from '../../images/solution-4-sec1.png'
import imgSec2 from '../../images/solution-4-sec2.png'
import imgSec3 from '../../images/solution-4-sec3.png'

import travelokaLogo from '../../images/logo/traveloka.png'
import philipLogo from '../../images/logo/philip.png'
import bcaLogo from '../../images/logo/bca.png'
import mbsbLogo from '../../images/logo/mbsb.png'
import nusLogo from '../../images/logo/nus.png'
import shopeeLogo from '../../images/logo/shopee.png'
import bniLogo from '../../images/logo/bni.png'
import infocommLogo from '../../images/logo/infocomm.png'
import darwinboxLogo from '../../images/logo/darwinbox.png'
import homecreditLogo from '../../images/logo/homecredit.png'

import teamtailorLogo from '../../images/logo/teamtailor.png'
import workableLogo from '../../images/logo/workable.png'
import pulsifiLogo from '../../images/logo/pulsifi.png'
import talentdatalabsLogo from '../../images/logo/talentdatalabs.png'
import podiumLogo from '../../images/logo/podium.png'
import greenhouseLogo from '../../images/logo/greenhouse.png'

import imgSeamless from '../../images/seamless.png'

import imgCard1 from '../../images/pro-1.png'
import imgCard2 from '../../images/pro-2.png'
import imgCard3 from '../../images/pro-3.png'

import {getOSInfo , translate } from '../../helpers/global'

import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

class ArticlesProfessionalsNew extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const {
      globalGetLanguages,
      user
    } = this.props
    return (
      <div id="homepage">
        <HeaderNewClear isOnStatusFor="is-on" />
        <div className="section-top-solution relative double-triangle triangle-left" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='solution-top-box-hanging purple'>
                  <h1 className='m-t-0 m-b-16'>
                  Elevate Your Platform With our Technology
                  </h1>
                  <p className='m-b-0'>We empower job boards, ATS, HRIS, and assessment platforms to offer asynchronous video functionality without the complexities of building and maintaining it. ASTRNT provides collaborative or white-label solutions, serving as your trusted technology partner.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right flex-container flex-end align-items-center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={homeIMG} />
              </div>
            </div>
          </section>
          <div className='solution-back-top purple desktop-hide'></div>
        </div>
        <div className="section-white hide" style={{overflow: 'hidden'}}>
          <section>
            <div className="text-center review-slider solution">
              <p>"ASTRNT platform gave us a holistic view of each candidate to be surgically precise at selecting the very best talents for our NUS cohorts."</p>
              <div className='horizontal-item--between source-div telkom'>
                <div className='vertical-item--center'>
                  <img className='person-img' src={personReview} />
                  <div className='flex-column'>
                    <h5>Winston Wee</h5>
                    <h6>Head, Admissions for MSc Programs<br/>
                    National University of Singapore (NUS) Business School</h6>
                  </div>
                </div>
                <div className='vertical-item--center'>
                  <img className='img-100 logo-review' src={logoReview} />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgSec1} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging-right-sol force-aqua'>
                  <h1 className='m-t-0 m-b-16'>
                  Win-Win Solutions
                  </h1>
                  <p className='m-b-0'>Partnering with ATS, HRIS, job boards, and talent platforms, we create global revenue opportunities. Our expert team develops innovative solutions, focusing on helping SMEs and start-ups enhance their talent acquisition and management processes.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='navy-back-feature-right for-4 desktop-hide'></div>
        </div>
        <div className="section-white relative double-triangle triangle-left disable-small-triangle sol-sec2-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='red-box-hanging-left'>
                  <h1 className='m-t-0 m-b-16'>
                  Elevate Your Platform With our Technology
                  </h1>
                  <p className='m-b-0'>Our advanced API technology enables seamless integration, offering features like asynchronous video interviews, on-demand learning, skill verification, and data analysis. Our solutions reduces development costs, saves time to get you to market fast. With easy integration, you'll have asynchronous video capabilities in hours, allowing your team to focus on growth.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgSec2} />
              </div>
            </div>
          </section>
          <div className='navy-back-feature-left for-5-red desktop-hide'></div>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgSec3} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging-right-sol'>
                  <h1 className='m-t-0 m-b-16'>
                  We Make It Easy For You
                  </h1>
                  <p className='m-b-0'>With our platform, you can create customized asynchronous video experiences. Our APIs and SDKs guarantee flawless video delivery on all major browsers and devices, boosting accessibility and user engagement. Whether your goal is to enhance customer interactions, simplify communication, or improve educational content, our technology equips you for success.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='navy-back-feature-right for-5 desktop-hide'></div>
        </div>
        <FooterNew />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesProfessionalsNew)
