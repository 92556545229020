//Should consist languages Indo

export const id = {

    //MOSTLY USED MORE THAN ONCE
    'LOGIN':'Masuk',
    'LIFE':'Life',
    'PRICING':'Harga',
    'ASTRONAUT_FOR': 'ASTRNT untuk',
    'PRODUCTS': 'Produk',
    'COMPANY':'Perusahaan',
    'CANDIDATES':'Kandidat',
    'ABOUT':'Tentang Kami',
    'PARTNERS':'Kemitraan',
    'CONTACT':'Kontak',
    'HOMEPAGE_BUTTON_START_FREE_TRIAL':'Coba Gratis Sekarang',
    'HOMEPAGE_BUTTON_GET_ASTRNT_FOR_FREE':'Dapatkan ASTRNT.co Gratis',
    'HOMEPAGE_LABEL_NO_CREDIT_CARD':'Data kartu kredit tidak diperlukan.',
    'HOMEPAGE_BUTTON_REQUEST_DEMO' : 'Ajukan Demo',
    'ACCELERATE_VIDEO':'Mengakselerasi wawancara video & pengalaman kandidat untuk merek di seluruh dunia.',
    'EXPERIENCE_OUR_PLATFORM':'Coba langsung di platform kami',
    'PLANS':'Plans',
    'ESSENTIAL':'Essential',
    'ENTERPRISE':'Enterprise',
    'GET_FREE_TRIAL':'Get a free trial',
    'GET_ASTRNT_FOR_FREE':'Get ASTRNT For Free',
    'FEATURES':'Features',
    'ASSESS_TRACK_SELECT':'Assessment, Tracking & Selection',
    'APPLY_NOW':'Apply Now',
    'SEARCH':'Pencarian',
    'REGISTER':'Register',
   'SEARCH_PAGE_NOT_FOUND':'Maaf, kami tidak dapat menemukan halaman yang anda cari.',
   'SEARCH_PAGE_INCORRECT':'Sepertinya anda menulis url yang salah atau halaman yang anda cari tidak tersedia. Silahkan mencoba lagi atau kembali ke halaman utama.',
   'BACK_TO_HOME_PAGE':'Back to homepage',
   //END
    //END

   //start-Header


   'HEADER_CANDIDATES_INTERVIEWING': 'Anda Kandidat yang mengikuti wawancara? Klik Disini!',
   'HEADER_GRADUATE_HIRING':'Sarjana & Lulusan Baru',
   'HEADER_GRADUATE_HIRING_TEXT':'Identifikasi talenta terbaik lebih cepat melalui fitur video dan penilaian terpadu.',
   'HEADER_UNIVERSITY_TOOLS':'Perangkat Universitas',
   'HEADER_UNIVERSITY_TOOLS_TEXT':'Rekrutmen universitas & sekolah tinggi memerlukan solusi perangkat software handal sekelas enterprise seperti ASTRNT.',
   'HEADER_PROFESSIONAL':'Eksekutif & Profesional',
   'HEADER_PROFESSIONAL_TEXT':'Jika menggunakan ATS, anda bisa mengintegrasikannya dengan ASTRNT untuk memperkaya data serta tetap konsisten dan semakin cepat.',
   'HEADER_GOVERNMENTS':'Instansi Pemerintahan & BUMN',
   'HEADER_GOVERNMENTS_TEXT':'Bersaing dengan swasta untuk mendapatkan talenta terbaik melalui proses yang transparan, wawancara video, <em>fit & proper test</em>.',
   'HEADER_BLUE_COLLAR':'Karyawan & Buruh',
   'HEADER_BLUE_COLLAR_TEXT':'Lampaui data CV. Konfirmasi identitas kandidat, nilai kemampuan, & tentukan finalis cepat. Dari perangkat apapun, kapan saja, & kondisi internet apapun.',
   'HEADER_ASSESMENT_CERT':'Lembaga Penilaian & Sertifikasi',
   'HEADER_ASSESMENT_CERT_TEXT':'Tetap kompetitif di industry yang cepat beralih ke digital. Pilih kami sebagai rekan teknologi terpercaya.',
   'HEADER_TALENT_PLATFORM':'Platform Memerlukan Video',
   'HEADER_TALENT_PLATFORM_TEXT':'Bawa funsionalitas video asinkron ke platform Anda, tanpa kompleksitas teknis.',

   'HEADER_LEARN_MORE':'Pelajari lebih lanjut.',
   
   //end-Header

   //start page FooterNew
   'FOOTER_AUTOMATION':'Otomatisasi untuk mempercepat rekrutmen Anda!',

   'FOOTER_GRADUATE_HIRING':'Sarjana & Lulusan Baru',
   'FOOTER_UNIVERSITY_TOOLS':'Perangkat Universitas',
   'FOOTER_GOVERNMENTS':'Instansi Pemerintahan & BUMN',
   'FOOTER_PROFESSIONAL':'Eksekutif & Profesional',
   'FOOTER_BLUE_COLLAR':'Karyawan & Buruh',
   'FOOTER_ASSESMENT_CERT':'Lembaga Penilaian & Sertifikasi',
   'FOOTER_TALENT_PLATFORM':'Platform Memerlukan Video',
   'FOOTER_FAQ':'Cara Kerja & FAQ',
   'FOOTER_API_DOC':'Dokumentasi API',
   'FOOTER_TOS':'Ketentuan Layanan',
   'FOOTER_PRIVACY':'Kebijakan Data Pribadi',
   'FOOTER_LIFE_BLOG':'Life (Our Blog)',
   'FOOTER_CANDIDATE_SUPPORT':'Dukungan Kandidat',
   'FOOTER_COPYRIGHT':'Copyrights © 2024 ASTRNT All Rights Reserved.',
   'FOOTER_LANG':'Indonesia',

   //end page FooterNew

   //start-Homepagenew 
   'HOMEPAGE_BANNER_TITLE': 'Temukan bakat, mulai percakapan, dan berikan penawaran kerja secara cepat untuk',
   'HOMEPAGE_BANNER_TEXT': 'Berikan kenyamanan untuk kandidat Anda dengan menjawab pertanyaan asesmen & tes dalam berbagai format, termasuk video seluler. Mereka akan menjawab lebih cepat & menyukai brand Anda.',
   
   'HOMEPAGE_CARD_EXPERIENCE_TITLE':'Mengakselerasi wawancara video & pengalaman kandidat untuk merek di seluruh dunia.',
   'HOMEPAGE_CARD_EXPLORE_OUR_FEATURES': 'Jelajahi Fitur Kami Secara Mendetail',
   'HOMEPAGE_RENDER_BUTTON_FEATURE_1':'Wawancara Melalui Video',
   'HOMEPAGE_RENDER_BUTTON_FEATURE_2':'Penilaian Wawancara',
   'HOMEPAGE_RENDER_BUTTON_FEATURE_3':'Wawancara Terstruktur',
   'HOMEPAGE_RENDER_BUTTON_FEATURE_4':'Penilaian Yang Lengkap',
   'HOMEPAGE_RENDER_TITLE_FEATURE_1':'Pahami kandidat Anda',
   'HOMEPAGE_RENDER_TITLE_FEATURE_2':`Atur proses seleksi yang adil & efisien`,
   'HOMEPAGE_RENDER_TITLE_FEATURE_3':'Pertahankan struktur yang konsisten',
   'HOMEPAGE_RENDER_TITLE_FEATURE_4':'Asesmen lengkap sesuai kebutuhan Anda',
   'HOMEPAGE_RENDER_TEXT_FEATURE_1':'Sistem video terintegrasi kami bekerja pada semua perangkat yang memiliki kamera. Video asinkron membantu kandidat secara masal melakukan interview dan ujian dalam waktu yang sama, menghemat waktu organisasi dan meningkatkan kemampuan.',
   'HOMEPAGE_RENDER_TEXT_FEATURE_2':'Pahami dan seleksi kandidat yang memenuhi kriteria minimum dengan cepat serta penilaian berlapis.',
   'HOMEPAGE_RENDER_TEXT_FEATURE_3':'Susun wawancara terstruktur khusus yang konsisten serta adil selama proses wawancara, dengan pustaka dan templat pertanyaan kami.',
   'HOMEPAGE_RENDER_TEXT_FEATURE_4':'Paket lengkap fitur-fitur bawaan untuk meningkatkan proses penilaian, seperti pengawasan tes, berbagi kandidat, intelegensi artifisial (AI), aplikasi seluler, dan lain-lain.',
   'HOMEPAGE_CARD_CUSTOMERS_SAY':'Apa Kata Pelanggan',
   'HOMEPAGE_CARD_CUSTOMERS_ACCELERATE':'ASTRNT mengakselerasi proses wawancara.',
   'HOMEPAGE_CARD_SATISFIED_CANDIDATE':'Kandidat yang puas dengan pengalaman wawancara',
   'HOMEPAGE_ASSESSMENTS':'Assessments',
   'HOMEPAGE_ASSESS_FULLY':'Assessments, fully integrated & priced for volume',
   'HOMEPAGE_WITH_PRICING_PERFECT':'With pricing perfect for high volume, we partner with people analytics experts Talent Data Labs to provide high-quality psychometric assessments, including cognitive and personality. These are fully-integrated and can be combined with on-demand structured interviews.<br/><br/>In addition, our psychometrician team customises so you have the fastest, most accurate shortlisting offering.',
   'HOMEPAGE_FIND_OUT_MORE':'Find out more',
   'HOMEPAGE_INTEGRATIONS':'INTEGRASI',
   'HOMEPAGE_WE_LOVE_COLLAB':'Berkolaborasi dengan rekan integrasi adalah kegemaran kami',
   'HOMEPAGE_WE_LOVE_PARTNER':'Kami bekerjasama dengan banyak software HR ternama di dunia, supaya anda bisa mendapatkan SDM terbaik dengan perangkat terlengkap yang Anda butuhkan.',
   'HOMEPAGE_CASES_STUDIES':'STUDI KASUS',
   'HOMEPAGE_HIRING_ASSESSMENT':'Solusi rekrutmen & penilaian yang memikirkan kebutuhan bisnis Anda',
   'HOMEPAGE_CUSTOMER_SHARE':'Pelanggan kami berbagi cerita sukses mereka menggunakan platform ASTRNT dan wawancara video.',
   'HOMEPAGE_DOWNLOAD_CASE':'Unduh studi kasus',
   'HOMEPAGE_READY_TO_CREATE':`Siap untuk menciptakan pengalaman wawancara yang lancar?`,
   'HOMEPAGE_WE_HELP':'Membantu pelaksana ekosistem talenta mendigitisasi dan otomatisasi proses kerja.',
   'HOME_PAGE_CUSTOMERS_SAY_1':'Ini aplikasi yang bagus, kandidat tidak perlu mendaftar. Sangat simpel, cukup masukkan kode dan ikuti wawancara daring. Latihannya sangat bagus! Terima kasih!',
   'HOME_PAGE_CUSTOMERS_SAY_2':'Sungguh pengalaman luar biasa dan saya suka 3 kesempatan merekam ulang video. Awalnya saya sangat gugup, sesi latihan ini membuat saya menjadi lebih rileks.',
   'HOME_PAGE_CUSTOMERS_SAY_3':'Wow, saya sangat terkesan! Dengan teknologi ini, saya sebagai pelamar kerja bisa mengakses perangkat wawancara video dengan mudah. Teknologi ini adalah masa depan rekrutmen yang memberikan pelamar dan perekrut akses dan membantu mereka memberikan yang terbaik bagi perusahaan. Terima kasih, ASTRNT!',
   'HOME_PAGE_CUSTOMERS_SAY_4':'Pertama kali memakai aplikasi ini, saya terkejut. Aplikasi ini sangat membantu dan efektif, tetapi saya tidak terbiasa dengan wawancara semacam ini. Kerja yang bagus, pertahankan!',
   'HOME_PAGE_CUSTOMERS_SAY_5':'Sangat mengesankan, menghemat waktu menjawab pertanyaan dan wawancara.',
   'HOME_PAGE_CUSTOMERS_SAY_6':'Saya menikmati metode baru ini dan sangat antusias dengan prosesnya.',
   'HOME_PAGE_CUSTOMERS_1':'Platform ASTRNT memberi informasi menyeluruh tiap kandidat, memungkinkan secara presisi tinggi memilih yang terbaik untuk menjadi bagian dari keluarga NUS.',
   'HOME_PAGE_CUSTOMERS_2':'Kolaborasi dan integrasi dengan ASTRNT adalah keputusan paling cerdas untuk memulai sebuah strategi rekrutmen digital yang handal.<br/>Kami mempertimbangkan alur rekrutmen dengan memperhatikan pengalaman kandidat & efisiensi proses agar dipastikan semua dapat terus dikembangkan.',
   'HOME_PAGE_CUSTOMERS_3':'Saya suka menggunakan platform ASTRNT.<br/>Dukungan teknis luar biasa dan sangat responsif. Memenuhi kepegawaian dengan cepat. Saya pelanggan yang bahagia!',
   //end-Homepagenew 

     //Start-ArticlesGraduateHiringNew
   'GRADUATE_TAG':'ASTRNT UNTUK SARJANA & LULUSAN BARU',
   'GRADUATE_BIG_TEXT':'Solusi virtual rekrutmen kampus',
   'GRADUATE_FILL_TEXT':'Rekrutmen lulusan baru yang terukur, membutuhkan perangkat lunak yang andal dan terpercaya.<br/><br/>Kandidat menyukai kemampuan untuk melakukan wawancara dari perangkat apa pun, kapan saja, dari mana saja. Dengan wawancara, penilaian, dan simulasi video sesuai permintaan, perekrut dapat merekrut mahasiswa terbaik kampus mana pun dengan cepat tanpa berkunjung ke lokasi.',
   
   'GRADUATE_FIND_POTENTIAL':'Temukan potensi, secara akurat',
   'GRADUATE_FIND_POTENTIAL_TEXT':'Berikan kemampuan perekrut Anda untuk menilai bakat, kemampuan kerja, serta adaptasi kandidat, tanpa harus mengunjungi kampus.',
   
   'GRADUATE_ALL_UNIV':'Semua universitas dalam jangkauan',
   'GRADUATE_ALL_UNIV_TEXT':'Pantau lebih banyak kandidat di lebih banyak universitas, dan perluas skalabilitas Anda. Tak terikat lagi oleh halangan lokasi dan zona waktu.',
   
   'GRADUATE_BOOST_YOUR_BRAND':'Promosikan perusahaan Anda, dukung keberagaman',
   'GRADUATE_BOOST_YOUR_BRAND_TEXT':'Dengan pengalaman kandidat yang modern, mahasiswa sibuk menghargai fleksibilitas, keadilan, dan konsistensi bagi semua.',
   //end-ArticlesGraduateHiringNew


   //START-ArticlesUniversityNew
   'UNIVERSITY_TAG':'ASTRNT UNTUK PERANGKAT UNIVERSITAS',
   'UNIVERSITY_BIG_TEXT':'Fitur multifungsi untuk wawancara, penilaian, & banyak lagi dalam satu platform',
   'UNIVERSITY_FILL_TEXT':'Ubah proses penerimaan dan penilaian mahasiswa dengan video, sesuai permintaan, berjangka and penilaian tertulis berjangka. Dapatkan juga pengawasan tes.',

   'UNIVERSITY_GET_HOLISTIC':'Dapatkan pandangan holistik, buat keputusan yang adil',
   'UNIVERSITY_GET_HOLISTIC_TEXT':'Tingkatkan kualitas keputusan Anda dengan penilaian atas ciri-ciri utama seperti kepemimpinan, pemikiran kritis, dan kemampuan komunikasi. Berikan setiap pelamar kesempatan yang sama melalui penilaian standar untuk mengurangi bias.',
   
   'UNIVERSITY_SAVE_TIME':'Hemat waktu, eksekusi dengan cepat',
   'UNIVERSITY_SAVE_TIME_TEXT':'Hilangkan hal repetitif yang memakan waktu sehingga Anda dapat lebih mengenal calon siswa dan pelamar secara personal.',
   
   'UNIVERSITY_PROCTORING_SOLUTION':'Solusi Pengawasan Tes',
   'UNIVERSITY_PROCTORING_SOLUTION_TEXT':'Sederhanakan dan otomatisaskan proses dengan beralih ke pengawasan ujian, tes, dan sertifikasi secara digital untuk menjangkau lebih banyak peserta.',
   
   //end-ArticlesUniversityNew

   //Start-ArticlesGovernments
   'GOVERNMENTS_TAG':'INSTANSI PEMERINTAHAN & BUMN',
   'GOVERNMENTS_BIG_TEXT':'Proses rekrutmen yang transparan untuk menyesuaikan standar kelayakan & anggaran',
   'GOVERNMENTS_FILL_TEXT':'Bersaing dengan swasta untuk mendapatkan talenta terbaik melalui teknologi modern serta kesetaraan akan kesempatan.',

   'GOVERNMENTS_DECREASE_TIME':'Praktek Standar Kelayakan Terbaik',
   'GOVERNMENTS_DECREASE_TIME_TEXT':'Beralih ke ujian yang diawasi oleh <em>system intelligence</em>, penilaian dan sertifikasi tuk memastikan Anda mendapatkan kandidat terbaik melalui <em>fit & proper test</em>. ',

   'GOVERNMENTS_MEASURE':'Seleksi adil & tanpa keberpihakan untuk semua kandidat',
   'GOVERNMENTS_MEASURE_TEXT':'Pertajam keputusan seleksi Anda dengan menyaring kemampuan seperti kepemimpinan, berfikir kritis, dan komunikasi dari semua kandidat yang melamar. Berikan tiap kandidat kesempatan yang sama dengan menyajikan penilaian terstandarisasi guna menghindari keberpihakan melalui penialian otomatis.',
   
   'GOVERNMENTS_BUILD_TRUST':'Kendalikan Pengeluaran, Fokus pada Peningkatan Mutu',
   'GOVERNMENTS_BUILD_TRUST_TEXT':'Lakukan penerimaan kepegawaian secara masal dengan mudah, hemat biaya & dapat diulas setiap saat. Hapus kegiatan repetitif yang memakan waktu & biaya sehingga Anda dapat fokus memantau kebutuhan dan kinerja karyawan dengan lebih seksama.',
   //End-ArticlesGovernments

   //Start-ArticlesProfessionalsNew
   'PROFESSIONAL_TAG':'EKSEKUTIF, REKRUTMEN SEPANJANG TAHUN',
   'PROFESSIONAL_BIG_TEXT':'Lakukan perubahan menyeluruh',
   'PROFESSIONAL_FILL_TEXT':'Libatkan manager rekrutmen dalam segala proses secara konsisten dan tepat waktu. Interaksi secara cepat dengan kandidat pasif dan aktif melalui partisipasi yang mudah digunakan, dan tetap gunakan ATS anda sebagai pusat informasi.',
   'PROFESSIONAL_DECREASE_TIME':'Kurangi jeda waktu rekrutmen',
   'PROFESSIONAL_DECREASE_TIME_TEXT':'Lakukan wawancara sesuai waktu luang yang kandidat inginkan, di perangkat yang mereka pilih.',

   'PROFESSIONAL_MEASURE':'Ukur hal yang penting',
   'PROFESSIONAL_MEASURE_TEXT':'Transkrip nilai dan pengalaman bertahun-tahun tidak menjamin kinerja. Lakukan pengalaman wawancara terstruktur, sehingga terungkap hal-hal yang penting bagi penilaian Anda, untuk membuat keputusan yang adil, cepat, dan cerdas.',
   
   'PROFESSIONAL_BUILD_TRUST':'Bangun kepercayaan dengan pemangku kepentingan',
   'PROFESSIONAL_BUILD_TRUST_TEXT':'Bangun kredibilitas proses rekrutmen, laporankan secara langsung interview dan hasil tes pada manajer rekrutmen serta pemangku kepentingan, untuk mendapat keputusan yang cepat.',
   //End-ArticlesProfessionalsNew

   //Start-ArticlesBlueNew
   'BLUE_COLLAR_TAG':'KARYAWAN, REKRUTMEN SEPANJANG TAHUN',
   'BLUE_COLLAR_BIG_TEXT':'Penyaringan menyeluruh, mudah diakses oleh kandidat',
   'BLUE_COLLAR_FILL_TEXT':'Beri kandidat solusi cepat dan mudah untuk mengisi lowongan kerja, melalui perangkat apapun, kapanpun, bahkan di kondisi internet buruk. Nilai semua aspek dengan deskripsi kerja yang realistis, kemampuan berbahasa, dan lainnya.',

   'BLUE_COLLAR_CARD_1':'Tanpa mengurangi produktivitas manajer tim',
   'BLUE_COLLAR_CARD_TEXT_1':'Proses rekrutmen berdasarkan CV perlu berminggu-minggu dan mengurangi produktivitas manajer yang sibuk. Hindari pemborosan waktu.',

   'BLUE_COLLAR_CARD_2':'Penilaian berdasarkan kecakapan',
   'BLUE_COLLAR_CARD_TEXT_2':'Transkrip nilai dan pengalaman kerja tidak menjamin kinerja. Uji berbagai kecakapan dengan cepat dan mudah melalui wawancara multi-media yang terstruktur.',
   
   'BLUE_COLLAR_CARD_3':'Terpercaya, cepat, dan andal',
   'BLUE_COLLAR_CARD_TEXT_3':'Tak perlu menjadwalkan koordinasi waktu bersama, tingkatkan rata-rata penyelesaian wawancara yang sesuai kebutuhan posisi serta pengalaman yand disukai kandidat.',
   //End-ArticlesBlueNew

   //Start-ArticlesAsessNew
   'ASSESS_COLLAR_TAG':'ASTRNT UNTUK PUSAT PENILAIAN & SERTIFIKASI',
   'ASSESS_COLLAR_TAG_BIG_TEXT':'Offline ke online dengan mitra teknologi terpercaya',
   'ASSESS_COLLAR_TAG_FILL_TEXT':'Transformasi bisnis Anda dengan mitra teknologi terpercaya. Andal, fleksibel, hemat biaya, dan berkomitmen untuk masa depan industri penilaian. Dari offline ke online hingga otomatisasi dan algoritma, kami bersama Anda.',

   'ASSESS_COLLAR_CARD_1':'Andal, fleksibel, hemat biaya',
   'ASSESS_COLLAR_CARD_TEXT_1':'Pilih ASTRNT sebagai mitra teknologi untuk bisnis Anda. Kami tumbuh bersama Anda, dengan pilihan model kemitraan untuk menekan biaya tanpa mengurangi kualitas dan performa tinggi.',

   'ASSESS_COLLAR_CARD_2':'Kembangkan dampak bisnis Anda, melalui digitalisasi',
   'ASSESS_COLLAR_CARD_TEXT_2':'Dengan menawarkan layanan online, Anda dapat menjangkau pengguna di mana saja, kapan saja. Tidak lagi ada batasan geografis.',
   
   'ASSESS_COLLAR_CARD_3':'Perkaya asset intelektual tanpa batas',
   'ASSESS_COLLAR_CARD_TEXT_3':'Gunakan kembali konten uji yang ada, dan buat konten uji baru. Kembangkan metode uji, penilaian dan analisa tanpa batas.',
   //End-ArticlesAsessNew

   //Start-ArticlesTalentNew
   'TALENT_COLLAR_TAG':'ASTRNT UNTUK PLATFORM TALENTA YANG MEMERLUKAN VIDEO',
   'TALENT_COLLAR_TAG_BIG_TEXT':'Video asinkron yang andal dan mudah di integrasi',
   'TALENT_COLLAR_TAG_FILL_TEXT':'Kami mengakselerasi kinerja software lowongan pekerjaan, ATS, HRIS, dan platform pengujian yang ingin menawarkan fungsi video asinkron kepada pengguna mereka, tanpa susah payah membangun dari nol dan merawat secara berkala. Kami dapat menjadi mitra teknologi yang terpercaya buat Anda.   ',

   'TALENT_COLLAR_CARD_1':'Jadikan platform anda menonjol',
   'TALENT_COLLAR_CARD_TEXT_1':'Bangun aplikasi istimewa atau unik dengan video asinkron yang dibangun diatas platform kami. API dan SDK kami berfungsi di semua browser dan perangkat utama dengan video.',
   'TALENT_EXPLORE_OUR_API':'Coba API & SDK Kami',
  
   'TALENT_COLLAR_CARD_2':'Bangun fitur video sesuai kebutuhan',
   'TALENT_COLLAR_CARD_TEXT_2':'Bangun, luncurkan, dan kembangkan aplikasi Anda di atas infrastruktur kami yang berbasis-cloud. Dokumentasi dan dukungan kami menyediakan semua kebutuhan Anda untuk membuat aplikasi video yang sesuai keinginan dalam skala yang anda butuhkan.',
   
   'TALENT_COLLAR_CARD_3':'Kami buat semuanya mudah',
   'TALENT_COLLAR_CARD_TEXT_3':'ASTRNT adalah cara termudah untuk menambahkan video asinkron ke platform Anda. Bangun dan jalankan dalam hitungan jam, bukan bulan, sehingga tim Anda dapat fokus pada bisnis Anda.',
   'TALENT_TEST_OUR_VIDEO_API':'Coba Video API Kami',
   
   //End-ArticlesTalentNew


   
   //Start-HowItWorkNew
   'PRICING_TITLE': 'Flexible pricing that <strong>fits your team size.</strong>',
   'PRICING_DESCRIPTION': 'No credit card required in your first 14 days.',
   'PRICING_LABEL_PRICE_IN_USD': '*Price in USD',
   'PRICING_ESSENTIAL_DESC': '<p><strong>Plan designed for</strong> Startups, Small and Medium<br/>Enterprises</p>',
   'PRICING_ESSENTIAL_PRICE_MONTHLY' : '$<strong>88</strong> <span>/month</span',
   'PRICING_ESSENTIAL_PRICE_YEARLY' : '$<strong>900</strong> <span>/year</span>',
   'PRICING_SAVE_COMPARED_MONTHLY' : 'Save 15% compared to monthly',
   'PRICING_PLAN_FOR_LARGE_VOLUMES': '<p><strong>Plan designed for</strong> Large Volumes</p>',
   'PRICING_PLAN_CUSTOM_PRICING':'Custom Pricing',
   'PRICING_OFFER_ENTERPRISE':'We offer a very competitive pricing to match your business needs. Get in touch and we will reach back to create your custom pricing plan.',
   'PRICING_SWITCH_PLAN':'Switch Plan',
   'PRICING_ESSENTIAL_MONTLY':'Essential Monthly',
   'PRICING_ESSENTIAL_YEARLY':'Essential Yearly',
   'PRICING_PLAN_DESIGNED_MOBILE':'<strong>Plan designed for</strong> Startups, Small and Medium Enterprises',
   'PRICING_PLAN_DESIGNED_MOBILE_LARGE':'<strong>Plan designed for</strong> Large Volumes',
   
   //Features pricing
   'UNLIMITED':'Unlimited',
   'PRICING_#_CANDIDATE_RESPONSE_1':'# of candidate responses',
   'PRICING_#_CANDIDATE_RESPONSE_2':'30/month',
   'PRICING_#_CANDIDATE_RESPONSE_3':'360/year',
   'PRICING_#_USER_TEAM_MEMBER_RESPONSE':'# of users or team member',
   'PRICING_#_OF_JOBS':'# of jobs',
   
   'PRICING_VIDEO_INTERVIEW':'Video Interview',
   'PRICING_MCQ':'Multiple Choice Questions',
   'PRICING_FTQ':'Free Text Questions',
   'PRICING_RANDOM_QUESTION':'Randomization Question',
   'PRICING_VOICE_TO_TEXT':'Voice to Text Transcription',
   'PRICING_PROCTORING':'Proctoring',
   'PRICING_CO_BRAND':'Co-brand invitation',
   'PRICING_CANDIDATE_REVIEWER':'Candidate and Reviewer Analytics',
   'PRICING_QUESTIONS_BANK':'Question Banks',
   'PRICING_CANDIDATE_DOWNLOAD':'Candidate Download and Upload Documents',
   'PRICING_INCLUDED_WHEN_AVAILABLE':'Included when available, coming soon.',
   'PRICING_AVAILABLE_UPON_REQUEST':'Available, upon request.',

   'PRICING_CANDIDATE_EXPERIENCE':'Candidate Experience',
   'PRICING_CANDIDATE_EXPERIENCE_TEXT_1':'White label candidate experience',
   'PRICING_CANDIDATE_EXPERIENCE_TEXT_2':'Welcome Video',
   'PRICING_CANDIDATE_EXPERIENCE_TEXT_3':'WhatsApp Integration',

   'PRICING_DATA_SECURITY':'Data Security',
   'PRICING_DATA_SECURITY_TEXT_1':'ISO 27001 Compliant',

   'PRICING_CANDIDATE_MANAGEMENT':'Candidate Management',
   'PRICING_CANDIDATE_MANAGEMENT_TEXT_1':'Smart Filtering',
   'PRICING_CANDIDATE_MANAGEMENT_TEXT_2':'Candidate Scoring Report',
   'PRICING_CANDIDATE_MANAGEMENT_TEXT_3':'Automatic Calculation Scoring on MCQ',
   'PRICING_CANDIDATE_MANAGEMENT_TEXT_4':'Share Candidate',
   'PRICING_CANDIDATE_MANAGEMENT_TEXT_5':'Think Time',

   'PRICING_INTEGRATION':'Integration',
   'PRICING_INTEGRATION_TEXT_1':'ATS Integration',

   'PRICING_SUPPORT':'Support',
   'PRICING_SUPPORT_TEXT_1':'Dedicated Onboarding',
   'PRICING_SUPPORT_TEXT_2':'Dedicated Customer Success Manager',
   'PRICING_SUPPORT_TEXT_3':'Live Chat',
   'PRICING_SUPPORT_TEXT_4':'Question Set-Up',
   'PRICING_SUPPORT_TEXT_5':'Features request and additional tech support',

   'PRICING_ARE_YOU_NGO':'Are you NGO or a start up company?',
   'PRICING_ARE_YOU_NGO_TEXT_1':'Find your eligiblity to get 1st year discount by filling in your details and create a free trial for 14 days',


   'PRICING_FAQ':'Pricing Frequently Asked Questions',
   
   'PRICING_FAQ_QUESTION_1':'What is a candidate credit?',
   'PRICING_FAQ_ANSWER_1':' A candidate credit is defined as the ability for a candidate to complete a session in ASTRNT, i.e. if the candidate does not complete the questions your account will not be charged. It is 1 credit for 1 completed candidate.',

   'PRICING_FAQ_QUESTION_2':'What does the 12 months validity mean?',
   'PRICING_FAQ_ANSWER_2':'All of your candidate credits hold a 12 months validity based on your subscription plan and you are encouraged to use them before they expire. If you require flexibility for the period of time, please contact us at <a target="_blank" rel="noopener noreferrer" href="mailto:help@astrnt.co">help@astrnt.co</a>, we are here to help always. Also, see below regarding how to extend the validity of existing credits by purchasing new credits.',

   'PRICING_FAQ_QUESTION_3':'What happens to my existing credits?',
   'PRICING_FAQ_ANSWER_3':'When you top-up your credits, a new 12 month validity will be applied to all your existing credits.',
   
   'PRICING_FAQ_QUESTION_4':'When can I use my newly bought credits?',
   'PRICING_FAQ_ANSWER_4':'We operate on a trust basis and allow you to access your new credits right away. All we ask in return is a speedy payment in response to the invoice and tax documents. We may need to suspend your account if we don’t receive payment within 30 days of sending the invoice.',

   'PRICING_FAQ_QUESTION_5':'My credits are currently in negative. Will I be billed twice?',
   'PRICING_FAQ_ANSWER_5':'No, after you purchase new credits they will be applied to offset the deficit.',

   'PRICING_FAQ_QUESTION_6':'What payment methods are accepted?',
   'PRICING_FAQ_ANSWER_6':'We accept Credit Cards (American Express, China Union Pay, Discover & Diners, Japan Credit Bureau, Master Card, Visa), ATM/Bank Transfer, KlikBCA, BCA KlikPay, Octo Clicks, and e-Pay BRI. For Enterprise subscribers we also accept direct bank transfer. ',
   
   'PRICING_STILL_HAVE_QUESTION':'Still have questions?',
   'PRICING_LETS_CHAT_WITH_US':`Let's <a target="_blank" rel="noopener noreferrer" href="mailto:help@astrnt.co">chat with us</a>`,
   //End-HowItWorkNew

   
   //Start-HowItWorksNew
   'HOW_IT_WORKS_TITLE':`Pelajari cara menyederhanakan & mempercepat proses perekrutan Anda`,
   'HOW_IT_WORKS_CARD_TITLE_1':'Wawancara Video Asinkron',
   'HOW_IT_WORKS_CARD_TEXT_1':'Percepat sesi wawancara dan tanggapan kandidat dengan video asinkron.',

   'HOW_IT_WORKS_CARD_TITLE_2':`Rangkaian Alat Penilaian Lengkap`,
   'HOW_IT_WORKS_CARD_TEXT_2':'Ukur kandidat Anda secara menyeluruh dengan pertanyaan pilihan ganda, esai, pertanyaan berkelompok, dan database pertanyaan.',

   'HOW_IT_WORKS_CARD_TITLE_3':'Mitra Penilaian',
   'HOW_IT_WORKS_CARD_TEXT_3':'Jika perlu bantuan untuk mengajukan pertanyaan yang tepat, bekerja samalah dengan mitra penilaian dari kami atau yang Anda inginkan untuk membuat pustaka pertanyaan yang dapat disesuaikan.',

   'HOW_IT_WORKS_CARD_TITLE_4':'Berbagi',
   'HOW_IT_WORKS_CARD_TEXT_4':'Dapatkan catatan dan pendapat rekan dan manajer Anda dengan membagikan kandidat demi hasil yang cepat.',

   'HOW_IT_WORKS_CARD_TITLE_5':`Pustaka Pertanyaan Khusus`,
   'HOW_IT_WORKS_CARD_TEXT_5':'Buat rencana dan bersiaplah. Isi pustaka pertanyaan khusus dengan masukan dari manajer dan pemangku kepentingan, dan buat templat untuk wawancara di masa depan.',

   'HOW_IT_WORKS_CAN_WE_HELP':'Apa yang bisa kami bantu?',
   '':'',

   //End-HowItWorksNew

   //Start-PartnersPageNew
   'PARTNERS_PAGE_CARD_1_TITLE':'Bersama kita bisa melakukan banyak hal',
   'PARTNERS_PAGE_CARD_1_TEXT':'Ekosistem talenta digital global berkembang pesat. Apa peran apa? Kami bermitra dengan agensi, konsultan, asesor, pengusaha, dan platform rekrutmen lainnya di seluruh dunia.',

   'PARTNERS_PAGE_CARD_2_TITLE':'Bentuk Rekanan',

   'PARTNERS_PAGE_CARD_2_CONTENT_TITLE_1':'Mitra Regional',
   'PARTNERS_PAGE_CARD_2_CONTENT_TEXT_1':'Tidak ada yang mengalahkan pengetahuan lokal dan nama baik. Bantu kami membangun jejak global kami di lokasi Anda. Kami menyesuaikan dengan kebutuhan Anda dan berharap lebih mengenal Anda.',

   'PARTNERS_PAGE_CARD_2_CONTENT_TITLE_2':'Tim Teknologi Talenta',
   'PARTNERS_PAGE_CARD_2_CONTENT_TEXT_2':'Ekosistem talenta digital semakin terhubung untuk memenuhi kebutuhan pasar. Bergerak cepat dan berinovasi bersama kami demi solusi lengkap bagi industri Anda.',

   
   'PARTNERS_PAGE_CARD_2_CONTENT_TITLE_3':'Konsultan & Pemilik Konten',
   'PARTNERS_PAGE_CARD_2_CONTENT_TEXT_3':'Apakah Anda memiliki keahlian dan konten, tetapi perlu mitra teknologi terpercaya untuk mengejar ambisi Anda? Kami bisa membantu Anda.',

   'PARTNERS_PAGE_CARD_3_TITLE':'Bekerjasama dengan ASTRNT',
   'PARTNERS_PAGE_CARD_3_TEXT':'Kami menyambut kemitraan.<br/>Isi formulir di bawah ini, dan kami akan segera menghubungi Anda.',

   //Form Partnership
   'PARTNERS_FORM_LABEL_1':'Industri/Institusi *',
   'PARTNERS_FORM_LABEL_2':'Alamat Email *',
   'PARTNERS_FORM_LABEL_3':'Nama *',
   'PARTNERS_FORM_LABEL_4':'Nomor handphone *',
   'PARTNERS_FORM_LABEL_5':'Mengapa dan bagaimana institusi Anda ingin bermitra dengan kami *',

   'PARTNERS_FORM_PLACEHOLDER_1':'e.g: Talent Data Labs',
   'PARTNERS_FORM_PLACEHOLDER_2':'e.g: johndean@mail.com',
   'PARTNERS_FORM_PLACEHOLDER_3':'e.g: John Dean',
   'PARTNERS_FORM_PLACEHOLDER_4':'e.g: +61 8888 4466',

   'PARTNERS_FORM_SUBMIT_BUTTON':'Kirim',
   //End Form

    ///Start-CandidateSupport
   //Start-UserRegisterWizardFormContainerNew
    'REGISTER_PAGE_A_FASTER':'A faster, smarter way that candidates love.',
    'REGISTER_PAGE_JOIN_HUNDREDS':'Join hundreds of talent ecosystem leaders digitizing and automating their selection processes and keeping their organisations safe & agile.',

    'REGISTER_PAGE_NO_CREDIT_CARD':'No credit card required.',
    'REGISTER_PAGE_14_DAYS':'14 days free trial',
    'REGISTER_PAGE_UNLIMITED_JOBS':'Unlimited jobs',
    'REGISTER_PAGE_UNLIMITED_USERS':'Unlimited users or team members',
    'REGISTER_PAGE_LIVE_CHAT':'Live chat support',

    //REGISTER POPUP
    'REGISTER_POP_WHAT_BEST':'What best describes your focus?',
    'REGISTER_POP_UNIV_ADMISSION':'University Admissions',
    'REGISTER_POP_RECRUIT':'Recruitment & Assessment',
    'REGISTER_POP_APPLICANT':'I’m An Applicant',

    'REGISTER_POP_IF_YOU_APPLICANT':"If You're An Applicant",

    'REGISTER_POP_OK_SUPER':'Ok, super. That’s great to know.',
    'REGISTER_POP_APPLICANTS_SHOULD':'Applicants should proceed as follows:',
    'REGISTER_POP_CHECK_EMAIL':'Check the email sent to you by the organization you’re interviewing with.',
    'REGISTER_POP_INSERT_CODE':'Insert the provided code to ',
    'REGISTER_POP_START_INTERVIEW':'Start the interview.',
    'REGISTER_POP_NEED_ASSISTANCE':'If you need assistance with your interview, please contact our ',
    'REGISTER_POP_SUPPORT_TEAM':'support team.',

    //Form Register
    'REGISTER_FORM_GET_TO_KNOW':'Get Ready, Set-Up is Easy',
    'REGISTER_FORM_WHAT_BEST':'What best describes your focus?',

    'REGISTER_FORM_LABEL_NAME_COMPANY':'Name of Company/Organization *',
    'REGISTER_FORM_LABEL_WORK_EMAIL':'Work Email *',
    'REGISTER_FORM_LABEL_WORK_PHONE':'Work Phone *',
    'REGISTER_FORM_LABEL_FULLNAME':'Full Name *',
    'REGISTER_FORM_LABEL_PREFERRED_NAME':'Preferred Name *',
    'REGISTER_FORM_LABEL_PASSWORD':'Password *',

    'REGISTER_FORM_LABEL_HOW_DO_YOU_HOPE':'How do you hope ASTRNT can help achieve your goals? *',
    'REGISTER_FORM_PLACEHOLDER_I_HOPE':'I hope ASTRNT can help us...',

    'REGISTER_FORM_BY_CLICK':`By clicking "Start Free Trial", you agree to ASTRNT's `,
    'REGISTER_FORM_AND':" and ",
    'REGISTER_FORM_ALREADY_HAVE':"Already have an account? ",
    'REGISTER_FORM_LOGIN':"Log in",

    'REGISTER_FORM_SUBMIT_BUTTON':'Create Account',
    //End Form

    //End-UserRegisterWizardFormContainerNew

    //Start-CandidateSupport
    'CANDIDATE_SUPPORT_CARD_1_TITLE':'Wawancara & Penilaian Paling Sesederhana',
    'CANDIDATE_SUPPORT_CARD_1_TEXT':"ASTRNT memberi Anda kesempatan melampaui CV Anda. Dengan video asinkron, Anda dapat merekam jawaban wawancara dari mana saja, kapan saja, di laptop atau ponsel Anda.",

    'CANDIDATE_SUPPORT_CARD_2_TITLE':'Pengalaman lebih baik',
    'CANDIDATE_SUPPORT_CARD_2_TEXT':'Orang-orang menyukai Wawancara dengan kami!',
    'CANDIDATE_SUPPORT_CARD_REVIEW_APPLE':'Lihat komentar di Apple Store',
    'CANDIDATE_SUPPORT_CARD_REVIEW_GOOGLE':'Lihat komentar di Google Play Store',

    'CANDIDATE_SUPPORT_CARD_3_TITLE':'Kami Mendukung Anda!',
    'CANDIDATE_SUPPORT_CARD_3_TEXT':'Jangan gugup menghadapi wawancara Anda, di sini ASTRNT mendukung Anda demi kesuksesan berikutnya.',
    
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TITLE':'Persiapkan dirimu sebelum wawancara',

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_1':'5 Tips Mempersiapkan Diri untuk Wawancara',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_2':`#1 Pelajari Perusahaan yang Anda Tuju`,
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_3':'#2 Komunikasi Non-Verbal',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_4':'#3 Tata Krama',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_5':'#4 Promosikan Diri Anda',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_6':'#5 Bersiaplah',

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_1':`
    Penilaian dan wawancara ASTRNT cepat dan mudah digunakan. Untuk menghadapi rasa gugup, inilah 5 Tip Teratas kami untuk mempersiapkan Anda menghadapi wawancara.`,
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_2':`Pelajari perusahaan yang Anda tuju. Pahami kerja dan produk mereka sehingga Anda lebih percaya diri saat menjawab pertanyaan.`,
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_3':'Dalam wawancara daring, mulailah dengan kontak mata dengan kamera. Duduklah dalam posisi yang nyaman, tersenyum, dan jangan melihat ponsel Anda.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_4':'Jaga ucapan Anda, coba bersikap ramah, dan selalu bersikap sopan.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_5':'Jangan takut menceritakan keahlian dan pencapaian Anda.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_6':'Siapkan CV, foto, ijazah, dll dalam bentuk digital jika perlu diunggah. Tampil dengan pakaian yang baik dan tunjukkan rasa percaya diri.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_FIND_MORE':'Temukan lebih banyak artikel untuk membantu Anda mempersiapkan diri untuk wawancara di',

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_2_TITLE':'Latihan wawancara video',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_2_TEXT':`Cobalah proses wawancara ASTRNT. Hanya sekejap!`,
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_2_TEST_QNA':`Test Q&A ASTRNT`,

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TITLE':'Ekspresikan Dirimu',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_INTRO_TEXT':`Jangan takut bercerita lebih banyak tentang diri Anda. Berikut beberapa tips wawancara video:`,

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_LABEL_1':'#Pakai Pakaian Yang Menunjukkan Jati Diri Anda',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_LABEL_2':'#Pilih Setting Anda',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_LABEL_3':'#Bicaralah dengan Jelas',

    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TEXT_1':'Pakaian rapi dan formal adalah pilihan terbaik, tetapi tonjolkan kepribadian Anda sehingga Anda mudah diingat. Mulai dengan warna favorit hingga aksesori, tapi jangan berlebihan.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TEXT_2':'Kelebihan wawancara daring adalah dapat dilakukan di mana saja. Di kafe, taman, ruang tamu, di mana saja. Pastikan ruangan cerah agar wajah Anda tampak jelas, dan pastikan tidak berisik.',
    'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TEXT_3':'Jangan takut menyampaikan pendapat, tetapi pastikan suara Anda jelas. Latih vokal Anda dengan mengucapkan beberapa kalimat sambil menggigit pensil, atau mengartikulasikan rahang.',



    'CANDIDATE_SUPPORT_CARD_4_TITLE':'Pertanyaan yang Sering Diajukan (FAQ)',

    'CANDIDATE_SUPPORT_FAQ_1':'Apa itu kode? Mengapa saya perlu kode?',
    'CANDIDATE_SUPPORT_FAQ_2':'Di mana kode saya?',
    'CANDIDATE_SUPPORT_FAQ_3':'Saya punya kode sesi Tanya-Jawab ASTRNT, apa langkah selanjutnya?',
    'CANDIDATE_SUPPORT_FAQ_4':'Saya menerima email undangan, apa langkah selanjutnya?',
    'CANDIDATE_SUPPORT_FAQ_5':`Wajah saya tidak tampak di layar, apa langkah selanjutnya?`,
    'CANDIDATE_SUPPORT_FAQ_6':`Aplikasi tidak bisa merekam suara, apa yang harus dilakukan?`,
    'CANDIDATE_SUPPORT_FAQ_7':`Saya tidak bisa menginstal aplikasi seluler Tanya-Jawab ASTRNT. Mengapa?`,
    'CANDIDATE_SUPPORT_FAQ_8':`Aplikasi Android di perangkat saya terus mogok. Apa yang harus dilakukan?`,
    'CANDIDATE_SUPPORT_FAQ_9':`Sesi Tanya-Jawab bisa dilakukan di browser web mana?`,
    'CANDIDATE_SUPPORT_FAQ_10':`Waktu habis tetapi saya belum menyelesaikan semua pertanyaan. Apa yang harus dilakukan?`,
    'CANDIDATE_SUPPORT_FAQ_11':`Koneksi saya terputus saat sesi Tanya-Jawab. Apa yang harus dilakukan?`,
    'CANDIDATE_SUPPORT_FAQ_12':`Ada masalah teknis selama Tanya-Jawab dan saya ingin mengulang sesi. Apa yang harus dilakukan?`,
    'CANDIDATE_SUPPORT_FAQ_13':'Sudahkah jawaban saya diterima?',
    'CANDIDATE_SUPPORT_FAQ_14':`Sesi Tanya-Jawab saya selesai. Bagaimana cara melihat hasil saya? Apa langkah selanjutnya?`,
    'CANDIDATE_SUPPORT_FAQ_15':'Saya dapat email yang meminta dokumen. Apa yang harus dilakukan?',
    'CANDIDATE_SUPPORT_FAQ_16':'Sepertinya ASTRNT keren. Bagaimana melamar di ASTRNT?',


    'CANDIDATE_SUPPORT_FAQ_1_TEXT':`Kode Tanya-Jawab ASTRNT memastikan Anda mendapatkan pertanyaan yang tepat. Pada setiap kode, ada serangkaian pertanyaan dan skenario khusus, tergantung apa yang ingin diketahui perekrut tentang Anda.`,
    
    
    'CANDIDATE_SUPPORT_FAQ_2_TEXT':`<li>
      Jika:
      <ul>
        <li>Perekrut mengundang Anda ke sesi Tanya-Jawab ASTRNT, temukan kode dalam email Anda.</li>
        <li>Anda mengunduh aplikasi karena iklan, temukan kode terbuka pada iklan.</li>
        <li>Anda ingin mendaftar di ASTRNT, temukan kode kami pada FAQ terakhir.</li>
      </ul>
    </li>`,

    'CANDIDATE_SUPPORT_FAQ_3_TEXT':`<li>
                    Anda bisa langsung ikut sesi Tanya-Jawab! Ada dua cara melakukannya:
                    <ul>
                      <li>
                        <b>Ikut sesi Tanya-Jawab di aplikasi seluler ASTRNT</b>
                        Instal aplikasi seluler Tanya-Jawab ASTRNT (untuk <a href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer">iOS</a> dan <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer">Android</a>), masukkan kode, dan ikuti petunjuk di aplikasi seluler.
                      </li>
                      <li>
                        <b>Ikut sesi Tanya-Jawab di browser desktop</b>
                        Buka <a href="http://astrnt.co" target="_blank" rel="noopener noreferrer">http://astrnt.co</a>, klik "Saya punya kode", dan masukkan kode Anda, lalu ikuti petunjuk untuk menyelesaikan sesi Tanya-Jawab.
                      </li>
                    </ul>
                    Cari tempat yang tenang dan waktu yang cukup untuk melakukan tes tanpa gangguan. Pastikan koneksi internet Anda stabil (kecepatan unggah minimal 300kbps). Semoga beruntung!
                  </li>`,

    'CANDIDATE_SUPPORT_FAQ_4_TEXT':`<li>
    Anda bisa langsung ikut sesi Tanya-Jawab! Ada dua cara melakukannya:
    <ul>
      <li>
        <b>Ikut sesi Tanya-Jawab di aplikasi seluler ASTRNT</b>
        Instal aplikasi seluler Tanya-Jawab ASTRNT (untuk <a href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer">iOS</a> dan <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer">Android</a>), masukkan kode, dan ikuti petunjuk di aplikasi seluler.
      </li>
      <li>
        <b>Ikut sesi Tanya-Jawab di browser desktop</b>
        Buka <a href="http://astrnt.co" target="_blank" rel="noopener noreferrer">http://astrnt.co</a>, klik "Saya punya kode", dan masukkan kode Anda, lalu ikuti petunjuk untuk menyelesaikan sesi Tanya-Jawab.
      </li>
    </ul>
    Cari tempat yang tenang dan waktu yang cukup untuk melakukan tes tanpa gangguan. Pastikan koneksi internet Anda stabil (kecepatan unggah minimal 300kbps). Semoga beruntung!
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_5_TEXT':`<li>
               Periksalah izin kamera Anda. Begini caranya:
                    <ul>
                      <li>
                        <b>Dengan Chrome</b>
                        Ikuti petunjuk di <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">tautan</a> ini untuk mengubah izin akses kamera.
                      </li>
                      <li>
                        <b>Dengan Mozilla Firefox</b>
                        Pada menu bar, buka Alat > Info Halaman > Izin, lalu gulir ke bawah ke "Gunakan Kamera". Pastikan situs web diizinkan mengakses kamera.
                      </li>
                      <li>
                        <b>Dengan Opera</b>
                        Secara default, Opera akan selalu minta izin akses ke kamera. Jika tidak, buka Pengaturan > Situs Web, di bawah Kamera, dan klik tombol Kelola Pengecualian. Untuk informasi lebih lanjut, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#camera" target="_blank">klik di sini</a>.
                      </li>
                      <li>
                        <b>Dengan aplikasi Tanya-Jawab ASTRNT di iOS</b>
                        Buka Pengaturan > Privasi > Mikrofon, dan pastikan ASTRNT dapat mengakses mikrofon.
                      </li>
                      <li>
                        <b>Dengan aplikasi Tanya-Jawab ASTRNT di Android</b>
                        Buka Pengaturan > Keamanan & Privasi > Izin Aplikasi dan pastikan ASTRNT dapat mengakses mikrofon.
                      </li>
                    </ul>
                  </li>`,

    'CANDIDATE_SUPPORT_FAQ_6_TEXT':`<li>
                    Periksalah izin mikrofon Anda. Begini caranya:
                    <ul>
                      <li>
                        <b>Chrome</b>
                        Ikuti instruksi di <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">tautan ini</a> untuk mengubah izin akses kamera.
                      </li>
                      <li>
                        <b>Mozilla Firefox</b>
                        Pada bar menu, klik Tools > Page Info > Permissions, lalu gulir ke bawah untuk  "Use the Microphone". Pastikan situs web diizinkan untuk mengakses mikrofon.
                      </li>
                      <li>
                        <b>Opera</b>
                        Secara bawaan, Opera akan meminta Anda mengizinkan akses mikrofon pada situs. Jika tidak demikian, klik Settings > Websites, di bawah Microphone, klik Manage Exceptions. Informasi selengkapnya klik, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#microphone" target="_blank">disini</a>.
                      </li>
                      <li>
                        <b>Aplikasi ASTRNT Q&A di iOS</b>
                        Akses Settings > Privacy > Microphone, lalu pastikan ASTRNT mendapatkan akses mikrofon Anda.
                      </li>
                      <li>
                        <b>Aplikasi ASTRNT Q&A di Android</b>
                        Akses Settings > Security & Privacy > Application Permissions dan pastikan ASTRNT mendapatkan akses mikrofon Anda.
                      </li>
                    </ul>
                  </li>`,

    'CANDIDATE_SUPPORT_FAQ_7_TEXT':`<li>
    Aplikasi Tanya-Jawab ASTRNT tersedia di <a href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer">iOS</a> (iOS 12 ke atas) dan <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer">Android</a> (6.0 ke atas, RAM minimal 2GB). Jika ponsel Anda tidak mencukupi, lakukan sesi Tanya Jawab di browser desktop.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_8_TEXT':`<li>
    Maaf! Sangat jarang ada masalah di Android, khususnya perangkat Nexus. Jika aplikasi di perangkat Anda bermasalah, laporkan kepada kami. Untuk menyelesaikan sesi Tanya-Jawab, gunakan situs web kami di desktop.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_9_TEXT':`<li>
    <a href="https://www.google.com/intl/en/chrome/browser/" target="_blank">Google Chrome</a> (disarankan), <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a>, dan <a href="http://www.opera.com/" target="_blank">Opera</a>.Sayangnya, saat ini aplikasi kami tidak mendukung Safari, Microsoft Edge, dan Internet Explorer, yang masih dalam tahap percobaan karena dukungan WebRTC baru saja dirilis di sistem ini. Tim kami bekerja keras untuk mengatasi bug sebelum merilis dukungan untuk browser tersebut.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_10_TEXT':`<li>
    Jangan khawatir, jawaban diunggah ke database sebelum waktu habis. Namun, Tim ASTRNT tidak berhak memberi Anda kesempatan kedua. Anda dapat menghubungi perekrut untuk minta sesi ulang. Jika menurut Anda waktu habis karena kesalahan, silakan hubungi kami di <a href="mailto:help@astrnt.co" target="_blank">help@astrnt.co</a>.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_11_TEXT':`<li>
    Sayangnya, waktu terus berjalan di tengah sesi Tanya-Jawab, meskipun koneksi Anda terputus. Coba segera sambungkan kembali.
Jika koneksi putus setelah semua pertanyaan selesai, jangan khawatir, jawaban Anda masih ada — tinggal sambungkan kembali ke internet dan proses unggah akan berlanjut. Sistem kami memeriksa kecepatan Anda untuk menghindari masalah ini.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_12_TEXT':`<li>
    Jika ada masalah teknis selama sesi Tanya-Jawab, hubungi <a href="mailto:help@astrnt.co" target="_blank">help@astrnt.co</a>. 
    Namun, Tim ASTRNT tidak berhak memberi Anda kesempatan kedua. Anda dapat menghubungi perekrut untuk minta sesi ulang.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_13_TEXT':`<li>
    Di akhir sesi Tanya-Jawab, status unggahan Anda tampak pada halaman unggahan. Setelah semua jawaban diunggah, sistem kami akan mengirim email konfirmasi kepada Anda. Ini berarti jawaban Anda tersimpan aman di server kami.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_14_TEXT':`  <li>
    Bagus! Untuk informasi lebih lanjut tentang hasil dan proses rekrutmen Anda, hubungi perekrut Anda.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_15_TEXT':`<li>
    Anda menerima email ini karena perekrut ingin meminta dokumen Anda. Baca email dengan cermat untuk mengetahui dokumen apa yang diminta, dan balas email dengan melampirkan dokumen Anda. Dokumen harus dalam format PDF.
  </li>`,

    'CANDIDATE_SUPPORT_FAQ_16_TEXT':`<li>
    Tim ASTRNT selalu menyambut lamaran berupa video. Gunakan kode "talentpool" di ASTRNT untuk melamar kerja di perusahaan kami, atau <a href="https://bit.ly/astrnt_talentpool" target="_blank">klik di sini</a>.
  </li>`,

    //End-CandidateSupport

   //End-PartnersPageNew

   //start-PageNotFound
   '404_OOPS': 'Ups!',
   '404_BUTTON_BACK': 'Kembali ke laman utama',
   '404_DESKTOP_HIDE_TEXT': '<span>Maaf, laman yang Anda cari tidak ada.</span><p>Tautan yang Anda klik mungkin rusak atau laman telah dihapus.<br/>Silakan kembali ke laman utama.</p>',
   '404_MOBILE_HIDE_TEXT': '<span>Maaf, laman yang Anda cari tidak ada.</span><p>Tautan yang Anda klik mungkin rusak atau laman telah dihapus. Silakan kembali ke laman utama.</p>',
   //end-PageNotFound

   // Start-About
   'ABOUT_CARD_1_TITLE': 'Jika Ingin Melangkah Jauh, Melangkahlah Bersama',
   'ABOUT_CARD_1_TEXT': `Misi kami di ASTRNT adalah membuat rekrutmen lebih cepat, menyenangkan & menarik bagi semua orang, dengan mengubah cara perusahaan mengenal kandidat & cara kandidat mengenal tempat kerja mereka. Kami juga mengutamakan video selfie. Mengapa video? Ini untuk manusia. Selain itu, mereka kaya akan data.`,
   'ABOUT_CARD_2_TITLE': 'Sejarah',
   'ABOUT_CARD_2_TEXT': `Setelah bertahun-tahun membangun tim dan merekrut agensi, kami tahu kami dapat berkontribusi bagi ekosistem talenta. Dari situlah ASTRNT lahir. Misi awal kami adalah menjadikan rekrutmen cepat, menyenangkan & menarik bagi semua orang dengan mengutamakan video selfie. 
      Dengan prioritas yang sama, kami melakukan lebih banyak lagi. Kami ingin menciptakan akses yang lebih kuat yang lebih adil, cepat, dan besar menuju peluang dan pertumbuhan pribadi yang lebih kuat kepada pemberi kerja, universitas, dan seluruh ekosistem talenta.`,
   'ABOUT_CARD_3_TITLE': 'Inilah tim kami',
   'ABOUT_CARD_3_TEXT': 'Kami menghargai rekan kerja kami di ASTRNT.<br/>Ini tim kami, sekumpulan manusia penuh senyum yang bekerja keras untuk memberikan yang terbaik bagi tim Anda.',
   'ABOUT_CARD_4_TITLE': 'Kepemimpinan di ASTRNT',
   'ABOUT_CARD_4_CEO_DESC': `Insinyur Sipil dengan pengalaman di perusahaan rintisan Asia dan berinvestasi di India, Shanghai dan Indonesia, di bidang sumber daya manusia dan pertumbuhan.`,
   'ABOUT_CARD_4_CTO_DESC': `Lulusan Universitas Lund ini adalah CTO start-up berpengalaman dalam teknologi keuangan dan industri sumber daya manusia.`,
   'ABOUT_CARD_4_MARKETING_DESC': `20+ tahun pengalaman dalam periklanan & pemasaran digital yang fokus pada strategi dan produksi untuk brand internasional. Latar belakang pendidikan yang kuat & pendiri POPSkul.id`,
   'ABOUT_CARD_4_PE_QALEAD_DESC': `Mantan pimpinan 123RF dan OVO, Galih memimpin tim pengembangan di Bandung dan mendukung peran CTO dalam manajemen dan produksi tim.`,
   'ABOUT_CARD_4_TALENT_DESC': `Spesialis asesmen dengan 10+ tahun pengalaman di bidang psikometri, pusat asesmen, pengembangan kompetensi, dan teknologi asesmen/rekrutmen.`,
   'ABOUT_CARD_4_PM_DESC': `Master full-stack Scrum dengan lebih dari 10 tahun pengalaman unik dalam pengembangan perangkat lunak dan pengembangan bisnis.`,
   'ABOUT_CARD_4_PIL_DESC': `Vinsen memimpin proses integrasi ASTRNT, mulai dari ATS hingga dengan produk talenta SaaS lain dan platform internal berskala enterprise. Dia adalah orang yang tepat untuk beradaptasi dengan <i>flow</i> kerja anda.`,
   'ABOUT_CARD_5_TITLE': `Semua ini tentang kebersamaan`,
   'ABOUT_CARD_5_SUBTITLE': 'Ingin bergabung dengan kami?',
   'ABOUT_CARD_5_TEXT': 'Kami selalu mempertimbangkan rekrutmen baru untuk semua posisi. Jika Anda tertarik dengan ASTRNT, cobalah wawancara singkat untuk menjadi kandidat kami.',
   'ABOUT_CARD_5_TEXT2': 'Hanya dalam 5 menit Anda bisa mecoba platform kami! Kami senang mengenal Anda.',
   'ABOUT_CARD_5_CHILD_TITLE': 'Bergabung dalam talent pool ASTRNT',
   'ABOUT_CARD_5_CHILD_TEXT': `Bergabunglah dengan komunitas talenta dan dapatkan informasi kesempatan untuk bergabung dalam tim, berita tentang perusahaan, serta kabar karir yang relevan dengan Anda.`,
   'ABOUT_CARD_5_CHILD_BUTTON': 'Lakukan wawancara pendek',
   // End-About

   // Start-feature
   'FEATURE_TITLE': 'Features made for your success',
   'FEATURE_1_TITLE': 'Wawancara Video',
   'FEATURE_1_TEXT': `Sistem video terintegrasi kami berfungsi di semua perangkat berkamera. Video asinkron membantu beberapa kandidat mengikuti penilaian wawancara bersamaan, sehingga menghemat waktu dan meningkatkan skala organisasi.`,
   'FEATURE_2_TITLE': 'Penyusun Wawancara Terstruktur',
   'FEATURE_2_TEXT': 'Susun wawancara terstruktur khusus yang konsisten serta adil selama proses wawancara, dengan pustaka dan templat pertanyaan kami.',
   'FEATURE_3_TITLE': 'Penilaian Wawancara',
   'FEATURE_3_TEXT': 'Lebih cepat memahami dan menyaring kandidat yang memenuhi kriteria minimum melalui penilaian berlapis.',
   'FEATURE_4_TITLE': 'Video Sambutan',
   'FEATURE_4_TEXT': `Berikan kesan yang lebih baik melalui video sambutan bagi kandidat. Perkenalkan perusahaan dan pekerjaan secara singkat, beri arahan tentang cara mengikuti penilaian dan wawancara agar kandidat lebih santai dan percaya diri.`,
   'FEATURE_5_TITLE': 'Evaluasi Wawancara',
   'FEATURE_5_TEXT': 'Evaluasi wawancara otomatis akan menciptakan daftar pendek dengan cepat melalui seleksi hasil dan parameter yang  Anda inginkan.',
   'FEATURE_6_TITLE': 'Kolaborasi Keputusan Seleksi',
   'FEATURE_6_TEXT': 'Bekerja sama dengan berbagi profil dan hasil kandidat dengan tim, pemangku kepentingan, atau kolega lain yang terlibat.',
   'FEATURE_7_TITLE': 'Analisis & Pelaporan',
   'FEATURE_7_TEXT': 'Laporkan proses rekrutmen dari jumlah undangan wawancara yang dikirim, waktu respons kandidat, waktu penyelesaian, umpan balik, dan banyak lagi.',
   'FEATURE_8_TITLE': 'Izin',
   'FEATURE_8_TEXT': `Libatkan semua pemangku kepentingan. Berikan akses terkontrol ke manajer rekurtmen, klien, dan penilai untuk menilai, meninjau, dan melaporkan. Izinkan kandidat untuk menghapus data mereka demi mematuhi kewajiban privasi dan kontrol data.`,
   'FEATURE_9_TITLE': 'Pengawasan Tes',
   'FEATURE_9_TEXT': `Fitur pengawasan kami mendorong penilaian yang adil dan aman, mulai dengan kunci salin-tempel dasar, dan pengacakan pertanyaan-jawaban, hingga perekaman audiovisual dan pemantauan catatan untuk mencegah kecurangan.`,
   'FEATURE_10_TITLE': 'Intelejensi Artifisial (AI)',
   'FEATURE_10_TEXT': `Berikan perekrut dan penilai Anda wawasan otomatis tentang kandidat, sehingga menghemat waktu dan menjaga kualitas keputusan. Penilaian dan pemeringkatan otomatis, dan data yang sesuai dengan analisis personel Anda.`,
   'FEATURE_11_TITLE': 'Strengthen Your ATS',
   'FEATURE_11_TEXT': `For fast, easy recruiting we integrate into leading ATS platforms. We strongly believe in a recruitment 
       experience with the ATS/CRM at the core, supported by our integration. `,
   'FEATURE_12_TITLE': 'Dukungan penuh',
   'FEATURE_12_TEXT': 'Kami menawarkan dukungan penuh untuk kandidat dan perekrut. Mengobrol dengan kami atau email help@astrnt.co. Tim kami akan menjawab semua pertanyaan Anda.',
   'FEATURE_13_TITLE': 'Semua Perangkat, Aksesibilitas Tinggi',
   'FEATURE_13_TEXT': 'Kami pastikan partisipasi kandidat maksimal karena kami tersedia di Android, iOS, dan web. Kami tetap dapat diakses bahkan dengan koneksi internet yang buruk.',
   'FEATURE_14_TITLE': 'Solusi Penilaian (Layanan & Mitra)',
   'FEATURE_14_TEXT': 'Bekerja sama dengan mitra dan spesialis kami untuk membuat konten penilaian berbasis kompetensi untuk organisasi Anda. Kami selalu menemani Anda.',
   'FEATURE_15_TITLE': 'Hubungkan Alat Anda',
   'FEATURE_15_TEXT': 'Pastikan ATS Anda tetap menjadi dasbor pusat Anda. Kami berintegrasi dengan penyedia ATS terkemuka, dan menambahkan lebih banyak lagi. Ceritakan ATS Anda pada kami.',
   // End-feature


   //Start supportListNew
  'SUPPORT_CARD_LABEL_1':'Layanan bantuan termasuk dalam semua paket',
  'SUPPORT_CARD_LABEL_2':'Sesuai kenyamanan kandidat menggunakan desktop, iOS atau Android',
  'SUPPORT_CARD_LABEL_3':`Coba gratis, batalkan kapan saja`,

   //End supportListNew

   //Start DemonstrationPageNew
   'DEMO_CARD_TITLE':`Waktunya Demo!`,
   'DEMO_CARD_TEXT':`Kami akan mendemonstrasikan kekuatan platform kami yang akan membantu Anda merekrut lebih cepat & mengakselerasi kinerja organisasi Anda.`,
   'DEMO_CARD_TITLE_FORM':`Ceritakan sedikit tentang diri Anda, & kami akan cerita lebih banyak tentang ASTRNT. `,
   

    //FORM
    'DEMO_FORM_LABEL_1':`Nama Perusahaan/Organisasi *`,
    'DEMO_FORM_LABEL_2':`Alamat Email *`,
    'DEMO_FORM_LABEL_3':`Nama *`,
    'DEMO_FORM_LABEL_4':`Nomor Handphone *`,
    'DEMO_FORM_LABEL_5':`Pilih Tanggal`,
    'DEMO_FORM_LABEL_6':`Pilih Waktu`,
    'DEMO_FORM_LABEL_7':`Zona Waktu`,
    'DEMO_FORM_LABEL_8':`Bagaimana ASTRNT dapat membantu mencapai harapan Anda? *`,

    'DEMO_FORM_PLACEHOLDER_7':`Pilih Zona Waktu`,

   //End DemonstrationPageNew

   //Start TrainingPage
  'TRAINING_CARD_TITLE':`Upgrade your team’s skills`,
  'TRAINING_CARD_TEXT':`Our free training sessions are available for your team. Learn new technologies, best practices and more. Register your team below and one of our representatives will contact you to schedule a time. Training begins in August 2022.`,

  //FORM
  'TRAINING_FORM_LABEL_1':`Name of Company/Organisation *`,
  'TRAINING_FORM_LABEL_2':`Company Email Address *`,
  'TRAINING_FORM_LABEL_3':`Name, Point of Contact *`,
  'TRAINING_FORM_LABEL_4':`Mobile Phone, Point of Contact *`,
  'TRAINING_FORM_LABEL_5':`Which Topics Are Of Most Interest? *`,
  'TRAINING_FORM_LABEL_6':`Why does your team need to upgrade their skills? *`,
  'TRAINING_FORM_LABEL_7':`Training is online, however in person is available in Jabodetabek. What is your preferred method of delivery? *`,

  'TRAINING_FORM_PLACEHOLDER_6':`Type your answer here...`,
  'TRAINING_FORM_PLACEHOLDER_7':`Type your answer here...`,

  //End TrainingPage
}