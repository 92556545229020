import React from 'react'
// import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker'
import moment from 'moment'

class RenderTimePicker extends React.Component {
	render() {
		const { input, label, formClass, placeholder, type, PassReqMobile, isValidatingEmail, meta: { touched, error } } = this.props
   
		return(
			<div className={`form-group ${formClass} ${touched && error && 'has-error'} ${isValidatingEmail && 'has-warning'}`}>
		    <label>{label}</label>
		    {PassReqMobile}
		    <div>
            
            <TimePicker
                allowEmpty={true}
                showSecond={false}
                defaultOpenValue={moment().set({hour:0,minute:0,second:0})}
                // placeholder="HH:MM"
                name="time"
                // selected={this.state.tempTime}
                // value={this.state.tempTime}
                // onChange={this.handleChangeTime}
                // value={ valueTime||null}
                placeholder={placeholder}
                selected={input.value || null}
                onChange={input.onChange} 
                />
            
		      {touched && error && <div className="help-block"><div dangerouslySetInnerHTML={{__html: error}}></div></div>}
		      {isValidatingEmail && <div className="help-block">Checking your email...</div>}
		    </div>
		  </div>
		)
	}
}


export default RenderTimePicker
