// @flow

import React, { Fragment } from 'react'
import qs from 'query-string';
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import { Map,fromJS } from 'immutable'

import {
	Field,
	reduxForm,
    change,
    formValueSelector,
} from 'redux-form'

import RenderInputV2 from './users/RenderInputV2'
import RenderTextareaV2 from './users/RenderTextareaV2'
import RenderDatePicker from './users/RenderDatePicker'
import RenderTimePicker from './users/RenderTimePicker'
import RenderSelectV2 from './users/RenderSelectV2'
import validateDemonstrationForm from './validateDemonstrationForm'

import $ from 'jquery'
import {Prompt} from 'react-router-dom'
import {demoSendRequest} from '../../actions/partnership'
import {getTimeZones} from '../../actions/global'
import SweetAlert from 'sweetalert-react'
import { renderToStaticMarkup } from 'react-dom/server';
import {TextRender} from './TextRender';
import {getOSInfo , translate } from '../../helpers/global'
import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'
// import { usersValidateEmailOnUserRegisterWizardFormRequest, usersSaveDataRequest, userVerify } from '../../../actions/users'

type Props = {
	pristine: boolean,
	submitting: boolean,
	handleSubmit: Function,
	asyncValidating: boolean,
	usersValidateEmailOnUserRegisterWizardFormRequest: Function,
	usersSaveDataRequest: Function,
	userVerify: Function,
};

const normalizeEmail = (value, previousValue) => {
	return value.trim()
}


class DemonstrationForm extends React.Component<Props> {
	constructor(props) {
    super(props)
    
    this.state = {
      isBlocking: false,
      hasUpper:false,
      hasLower:false,
      hasNumber:false,
      hasEightChar:false,
      passwordShown:false,
      isFocusNewPass:false
    }

		bindAll(this, [ 
      'handleSaveData',
      'renderError',
		])
	}


  componentDidMount(){
    const{ getTimeZones }=this.props

    getTimeZones();
  }

  renderError({ meta: { touched, error } }) {
    return touched && error ? <div><span className="error">{error}</span></div> : false
    }

  onUnload(e) {
    e.preventDefault()
    e.returnValue = ''
  }

  componentDidUpdate() {
    const { isBlocking } = this.state
    if(isBlocking) {
      window.addEventListener('beforeunload', this.onUnload)
    } else {
      window.removeEventListener('beforeunload', this.onUnload)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  handleSaveData(values) {
    const{
			history,
      location,
      form_values,
		}=this.props
    // const queryParams = qs.parse(location.search);
    // const newQueries = { ...queryParams, popup:false};
    // history.push({ search: qs.stringify(newQueries) });
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('submit', 'success');
    
    // entry.time = moment(values.time).format('HH:MM')
    const entry = values
    
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
		this.props.demoSendRequest(form_values)
  }

  render() {
  	const {
  		pristine,
  		submitting,
  		handleSubmit,
  		asyncValidating,
        globalSweetAlertData,
        globalGetTimezones,
        globalGetLanguages,
  	} = this.props

    const {passwordShown} = this.state

    
    const timezoneOptions = globalGetTimezones.map(timezone => ({
			value: timezone.get('id'),
			label: timezone.get('name'),
			id: `job-form_option_timezone_${timezone.get('id')}`
		}))
    
		timezoneOptions.unshift({value: '', label: translate(globalGetLanguages,'DEMO_FORM_PLACEHOLDER_7'), disabled: true})
   
    return(
    	<Fragment>
            
            <SweetAlert 
            {...globalSweetAlertData.toJS()} />
        {/* <Prompt
          when={this.state.isBlocking}
          message={location => `Data is not saved! Are you sure you want to leave?`}
        /> */}
        
        <ul className="the-form">
          <li>
            <Field
            component={RenderInputV2}
            name="company"
            formClass={'partnership-form'}
            placeholder="e.g: Livit International"
            label={translate(globalGetLanguages,'DEMO_FORM_LABEL_1')} />
          </li>
        
          <li>
            <Field
            component={RenderInputV2}
            name="email"
            props={{
              isValidatingEmail: asyncValidating
            }}
            label={translate(globalGetLanguages,'DEMO_FORM_LABEL_2')}
            formClass={'partnership-form'}
            placeholder="e.g: adriane@mail.com"
            normalize={normalizeEmail} />
          </li>
          <li>
            <Field
            component={RenderInputV2}
            name="name"
            formClass={'partnership-form'}
            placeholder="e.g: John Dean"
            label={translate(globalGetLanguages,'DEMO_FORM_LABEL_3')} />
          </li>
          <li>
            <Field
            component={RenderInputV2}
            name="phone"
            type="number"
            formClass={'partnership-form'}
            label={translate(globalGetLanguages,'DEMO_FORM_LABEL_4')}
            placeholder="e.g: +61 232 923 322"
            className="no-spinner" />
          </li>

          <li style={{position: 'relative'}}>
            <Field
               component={RenderDatePicker}
               name="date"
               label={translate(globalGetLanguages,'DEMO_FORM_LABEL_5')}
               // placeholder={`e.g: ${moment().format('D/M/yyyy')}`}
               placeholder="DD/MM/YYYY"
            />
            <i className="far fa-calendar"></i>
          </li>

          <li style={{position: 'relative'}}>
          <Field
               component={RenderTimePicker}
               name="time"
               label={translate(globalGetLanguages,'DEMO_FORM_LABEL_6')}
               // placeholder={`e.g: ${moment().set({hour:0,minute:0,second:0}).format('HH:MM')} HH:MM`}
               placeholder="HH:MM"
            />
            <i className="far fa-clock"></i>
        
          </li>

          <li>
            <Field
            component={RenderSelectV2}
            name="zone"
            options={timezoneOptions}
            formClass={'partnership-form'}
            label={translate(globalGetLanguages,'DEMO_FORM_LABEL_7')}
            placeholder={translate(globalGetLanguages,'DEMO_FORM_PLACEHOLDER_7')}
            className="no-spinner" />
          </li>
          
          <li id="how-do-you-hope">
            <Field
            component={RenderTextareaV2}
            formClass={'partnership-form'}
            name="desc"
            id="objective"
            placeholder="I hope ASTRNT can help us..."
            label={translate(globalGetLanguages,'DEMO_FORM_LABEL_8')} />
          </li>
          <li>
            <button
              onClick={handleSubmit(this.handleSaveData)}
              disabled={submitting || pristine || asyncValidating}
              className="btn-start-trial"
              type="submit">{translate(globalGetLanguages,'PARTNERS_FORM_SUBMIT_BUTTON')}</button>
          </li>
        
        </ul>
    	</Fragment>
    )
  }
}



  DemonstrationForm = reduxForm({
  form: 'DemonstrationForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate: validateDemonstrationForm
})(DemonstrationForm)


const selector = formValueSelector('DemonstrationForm')
const mapStateToProps = (state, ownProps) => {
  const user = state.user
  const initialValues = ({
        'company': '',
        'email': '',
        'name': '',
        'phone': '',
        // 'company': user.get('company'),
        // 'email': user.get('email'),
        // 'name': user.get('name'),
        // 'phone': user.get('phone'),
        'desc':'',
        'date':'',
        'time':'',
        'zone':'',
      });
	return {
    initialValues,
    form_values: selector(
        state,
        'company',
        'email',
        'name',
        'phone',
        'desc',
        'date',
        'time',
        'zone'
    ),
    globalSweetAlertData: state.globalSweetAlertData,
    location:ownProps.location,
    globalGetTimezones:state.globalGetTimezones,
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
    // usersValidateEmailOnUserRegisterWizardFormRequest: (email) => dispatch(usersValidateEmailOnUserRegisterWizardFormRequest(email)),
    // usersSaveDataRequest: (formName) => dispatch(usersSaveDataRequest(formName)),
    // userVerify: () => dispatch(userVerify()),
    getTimeZones:()=>dispatch(getTimeZones()),
    demoSendRequest:(data)=> dispatch(demoSendRequest(data)),
    changeFieldValue: (field, value) => dispatch(change('DemonstrationForm', field, value, true, true)),
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DemonstrationForm)
