// @flow

import React from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'

import bindAll from 'lodash/bindAll'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import iconDiscount from '../../images/discount-icon.png'

import ReactHtmlParser from 'react-html-parser';

import {getOSInfo , translate } from '../../helpers/global'

type Props = {};

class PricingNew extends React.Component<Props> {
	constructor() {
		super()

		bindAll(this, [
			'handleRegisterLink'
		])
	}

	componentDidMount() {
		window.scrollTo(0, 0)
		$('ul.price-faq li a.dropdown-new').click(function(event){
		    event.preventDefault();
		    
		    if(!$(this).hasClass('is-on')){
		        $(this).addClass('is-on').parent().find('div').slideDown('fast');
		    }else{
		        $(this).removeClass('is-on').parent().find('div').slideUp('fast');
		    }
		});
		$('.overlay, .close-button').click(function(event){
	        event.preventDefault();
	        
	        $('#modal-block').slideUp('fast');
	        $('.overlay').fadeOut('fast');
			$('#btn-es1').addClass('hide')
			$('#btn-es2').addClass('hide')
	    });
	}

	handleRegisterLink(e: Object): void {
		e.preventDefault()

		if (process.env.NODE_ENV === 'development') {
			window.location = 'http://localhost:8000/register'
		} else if (process.env.NODE_ENV === 'beta') {
			window.location = 'https://beta.astrnt.co/register'
		} else {
			window.location = 'https://app.astrnt.co/register'
		}
	}

	showDropdown() {
		$('.dropdown-content').css('display', 'block')
	}

	showModal0() {
		$('#modal-block').css('display', 'block')
		$('.overlay').fadeIn('fast');
		$('#btn-free').removeClass('hide')
		$('#btn-es1').addClass('hide')
		$('#btn-es2').addClass('hide')
	}

	showModal1() {
		$('#modal-block').css('display', 'block')
		$('.overlay').fadeIn('fast');
		$('#btn-free').addClass('hide')
		$('#btn-es1').removeClass('hide')
		$('#btn-es2').addClass('hide')
	}

	showModal2() {
		$('#modal-block').css('display', 'block')
		$('.overlay').fadeIn('fast');
		$('#btn-free').addClass('hide')
		$('#btn-es1').addClass('hide')
		$('#btn-es2').removeClass('hide')
	}

	chooseOpt(val) {
	  if(val === '2') {
	  	$('.dropdown-content').css('display', 'none')
			$('.plan-0').addClass('hide')
			$('.plan-1').addClass('hide')
			$('.plan-2').removeClass('hide')
			$('.plan-3').addClass('hide')
	  } else if (val === '3') {
	  	$('.dropdown-content').css('display', 'none')
		  	$('.plan-0').addClass('hide')
			$('.plan-1').addClass('hide')
			$('.plan-2').addClass('hide')
			$('.plan-3').removeClass('hide')
	  } else if(val === '1') {
	  	$('.dropdown-content').css('display', 'none')
		  	$('.plan-0').addClass('hide')
			$('.plan-1').removeClass('hide')
			$('.plan-2').addClass('hide')
			$('.plan-3').addClass('hide')
	  } else {
		$('.dropdown-content').css('display', 'none')
			$('.plan-0').removeClass('hide')
			$('.plan-1').addClass('hide')
			$('.plan-2').addClass('hide')
			$('.plan-3').addClass('hide')
	}
	}

  render() {

	const {
		globalGetLanguages,
	  } = this.props

	const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
			<div id="pricing">
				<div id="modal-block" className="pop" style={{display: 'none'}}>
					<div className="pop-head">
						<span className='invisible'>{translate(globalGetLanguages,'REGISTER_POP_IF_YOU_APPLICANT')}</span>
						<a className="close-button" href="#"><i className="fa fa-times" /></a>
					</div>
					<div className="pop-body">
						<div className={`div-info`}>
							<h1>Get Exactly What You Need</h1>
							<p>At the check-out you will be able to add additional credits to top-up those already provided in your plan.</p>
							<h2><span className='dollar'>$</span> 87.5 <span className='slash-block'>/block</span></h2>
							<label>(1 block = 25 credits)</label>
							<div id='btn-free' className='div-button hide'><Link to={{pathname: `/register`, query: { plan: 'free' }}}>{translate(globalGetLanguages,'GET_ASTRNT_FOR_FREE')}</Link></div>
							<div id='btn-es1' className='div-button hide'><Link to={{pathname: `/register`, query: { plan: 'es1t' }}}>{translate(globalGetLanguages,'GET_FREE_TRIAL')}</Link></div>
							<div id='btn-es2' className='div-button hide'><Link to={{pathname: `/register`, query: { plan: 'es2t' }}}>{translate(globalGetLanguages,'GET_FREE_TRIAL')}</Link></div>
						</div>
					</div>
				</div>

				<div className="overlay" style={{display: 'none'}} />

	        <HeaderNewClear isOnStatusPricing="is-on" />
	        <div className="pad-top desktop-hide" />
	        <section className="section-pricing">
	          <div className="price-tit">
			  {renderHTML(translate(globalGetLanguages,'PRICING_TITLE'))}
	          	<p>{translate(globalGetLanguages,'PRICING_DESCRIPTION')}</p>
	          	<span>{translate(globalGetLanguages,'PRICING_LABEL_PRICE_IN_USD')}</span>
	          </div>
	          <ul className="the-price">
	            <li>
	              <div className="box-price box-price-desktop">
	                <div className="tit-price">
	                	<div>{translate(globalGetLanguages,'PLANS')}</div>
	                	<div>Basic</div>
	                	<div>{translate(globalGetLanguages,'ESSENTIAL')}</div>
	                	<div>{translate(globalGetLanguages,'ENTERPRISE')}</div>
	                </div>
	                <hr className="divider" />
	                <div className="tit-price flex-container vertical--item-top">
	                	<div>{translate(globalGetLanguages,'PRICING')}</div>
						<div>
							{/* {ReactHtmlParser(translate(globalGetLanguages,'PRICING_ESSENTIAL_DESC'))}	 */}
	                		<p><strong>Plan designed for</strong> Startups and Small Enterprises, also NGO</p>
	                		<div className="div-free flex-container">
	                			<div>
									<div className='div-harga'><b>Free</b></div>
	                				<Link className="get-astrnt-free-btn" to={{pathname: `/register`, query: { plan: 'free' }}}>{translate(globalGetLanguages,'GET_ASTRNT_FOR_FREE')}</Link>
	                			</div>
	                		</div>
	                	</div>
	                	<div className='div-plan-not-basic'>
							{/* {ReactHtmlParser(translate(globalGetLanguages,'PRICING_ESSENTIAL_DESC'))} */}
	                		<p><strong>Plan designed for</strong> Startups, Small and Medium Entreprises</p>
	                		<div className="div-dollar flex-container">
	                			<div>
									<div className='div-harga'>{ReactHtmlParser(translate(globalGetLanguages,'PRICING_ESSENTIAL_PRICE_MONTHLY'))}</div>
	                				<Link to={{pathname: `/register`, query: { plan: 'es1' }}}>{translate(globalGetLanguages,'GET_FREE_TRIAL')}</Link>
	                			</div>
	                			<div>
									<div className='div-harga'>
										{ReactHtmlParser(translate(globalGetLanguages,'PRICING_ESSENTIAL_PRICE_YEARLY'))}
										<p style={{minHeight: '0'}}>{translate(globalGetLanguages,'PRICING_SAVE_COMPARED_MONTHLY')}</p>
									</div>
	                				<Link to={{pathname: `/register`, query: { plan: 'es2' }}}>{translate(globalGetLanguages,'GET_FREE_TRIAL')}</Link>
	                			</div>
	                		</div>
	                	</div>
	                	<div className='div-plan-not-basic' style={{padding: '0'}}>
						{ReactHtmlParser(translate(globalGetLanguages,'PRICING_PLAN_FOR_LARGE_VOLUMES'))}
	                		<div className="div-dollar">
	                			<div style={{width: '100%'}}>
									<div className='div-harga'><strong>{translate(globalGetLanguages,'PRICING_PLAN_CUSTOM_PRICING')}</strong></div>
	                				<Link to={{pathname: `/register`, query: { plan: 'en1' }}}>{translate(globalGetLanguages,'GET_FREE_TRIAL')}</Link>
	                				<p className="note-custom-pricing">{translate(globalGetLanguages,'PRICING_OFFER_ENTERPRISE')}</p>
	                			</div>
	                		</div>
	                	</div>
	                </div>
	              </div>

	              <div className="box-price box-price-mobile">
	                <div className="tit-price tit-price-mobile horizontal-item--between">
	                	<div className="plan-0">Basic</div>
	                	<div className="plan-1 hide">{translate(globalGetLanguages,'ESSENTIAL')}</div>
	                	<div className="plan-2 hide">{translate(globalGetLanguages,'ESSENTIAL')}</div>
	                	<div className="plan-3 hide">{translate(globalGetLanguages,'ENTERPRISE')}</div>
	                	{/*<div className="plan-selector">Switch Plan<i className="fas fa-sort" /></div>*/}
	                	<div className="dropdown-select">
	                	  <button className="dropbtn" onClick={() => this.showDropdown()}>{translate(globalGetLanguages,'PRICING_SWITCH_PLAN')}<i className="fas fa-chevron-right" /></button>
	                	  <div className="dropdown-content">
		                	  <div onClick={() => this.chooseOpt('0')}>Basic</div>
		                	  <div onClick={() => this.chooseOpt('1')}>{translate(globalGetLanguages,'PRICING_ESSENTIAL_MONTLY')}</div>
		                	  <div onClick={() => this.chooseOpt('2')}>{translate(globalGetLanguages,'PRICING_ESSENTIAL_YEARLY')}</div>
		                	  <div onClick={() => this.chooseOpt('3')}>{translate(globalGetLanguages,'ENTERPRISE')}</div>
	                	  </div>
	                	</div>
	                </div>
	                <div className="tit-price flex-container vertical--item-top">
	                	<div>
	                		<p className="plan-0"><strong>Plan designed for</strong> Startups and Small Enterprises, also NGO</p>
	                		<p className="plan-1 hide">{ReactHtmlParser(translate(globalGetLanguages,'PRICING_PLAN_DESIGNED_MOBILE'))}</p>
	                		<p className="plan-2 hide">{ReactHtmlParser(translate(globalGetLanguages,'PRICING_PLAN_DESIGNED_MOBILE'))}</p>
	                		<p className="plan-3 hide">{ReactHtmlParser(translate(globalGetLanguages,'PRICING_PLAN_DESIGNED_MOBILE_LARGE'))}</p>
	                		<div className="div-dollar div-dollar-mobile flex-container">
								<div className="plan-0">
									<b>Free</b>
										
										<Link style={{marginTop: '48px'}} to={{pathname: `/register`, query: { plan: 'free' }}}>Get Astrnt For Free</Link>
	                			</div>
	                			<div className="plan-1 hide">
								{ReactHtmlParser(translate(globalGetLanguages,'PRICING_ESSENTIAL_PRICE_MONTHLY'))}
	                				
	                				<Link style={{marginTop: '48px'}} to={{pathname: `/register`, query: { plan: 'es1' }}}>{translate(globalGetLanguages,'GET_FREE_TRIAL')}</Link>
	                			</div>
	                			<div className="plan-2 hide">
								{ReactHtmlParser(translate(globalGetLanguages,'PRICING_ESSENTIAL_PRICE_YEARLY'))}
	                				
	                				<p>{translate(globalGetLanguages,'PRICING_SAVE_COMPARED_MONTHLY')}</p>
	                				<Link to={{pathname: `/register`, query: { plan: 'es2' }}}>{translate(globalGetLanguages,'GET_FREE_TRIAL')}</Link>
	                			</div>
	                			<div className="plan-3 hide">
              						<strong>{translate(globalGetLanguages,'PRICING_PLAN_CUSTOM_PRICING')}</strong>
              						<Link to={{pathname: `/register`, query: { plan: 'en1' }}}>{translate(globalGetLanguages,'GET_FREE_TRIAL')}</Link>
              						<p className="note-custom-pricing">{translate(globalGetLanguages,'PRICING_OFFER_ENTERPRISE')}</p>
	                			</div>
	                		</div>
	                	</div>
	                </div>
	              </div>

	              <div className="comparison-list comparison-list-desktop">
	              	<div className="feature-list">
	              		<label>{translate(globalGetLanguages,'ASSESS_TRACK_SELECT')}</label>
						<div className="list-compare">
							<div>Applicant Tracking</div>
							<div>Always Free</div>
							<div>Always Free</div>
							<div>Always Free</div>
							<div>Always Free</div>
						</div>
	              		<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_#_CANDIDATE_RESPONSE_1')}</div>
							<div>5/month<a onClick={() => this.showModal0()}>want more?</a></div>
							<div>{translate(globalGetLanguages,'PRICING_#_CANDIDATE_RESPONSE_2')}<a onClick={() => this.showModal1()}>want more?</a></div>
							<div>{translate(globalGetLanguages,'PRICING_#_CANDIDATE_RESPONSE_3')}<a onClick={() => this.showModal2()}>want more?</a></div>
							<div>{translate(globalGetLanguages,'UNLIMITED')}</div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_#_USER_TEAM_MEMBER_RESPONSE')}</div>
							<div>{translate(globalGetLanguages,'UNLIMITED')}</div>
							<div>{translate(globalGetLanguages,'UNLIMITED')}</div>
							<div>{translate(globalGetLanguages,'UNLIMITED')}</div>
							<div>{translate(globalGetLanguages,'UNLIMITED')}</div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_#_OF_JOBS')}</div>
							<div>{translate(globalGetLanguages,'UNLIMITED')}</div>
							<div>{translate(globalGetLanguages,'UNLIMITED')}</div>
							<div>{translate(globalGetLanguages,'UNLIMITED')}</div>
							<div>{translate(globalGetLanguages,'UNLIMITED')}</div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_VIDEO_INTERVIEW')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_MCQ')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_FTQ')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_RANDOM_QUESTION')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_VOICE_TO_TEXT')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_PROCTORING')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div>{translate(globalGetLanguages,'PRICING_AVAILABLE_UPON_REQUEST')}</div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_CO_BRAND')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_CANDIDATE_REVIEWER')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div>{translate(globalGetLanguages,'PRICING_INCLUDED_WHEN_AVAILABLE')}</div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_QUESTIONS_BANK')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div>{translate(globalGetLanguages,'PRICING_AVAILABLE_UPON_REQUEST')}</div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_CANDIDATE_DOWNLOAD')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div>{translate(globalGetLanguages,'PRICING_INCLUDED_WHEN_AVAILABLE')}</div>
						</div>
	              	</div>
	              	<div className="feature-list">
						<label>{translate(globalGetLanguages,'PRICING_CANDIDATE_EXPERIENCE')}</label>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_CANDIDATE_EXPERIENCE_TEXT_1')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div>{translate(globalGetLanguages,'PRICING_AVAILABLE_UPON_REQUEST')}</div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_CANDIDATE_EXPERIENCE_TEXT_2')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_CANDIDATE_EXPERIENCE_TEXT_3')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div>{translate(globalGetLanguages,'PRICING_INCLUDED_WHEN_AVAILABLE')}</div>
						</div>
					</div>
					<div className="feature-list">
						<label>{translate(globalGetLanguages,'PRICING_DATA_SECURITY')}</label>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_DATA_SECURITY_TEXT_1')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
					</div>
					<div className="feature-list">
						<label>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT')}</label>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT_TEXT_1')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT_TEXT_2')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT_TEXT_3')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT_TEXT_4')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT_TEXT_5')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
					</div>
					<div className="feature-list">
						<label>{translate(globalGetLanguages,'PRICING_INTEGRATION')}</label>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_INTEGRATION_TEXT_1')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
					</div>
					<div className="feature-list">
						<label>{translate(globalGetLanguages,'PRICING_SUPPORT')}</label>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_SUPPORT_TEXT_1')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_SUPPORT_TEXT_2')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_SUPPORT_TEXT_3')}</div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_SUPPORT_TEXT_4')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
						<div className="list-compare">
							<div>{translate(globalGetLanguages,'PRICING_SUPPORT_TEXT_5')}</div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-minus" /></div>
							<div><i className="fas fa-check" /></div>
						</div>
					</div>
	              </div>

	              <div className="comparison-list comparison-list-mobile">
	              	<div className="feature-list">
	              		<label>{translate(globalGetLanguages,'ASSESS_TRACK_SELECT')}</label>
						  <div className="list-compare">
							<div>Applicant Tracking</div>
							<div>Always Free</div>
						</div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_#_CANDIDATE_RESPONSE_1')}</div>
	              			<div>
		              			<span className="plan-0">5/month<br/><a onClick={() => this.showModal0()}>want more?</a></span>
		              			<span className="plan-1 hide">{translate(globalGetLanguages,'PRICING_#_CANDIDATE_RESPONSE_2')}<br/><a onClick={() => this.showModal1()}>want more?</a></span>
		              			<span className="plan-2 hide">{translate(globalGetLanguages,'PRICING_#_CANDIDATE_RESPONSE_3')}<br/><a onClick={() => this.showModal2()}>want more?</a></span>
		              			<span className="plan-3 hide">{translate(globalGetLanguages,'UNLIMITED')}</span>
		              		</div>
	              		</div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_#_USER_TEAM_MEMBER_RESPONSE')}</div><div>{translate(globalGetLanguages,'UNLIMITED')}</div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_#_OF_JOBS')}</div><div>{translate(globalGetLanguages,'UNLIMITED')}</div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_VIDEO_INTERVIEW')}</div><div><i className="fas fa-check" /></div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_MCQ')}</div><div><i className="fas fa-check" /></div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_FTQ')}</div><div><i className="fas fa-check" /></div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_RANDOM_QUESTION')}</div><div><i className="fas fa-check" /></div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_VOICE_TO_TEXT')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<i className="fas fa-check plan-3 hide" />
              				</div>
              			</div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_PROCTORING')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<i className="fas fa-check plan-3 hide" />
              				</div>
              			</div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_CO_BRAND')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<i className="fas fa-check plan-3 hide" />
              				</div>
              			</div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_CANDIDATE_REVIEWER')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<span className="plan-3 hide">{translate(globalGetLanguages,'PRICING_INCLUDED_WHEN_AVAILABLE')}</span>
              				</div>
              			</div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_QUESTIONS_BANK')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<span className="plan-3 hide">{translate(globalGetLanguages,'PRICING_AVAILABLE_UPON_REQUEST')}</span>
              				</div>
              			</div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_CANDIDATE_DOWNLOAD')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<span className="plan-3 hide">{translate(globalGetLanguages,'PRICING_INCLUDED_WHEN_AVAILABLE')}</span>
              				</div>
              			</div>
	              	</div>
	              	<div className="feature-list">
	              		<label>{translate(globalGetLanguages,'PRICING_CANDIDATE_EXPERIENCE')}</label>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_CANDIDATE_EXPERIENCE_TEXT_1')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<span className="plan-3 hide">{translate(globalGetLanguages,'PRICING_AVAILABLE_UPON_REQUEST')}</span>
              				</div>
              			</div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_CANDIDATE_EXPERIENCE_TEXT_2')}</div><div><i className="fas fa-check" /></div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_CANDIDATE_EXPERIENCE_TEXT_3')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<span className="plan-3 hide">{translate(globalGetLanguages,'PRICING_INCLUDED_WHEN_AVAILABLE')}</span>
              				</div>
              			</div>
	              	</div>
	              	<div className="feature-list">
	              		<label>{translate(globalGetLanguages,'PRICING_DATA_SECURITY')}</label>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_DATA_SECURITY_TEXT_1')}</div><div><i className="fas fa-check" /></div></div>
	              	</div>
	              	<div className="feature-list">
	              		<label>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT')}</label>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT_TEXT_1')}</div><div><i className="fas fa-check" /></div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT_TEXT_2')}</div><div><i className="fas fa-check" /></div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT_TEXT_3')}</div><div><i className="fas fa-check" /></div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT_TEXT_4')}</div><div><i className="fas fa-check" /></div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_CANDIDATE_MANAGEMENT_TEXT_5')}</div><div><i className="fas fa-check" /></div></div>
	              	</div>
	              	<div className="feature-list">
	              		<label>{translate(globalGetLanguages,'PRICING_INTEGRATION')}</label>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_INTEGRATION_TEXT_1')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<i className="fas fa-check plan-3 hide" />
              				</div>
              			</div>
	              	</div>
	              	<div className="feature-list">
	              		<label>{translate(globalGetLanguages,'PRICING_SUPPORT')}</label>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_SUPPORT_TEXT_1')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<i className="fas fa-check plan-3 hide" />
              				</div>
              			</div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_SUPPORT_TEXT_2')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<i className="fas fa-check plan-3 hide" />
              				</div>
              			</div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_SUPPORT_TEXT_3')}</div><div><i className="fas fa-check" /></div></div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_SUPPORT_TEXT_4')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<i className="fas fa-check plan-3 hide" />
              				</div>
              			</div>
	              		<div className="list-compare"><div>{translate(globalGetLanguages,'PRICING_SUPPORT_TEXT_5')}</div>
	              			<div>
	              				<i className="fas fa-minus plan-1" />
	              				<i className="fas fa-minus plan-2 hide" />
	              				<i className="fas fa-check plan-3 hide" />
              				</div>
              			</div>
	              	</div>
	              </div>

	              <div className="div-ngo-startup">
              		<label>{translate(globalGetLanguages,'PRICING_ARE_YOU_NGO')}</label>
              		<p>{translate(globalGetLanguages,'PRICING_ARE_YOU_NGO_TEXT_1')}</p>
              		<Link to={{pathname: `/register`, query: { plan: 'ngo' }}} className="button-apply-now">{translate(globalGetLanguages,'APPLY_NOW')}</Link>
              	</div>
	            </li>
	          </ul>
	        </section>
	        <div className="sec-blue">
		        <section>
		          <div className="tit-faq">{translate(globalGetLanguages,'PRICING_FAQ')}</div>
		          <ul className="price-faq">
		            <li>
		              <a className="dropdown-new" href="#">
					  {translate(globalGetLanguages,'PRICING_FAQ_QUESTION_1')}
		                <i className="fa fa-chevron-down" />
		              </a>
		              <div style={{display: 'none'}}>
		                <ul>
		                  <li>
						  {translate(globalGetLanguages,'PRICING_FAQ_ANSWER_1')}
		                  </li>
		                </ul>
		              </div>
		            </li>
		            <li>
		              <a className="dropdown-new" href="#">
					  {translate(globalGetLanguages,'PRICING_FAQ_QUESTION_2')}
		                <i className="fa fa-chevron-down" />
		              </a>
		              <div style={{display: 'none'}}>
		                <ul>
		                  <li>
		                  {ReactHtmlParser(translate(globalGetLanguages,'PRICING_FAQ_ANSWER_2'))}
		                  </li>
		                </ul>
		              </div>
		            </li>
		            <li>
		              <a className="dropdown-new" href="#">
					  {translate(globalGetLanguages,'PRICING_FAQ_QUESTION_3')}
		                <i className="fa fa-chevron-down" />
		              </a>
		              <div style={{display: 'none'}}>
		                <ul>
		                  <li>
		                   {translate(globalGetLanguages,'PRICING_FAQ_ANSWER_3')}
		                  </li>
		                </ul>
		              </div>
		            </li>
		            <li>
		              <a className="dropdown-new" href="#">
					  {translate(globalGetLanguages,'PRICING_FAQ_QUESTION_4')}
		                <i className="fa fa-chevron-down" />
		              </a>
		              <div style={{display: 'none'}}>
		                <ul>
		                  <li>
						  {translate(globalGetLanguages,'PRICING_FAQ_ANSWER_4')}
							</li>
		                </ul>
		              </div>
		            </li>
		            <li>
		              <a className="dropdown-new" href="#">
					  {translate(globalGetLanguages,'PRICING_FAQ_QUESTION_5')}
		                <i className="fa fa-chevron-down" />
		              </a>
		              <div style={{display: 'none'}}>
		                <ul>
		                  <li>
						  {translate(globalGetLanguages,'PRICING_FAQ_ANSWER_5')}
		                  </li>
		                </ul>
		              </div>
		            </li>
		            <li>
		              <a className="dropdown-new" href="#">
					  {translate(globalGetLanguages,'PRICING_FAQ_QUESTION_6')}
		                <i className="fa fa-chevron-down" />
		              </a>
		              <div style={{display: 'none'}}>
		                <ul>
		                  <li>
						  {translate(globalGetLanguages,'PRICING_FAQ_ANSWER_6')}
						</li>
		                </ul>
		              </div>
		            </li>
		          </ul>
		          <div className="cant">
		          	<strong>  {translate(globalGetLanguages,'PRICING_STILL_HAVE_QUESTION')}</strong>
					  {renderHTML(translate(globalGetLanguages,'PRICING_LETS_CHAT_WITH_US'))}
		          </div>
		        </section>
	        </div>
	        <FooterNew />
	      </div>
		)
  }
}


const mapStateToProps = (state, ownProps) => {
	return {
	  globalGetLanguages:state.globalGetLanguages,
	}
  }
  
  const mapDispatchToProps = (dispatch) => {
	return {
	}
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(PricingNew)

