import React from 'react'

class RenderSelect extends React.Component {
	render() {
		const { input, label, meta: { touched, error } } = this.props

		return(
			<div className={`form-group ${touched && error && 'has-error'}`}>
		    <label>{label}</label>
		    <div>
		    	<select
		    		{...input}
		    		className="form-control">
		    		<option value="" disabled>Select the number of roles</option>
		    	  <option value="Less than 10">Less than 10</option>
		    	  <option value="10 - 50">10 - 50</option>
		    	  <option value="51 - 100">51 - 100</option>
		    	  <option value="101 - 500">101 - 500</option>
		    	  <option value="500 - 1000">500 - 1000</option>
		    	  <option value="1000 - above">1000 - above</option>
		    	</select>
		      {touched && error && <div className="help-block">{error}</div>}
		    </div>
		  </div>
		)
	}
}


export default RenderSelect
