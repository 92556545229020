// @flow
import React,{Fragment} from 'react'
import {connect} from 'react-redux'
import FaqId from '../partials/FaqId'
import FaqEn from '../partials/FaqEn'
import { Link } from 'react-router-dom'
import ModalVideoFaq from'../partials/ModalVideoFaq'
import $ from 'jquery'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'

import icon7 from '../../styles/img/icon-homepage/icon-7.svg'
import icon4 from '../../styles/img/icon-homepage/icon-4.svg'

import iconGeneral from '../../styles/img/icon/general.svg'
import iconRecruiter from '../../styles/img/icon/reqruiter.svg'
import iconCandidate from '../../styles/img/icon/candidate.svg'

function getAllUrlParams(url) {

  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {

        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string'){
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

class FrequentlyAskedQuestionNew extends React.Component<Props>{
    constructor(props) {
		super(props)

	}

    componentDidMount() {
		window.scrollTo(0, 0)
    $('#public').attr('id','not-public');
    var liLenght;
    
    $('.search-faq input').keyup(function(){
        var searchVal = $(this).val().toLowerCase();

        $('.misc-content > ul.list-faq > li').addClass('hide').hide();
        
        $('.misc-content').each(function(){
            liLenght = $(this).find('> ul.list-faq > li').length;
            
            $(this).find('> ul.list-faq > li').each(function(index){
                var searchText = ($(this).text().toLowerCase()).replace('arrow-mid', '');

                if(searchText.indexOf(searchVal) != -1){
                    $(this).removeClass('hide').show();
                }
                
                if(index+1 == liLenght){
                    console.log($(this).parent().find('> li').length + ' ' + $(this).parent().find('> li.hide').length);
                    if($(this).parent().find('> li').length == $(this).parent().find('> li.hide').length){
                        $(this).parent().parent().find('.faq-empty').slideDown('fast').find('strong').text(searchVal);
                    }else{
                        $(this).parent().parent().find('.faq-empty').slideUp('fast');
                    }
                }
            });
        });

        
    });
    
    var thisTab,
        $thisDiv;
    
    $('.sec-faq ul li').on('click', 'a', function(event){
        event.preventDefault();
        
        if(!$(this).hasClass('is-on')){
           thisTab = $(this).attr('class').split('-')[1];
        
            $('.misc-content:visible').slideUp('fast');
            $('.misc-content#sec-' + thisTab).slideDown('fast');

            $('.sec-faq ul li a.is-on').removeClass('is-on');
            $(this).addClass('is-on');
        }
    });
    
    $('.misc-content ul.list-faq li a.dropdown-new').click(function(event){
        event.preventDefault();
        
        if(!$(this).hasClass('is-on')){
            $(this).addClass('is-on').parent().find('div').slideDown('fast');
        }else{
            $(this).removeClass('is-on').parent().find('div').slideUp('fast');
        }
    });
	}

  componentWillUnmount(){
    $('#not-public').attr('id','public')
  }

    render(){
        const {langId} = this.props
        const videoParams=getAllUrlParams().watchvideo
        return(
            <div id="faq">
            <HeaderNewClear />
            <div className="banner">
              <section>
                <div className="ban-text">
                  <div className="big-text">FAQ's, know us more <em>closely</em>.</div>
                  <div className="search-faq">
                    <input type="text" placeholder="Type keywords to find answers" />
                    <button><i className="fa fa-search" /></button>
                  </div>
                  Take a look below for answers to the most commonly asked questions.
                </div>
              </section>
              <div className="ban-img" />
            </div>
            <section className="misc-content" id="sec-general">
              <div className="faq-empty" style={{display: 'none'}}>
                <span><img src={icon7} /></span>
                Sorry we've not found result for keywords "<strong>123</strong>" on General section.
              </div>
              <ul className="list-faq">
                <li>
                  <a href="#" className="dropdown-new">
                    What is a code? Why do I need one?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>The ASTRNT Q&amp;A code is what ASTRNT uses to make sure you get the right questions. For every code, there is a specific set of questions and scenarios, depending on what your recruiter wants to know about you.</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    Where do I find my code?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>If:</p>
                    <ul className="use-num">
                      <li>You are invited by your recruiter to do a Q&amp;A on ASTRNT, please check your email to find your code.</li>
                      <li>You download the app because of an advertisement, please check the advertisement to find an open code.</li>
                      <li>You’d like to apply to ASTRNT, please see the last FAQ for our code.</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    I have a code for an ASTRNT Q&amp;A session, what should I do with it?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>You’re all set for your Q&amp;A session! There are two ways you can do it:</p>
                    <ul>
                      <li><strong>Doing the Q&amp;A session on ASTRNT mobile app</strong><br />Install the ASTRNT Q&amp;A mobile app (available in both <a href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247" target="_blank">iOS</a> and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank">Android</a>), input your code, and follow the instructions in the mobile app.</li>
                      <li><strong>Doing the Q&amp;A session on desktop browser</strong><br />Go to <a href="http://astrnt.co" target="_blank">http://astrnt.co</a>, click on "I have a code", and input your code. Then follow the instructions to complete your Q&amp;A session.</li>
                    </ul>
                    <p>We recommend you find a quiet place and enough time to do the test without distractions. Make sure you have a stable internet connection as well. Good luck!</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    I have received an invitation email, what do I do next?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>You’re all set for your Q&amp;A session! You can do it using our mobile apps or from your desktop browser.</p>
                    <ul>
                      <li><strong>Doing the Q&amp;A session on ASTRNT mobile app</strong><br />Install the ASTRNT Q&amp;A mobile app (available in both <a href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247" target="_blank">iOS</a> and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank">Android</a>), input your code, and follow the instructions in the mobile app.</li>
                      <li><strong>Doing the Q&amp;A session on desktop browser</strong><br />Go to <a href="http://astrnt.co" target="_blank">http://astrnt.co</a>, click on "I have a code", and input your code. Then follow the instructions to complete your Q&amp;A session.</li>
                    </ul>
                    <p>We recommend you find a quiet place and enough time to do the test without distractions. Make sure you have a stable internet connection as well. Good luck!</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    I can’t see my face on the screen, what should I do?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>Please check your camera permission. Here’s how:</p>
                    <ul>
                      <li><strong>Using Chrome</strong><br />Follow the instructions in <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">this link</a> to change camera access permission.</li>
                      <li><strong>Using Mozilla Firefox</strong><br />On menu bar, go to Tools &gt; Page Info &gt; Permissions, then scroll down to "Use the Camera". Make sure the website is allowed to use the camera.</li>
                      <li><strong>Using Opera</strong><br />By default, Opera will always ask you to allow a site to access your camera. If this isn’t the case, go to Settings &gt; Websites, under Camera, and click Manage Exceptions button. For more information, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCamera" target="_blank">click here</a>.</li>
                      <li><strong>Using ASTRNT Q&amp;A app on iOS</strong><br />Go to Settings &gt; Privacy &gt; Camera, and make sure ASTRNT has access to your camera.</li>
                      <li><strong>Using ASTRNT Q&amp;A app on Android</strong><br />Go to Settings &gt; Security &amp; Privacy &gt; Application Permissions and make sure ASTRNT has access to your camera.</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    The application isn’t recording my voice, what should I do?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>Please check your microphone permission. Here’s how:</p>
                    <ul>
                      <li><strong>Using Chrome</strong><br />Follow the instructions in <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">this link</a> to change camera access permission.</li>
                      <li><strong>Using Mozilla Firefox</strong><br />On menu bar, go to Tools &gt; Page Info &gt; Permissions, then scroll down to "Use the Microphone". Make sure the website is allowed to use the microphone.</li>
                      <li><strong>Using Opera</strong><br />By default, Opera will always ask you to allow a site to access your microphone. If this isn’t the case, go to Settings &gt; Websites, under Microphone, and click Manage Exceptions button. For more information, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageMic" target="_blank">click here</a>.</li>
                      <li><strong>Using ASTRNT Q&amp;A app on iOS</strong><br />Go to Settings &gt; Privacy &gt; Microphone, and make sure ASTRNT has access to your microphone.</li>
                      <li><strong>Using ASTRNT Q&amp;A app on Android</strong><br />Go to Settings &gt; Security &amp; Privacy &gt; Application Permissions and make sure ASTRNT has access to your microphone.</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    I can’t install the ASTRNT Q&amp;A mobile app. Why?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>The ASTRNT Q&amp;A app is available in both <a href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247" target="_blank">iOS</a> (iOS 9 &amp; above, including iPhone 5s) and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank">Android</a> (6.0 &amp; above, with minimum 2GB RAM). If your phone doesn’t fulfill this requirement, we recommend doing the Q&amp;A session on your desktop browser.</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    The Android app keeps crashing on my device. What should I do?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>Sorry about that! On a very rare occasion we do encounter a problem in Android, particularly Nexus devices. If you experience this with your device, we encourage you to report it to us. To complete your Q&amp;A session, we recommend using our <a href="http://astrnt.co/" target="_blank">website</a> on your desktop.</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    In which web browsers can I do my Q&amp;A session?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>We support <a href="https://www.google.com/intl/en/chrome/browser/" target="_blank">Google Chrome</a> (recommended), <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a>, and <a href="http://www.opera.com/" target="_blank">Opera</a>. Unfortunately, we do not currently support Safari, Microsoft Edge, and Internet Explorer. These browsers are still in the experimental stage since WebRTC support was only recently released in these systems. Our team is working hard to work out the bugs before we roll out support for these browsers.</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    I ran out of time but I haven’t completed all the questions. What do I do?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>Don’t worry, all the answers you sent before the time run out are uploaded to our database. However, Team ASTRNT don’t have any rights to give you a second chance. If you wish, you can contact your recruiter directly to request a second chance. If you think the timer ran out by mistake, please contact us at <a href="mailto:help@astrnt.co" target="_blank">help@astrnt.co</a>.</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    I got disconnected from the internet during my Q&amp;A session. What should I do?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>Unfortunately, if you are in the middle of your Q&amp;A session, the timer will keep running even though you get disconnected. Please try to reconnect immediately.</p>
                    <p>If you lost connection after you have completed all the questions, no need to worry because your answers are still there—you just need to reconnect to the internet and the upload process will continue. Our system does spot checks of your speed to avoid this problem.</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    Have you received my answers?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>At the end of your Q&amp;A session, you should see an upload page that shows the status of your upload. Once all answers are uploaded, our system will send you a confirmation email. This means that your answers are safe and sound in our servers.</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    I’ve finished my Q&amp;A session. How do I get my results? What do I do next?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>Great job! For further information about your results and recruitment process, please contact your recruiter directly.</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    I received an email asking for my document. What do I do?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>You received this email because the recruiter would like to have your document. Please read the email carefully to know what document is requested, and reply to the email with your document attached. The document must be in PDF format.</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    ASTRNT sounds awesome. How do I join the team?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>Team ASTRNT is always interested in receiving and watching video applications. Use the code "JOINASTRNTCO" on ASTRNT to apply to work with us.</p>
                  </div>
                </li>
              </ul>
            </section>
            <section className="misc-content" id="sec-reqruiter" style={{display: 'none'}}>
              <div className="faq-empty" style={{display: 'none'}}>
                <span><img src={icon7} /></span>
                Sorry we've not found result for keywords "<strong>123</strong>" on Recruiter section.
              </div>
              <ul className="list-faq">
                <li>
                  <a href="#" className="dropdown-new">
                    I have a code for an ASTRNT Q&amp;A session, what should I do with it?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>You’re all set for your Q&amp;A session! There are two ways you can do it:</p>
                    <ul>
                      <li><strong>Doing the Q&amp;A session on ASTRNT mobile app</strong><br />Install the ASTRNT Q&amp;A mobile app (available in both <a href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247" target="_blank">iOS</a> and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank">Android</a>), input your code, and follow the instructions in the mobile app.</li>
                      <li><strong>Doing the Q&amp;A session on desktop browser</strong><br />Go to <a href="http://astrnt.co" target="_blank">http://astrnt.co</a>, click on "I have a code", and input your code. Then follow the instructions to complete your Q&amp;A session.</li>
                    </ul>
                    <p>We recommend you find a quiet place and enough time to do the test without distractions. Make sure you have a stable internet connection as well. Good luck!</p>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    I have received an invitation email, what do I do next?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>You’re all set for your Q&amp;A session! You can do it using our mobile apps or from your desktop browser.</p>
                    <ul>
                      <li><strong>Doing the Q&amp;A session on ASTRNT mobile app</strong><br />Install the ASTRNT Q&amp;A mobile app (available in both <a href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247" target="_blank">iOS</a> and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank">Android</a>), input your code, and follow the instructions in the mobile app.</li>
                      <li><strong>Doing the Q&amp;A session on desktop browser</strong><br />Go to <a href="http://astrnt.co" target="_blank">http://astrnt.co</a>, click on "I have a code", and input your code. Then follow the instructions to complete your Q&amp;A session.</li>
                    </ul>
                    <p>We recommend you find a quiet place and enough time to do the test without distractions. Make sure you have a stable internet connection as well. Good luck!</p>
                  </div>
                </li>
              </ul>
            </section>
            <section className="misc-content" id="sec-candidate" style={{display: 'none'}}>
              <div className="faq-empty" style={{display: 'none'}}>
                <span><img src={icon7} /></span>
                Sorry we've not found result for keywords "<strong>123</strong>" on Candidate section.
              </div>
              <ul className="list-faq">
                <li>
                  <a href="#" className="dropdown-new">
                    I can’t see my face on the screen, what should I do?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>Please check your camera permission. Here’s how:</p>
                    <ul>
                      <li><strong>Using Chrome</strong><br />Follow the instructions in <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">this link</a> to change camera access permission.</li>
                      <li><strong>Using Mozilla Firefox</strong><br />On menu bar, go to Tools &gt; Page Info &gt; Permissions, then scroll down to "Use the Camera". Make sure the website is allowed to use the camera.</li>
                      <li><strong>Using Opera</strong><br />By default, Opera will always ask you to allow a site to access your camera. If this isn’t the case, go to Settings &gt; Websites, under Camera, and click Manage Exceptions button. For more information, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCamera" target="_blank">click here</a>.</li>
                      <li><strong>Using ASTRNT Q&amp;A app on iOS</strong><br />Go to Settings &gt; Privacy &gt; Camera, and make sure ASTRNT has access to your camera.</li>
                      <li><strong>Using ASTRNT Q&amp;A app on Android</strong><br />Go to Settings &gt; Security &amp; Privacy &gt; Application Permissions and make sure ASTRNT has access to your camera.</li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href="#" className="dropdown-new">
                    The application isn’t recording my voice, what should I do?
                    <i className="fa fa-chevron-circle-down" />
                  </a>
                  <div style={{display: 'none'}}>
                    <p>Please check your microphone permission. Here’s how:</p>
                    <ul>
                      <li><strong>Using Chrome</strong><br />Follow the instructions in <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">this link</a> to change camera access permission.</li>
                      <li><strong>Using Mozilla Firefox</strong><br />On menu bar, go to Tools &gt; Page Info &gt; Permissions, then scroll down to "Use the Microphone". Make sure the website is allowed to use the microphone.</li>
                      <li><strong>Using Opera</strong><br />By default, Opera will always ask you to allow a site to access your microphone. If this isn’t the case, go to Settings &gt; Websites, under Microphone, and click Manage Exceptions button. For more information, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCamera" target="_blank">click here</a>.</li>
                      <li><strong>Using ASTRNT Q&amp;A app on iOS</strong><br />Go to Settings &gt; Privacy &gt; Microphone, and make sure ASTRNT has access to your microphone.</li>
                      <li><strong>Using ASTRNT Q&amp;A app on Android</strong><br />Go to Settings &gt; Security &amp; Privacy &gt; Application Permissions and make sure ASTRNT has access to your microphone.</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </section>
            <section className="cant">
              Can’t find the answer you’re looking for? Please email us at <a href="mailto:help@astrnt.co">help@astrnt.co</a>.
            </section>
            <FooterNew />
            {
              videoParams ? <ModalVideoFaq onRef={ref => (this.ModalWelcomeVideo = ref)}/> :null
            }
          </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
		langId: ownProps.match.params.langId
	}
}


export default connect(mapStateToProps, null)(FrequentlyAskedQuestionNew)