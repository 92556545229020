import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import $ from 'jquery'

import feature1 from '../../images/feature1.png'
import feature2a from '../../images/feature2videointerviewing.png'
import feature2b from '../../images/feature2structure.png'
import feature2c from '../../images/feature2assessment.png'
import feature2d from '../../images/feature2showcase.png'
import feature2e from '../../images/feature2evaluation.png'
import feature2f from '../../images/feature2screening.png'
import feature2g from '../../images/feature2analytics.png'
import feature2h from '../../images/feature2permissions.png'
import feature2i from '../../images/feature2proctoring.png'
import feature2j from '../../images/feature2ai.png'
import feature2k from '../../images/feature2ats.png'
import feature2l from '../../images/feature2support.png'
import feature2m from '../../images/feature2devices.png'
import feature2n from '../../images/feature2partner.png'
import feature2o from '../../images/feature2tools.png'
import about1 from '../../images/about1.png'
import FooterNew from '../partials/FooterNew'
import HeaderNewClear from '../partials/HeaderNewClear'
import SupportListNew from '../partials/SupportListNew'
import TrainingForm from './TrainingForm'
import {getOSInfo , translate } from '../../helpers/global'

class TrainingPage extends React.Component<Props>
{
  constructor(props)
  {
    super(props)
  }

  componentDidMount()
  {
    window.scrollTo(0,0)
    $('ul.price-faq li a.dropdown-new').click(function(event){
      event.preventDefault();
      
      if(!$(this).hasClass('is-on')){
          $(this).addClass('is-on').parent().find('div').slideDown('fast');
      }else{
          $(this).removeClass('is-on').parent().find('div').slideUp('fast');
      }
  });
  }

  render()
  {
    const{globalGetLanguages} = this.props
    
    const renderComponent = 
    (
      <div id='homepage'>
        <HeaderNewClear isOnStatusLogo="is-on" />
        <div className="banner">
        </div>
        <div className="sec-blue">
			
			  <section className="demonstration-margin">
          <div className='demo-title-container'>
            <p className="big-letter">{translate(globalGetLanguages,'TRAINING_CARD_TITLE')}</p>
            <p>{translate(globalGetLanguages,'TRAINING_CARD_TEXT')}</p>
          </div>
            <div id='register' className='partnership-container'>
              <div className="center box-reg partnership-form demo">
                <TrainingForm history={this.props.history} />
              </div>
            </div>
        </section>
      </div>
      
        <FooterNew />
      </div>
    )
    return renderComponent
  }
}


const mapStateToProps = (state) => {
  return{ 
    globalGetLanguages:state.globalGetLanguages,
  }
  
}



export default connect(mapStateToProps, null)(TrainingPage)