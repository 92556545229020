import axios from 'axios'
import initializers from './initializers'
import { setHttpHeaders } from '../helpers/global'

const headers = setHttpHeaders()

export default axios
  .create({
    baseURL: initializers.api_host,
    timeout: 20000,
    headers
  })
