import React from 'react'
import logoKornFerry from '../../images/astronaut/korn-ferry-logo.png'
import logoPhilipMorris from '../../images/astronaut/Philip-Morris-PMI-Logo.png'
import logoAiesec from '../../images/astronaut/aiesec.png'
import logoKompas from '../../images/astronaut/kompasgramedia.png'
import logoDanaDidik from '../../images/astronaut/dana-didik1.png'
import logoBca from '../../images/astronaut/bca2.png'
import logoSinarmas from '../../images/astronaut/sinarmas.png'
import logoGojek from '../../images/astronaut/gojek-logo.png'
import logoHomeCredit from '../../images/astronaut/home-credit-logo.jpg'
import logoRB from '../../images/astronaut/RB-logo.png'
import logoPegadaian from '../../images/astronaut/pegadaian-logo.png'

class PartnerListMobile extends React.Component {
  render() {
    return (
			<div className="logobar p-t-24 p-b-24 p-l-30 p-r-0 desktop">
				<div className="container">
					<div className="horizontal-item--around">
						<div className="logobar-1">
							<img src={logoKornFerry} alt="Korn Ferry Logo" />
						</div>
						<div className="logobar-1">
							<img src={logoPhilipMorris} alt="Philip Morris Logo" />
						</div>
						<div className="logobar-1">
							<img src={logoGojek} alt="Gojek Logo" style={{height: "40px"}} />
						</div>
						<div className="logobar-1">
							<img src={logoHomeCredit} alt="Home Credit Logo" />
						</div>
						<div className="logobar-1">
							<img src={logoPegadaian} alt="Pegadaian Logo" />
						</div>
						<div className="logobar-1">
							<img src={logoRB} alt="RB Logo" />
						</div>
					</div>
				</div>
				<div className="container">
					<div className="horizontal-item--around">
						<div className="logobar-1">
							<img src={logoAiesec} alt="AIESEC Logo" />
						</div>
						<div className="logobar-2">
							<img className="logobar-bca-mobile" src={logoBca} alt="BCA Logo" />
						</div>
						<div className="logobar-2">
							<img src={logoSinarmas} alt="Sinarmas Logo" style={{width: "150px"}} />
						</div>
						<div className="logobar-2">
							<img src={logoDanaDidik} alt="Dana Didik Logo" />
						</div>
						<div className="logobar-3">
							<img src={logoKompas} alt="Kompas Gramedia Logo" style={{height: "30px"}} />
						</div>
					</div>
				</div>
			</div>
		)
  }
}

export default PartnerListMobile
