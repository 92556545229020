// @flow

import React, { Fragment } from 'react'
import qs from 'query-string';
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import { Map,fromJS } from 'immutable'

import {
	Field,
	reduxForm,
    change,
    formValueSelector,
} from 'redux-form'

import RenderInputV2 from './users/RenderInputV2'
import RenderTextareaV2 from './users/RenderTextareaV2'
import RenderDatePicker from './users/RenderDatePicker'
import RenderTimePicker from './users/RenderTimePicker'
import RenderSelectV2 from './users/RenderSelectV2'
import RenderCheckboxTraining from './users/RenderCheckboxTraining'
import validateTrainingForm from './validateTrainingForm'

import $ from 'jquery'
import {Prompt} from 'react-router-dom'
import {trainingSendRequest} from '../../actions/partnership'
import {getTimeZones} from '../../actions/global'
import SweetAlert from 'sweetalert-react'
import { renderToStaticMarkup } from 'react-dom/server';
import {TextRender} from './TextRender';
import {getOSInfo , translate } from '../../helpers/global'
// import { usersValidateEmailOnUserRegisterWizardFormRequest, usersSaveDataRequest, userVerify } from '../../../actions/users'

type Props = {
	pristine: boolean,
	submitting: boolean,
	handleSubmit: Function,
	asyncValidating: boolean,
	usersValidateEmailOnUserRegisterWizardFormRequest: Function,
	usersSaveDataRequest: Function,
	userVerify: Function,
};

const normalizeEmail = (value, previousValue) => {
	return value.trim()
}


class TrainingForm extends React.Component<Props> {
	constructor(props) {
    super(props)
    
    this.state = {
      isBlocking: false,
      hasUpper:false,
      hasLower:false,
      hasNumber:false,
      hasEightChar:false,
      passwordShown:false,
      isFocusNewPass:false
    }

		bindAll(this, [ 
      'handleSaveData',
      'renderError',
		])
	}


  componentDidMount(){
    const{ getTimeZones }=this.props

    getTimeZones();
  }

  renderError({ meta: { touched, error } }) {
    return touched && error ? <div><span className="error">{error}</span></div> : false
    }

  onUnload(e) {
    e.preventDefault()
    e.returnValue = ''
  }

  componentDidUpdate() {
    const { isBlocking } = this.state
    if(isBlocking) {
      window.addEventListener('beforeunload', this.onUnload)
    } else {
      window.removeEventListener('beforeunload', this.onUnload)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  handleSaveData(values) {
    const{
			history,
      location,
      form_values,
		}=this.props

    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('submit', 'success');
    
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
    console.log('formvalue',form_values);
		this.props.trainingSendRequest(form_values)
  }

  render() {
  	const {
  		pristine,
  		submitting,
  		handleSubmit,
  		asyncValidating,
        globalSweetAlertData,
        globalGetTimezones,
        globalGetLanguages,
  	} = this.props

    const {passwordShown} = this.state

    
    const timezoneOptions = globalGetTimezones.map(timezone => ({
			value: timezone.get('id'),
			label: timezone.get('name'),
			id: `job-form_option_timezone_${timezone.get('id')}`
		}))
    
		timezoneOptions.unshift({value: '', label: translate(globalGetLanguages,'TRAINING_FORM_PLACEHOLDER_7'), disabled: true})
    let topics = ['Data-Centric / Data-Driven Recruiting','Diversity & Inclusion Recruitment: Eliminating Bias','Future of Recruiting: Screening-In Candidate','Using Technology: Preventing Burnout for Recruiters']
    return(
    	<Fragment>
            
            <SweetAlert 
            {...globalSweetAlertData.toJS()} />
        {/* <Prompt
          when={this.state.isBlocking}
          message={location => `Data is not saved! Are you sure you want to leave?`}
        /> */}
        
        <ul className="the-form">
          <li>
            <Field
            component={RenderInputV2}
            name="company"
            formClass={'partnership-form'}
            placeholder="e.g: Livit International"
            label={translate(globalGetLanguages,'TRAINING_FORM_LABEL_1')} />
          </li>
        
          <li>
            <Field
            component={RenderInputV2}
            name="email"
            props={{
              isValidatingEmail: asyncValidating
            }}
            label={translate(globalGetLanguages,'TRAINING_FORM_LABEL_2')}
            formClass={'partnership-form'}
            placeholder="e.g: adriane@mail.com"
            defaultValue=""
            normalize={normalizeEmail} />
          </li>
          <li>
            <Field
            component={RenderInputV2}
            name="name"
            formClass={'partnership-form'}
            placeholder="e.g: John Dean"
            label={translate(globalGetLanguages,'TRAINING_FORM_LABEL_3')} />
          </li>
          <li>
            <Field
            component={RenderInputV2}
            name="phone"
            type="number"
            formClass={'partnership-form'}
            label={translate(globalGetLanguages,'TRAINING_FORM_LABEL_4')}
            placeholder="e.g: +61 232 923 322"
            className="no-spinner" />
          </li>

          <li>
            <Field
            component={RenderCheckboxTraining}
            name="topic_of_interest"
            formClass={'partnership-form'}
            options={topics}
            maxOptions={3}
            label={translate(globalGetLanguages,'TRAINING_FORM_LABEL_5')}
            className="no-spinner" />
          </li>
          
          <li id="how-do-you-hope">
            <Field
            component={RenderTextareaV2}
            name="desc"
            id="objective"
            formClass={'training-form'}
            placeholder={translate(globalGetLanguages,'TRAINING_FORM_PLACEHOLDER_6')}
            label={translate(globalGetLanguages,'TRAINING_FORM_LABEL_6')} />
          </li>
          <li>
            <Field
            component={RenderTextareaV2}
            name="delivery_method"
            formClass={'training-form'}
            placeholder={translate(globalGetLanguages,'TRAINING_FORM_PLACEHOLDER_7')}
            label={translate(globalGetLanguages,'TRAINING_FORM_LABEL_7')} />
          </li>
          <li>
            <button
              onClick={handleSubmit(this.handleSaveData)}
              disabled={submitting || pristine || asyncValidating}
              className="btn-start-trial"
              type="submit">{translate(globalGetLanguages,'REGISTER')}</button>
          </li>
        
        </ul>
    	</Fragment>
    )
  }
}



  TrainingForm = reduxForm({
  form: 'TrainingForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateTrainingForm
})(TrainingForm)


const selector = formValueSelector('TrainingForm')
const mapStateToProps = (state, ownProps) => {
const initialValues = ({
        'company':'',
        'email':'',
        'name':'',
        'phone':'',
        'desc':'',
        'date':'',
        'time':'',
        'zone':'',
        'topic_of_interest':[],
        'delivery_method':''
      });
	return {
    initialValues,
    form_values: selector(
        state,
        'company',
        'email',
        'name',
        'phone',
        'desc',
        'date',
        'time',
        'zone',
        'topic_of_interest',
        'delivery_method'
    ),
    globalSweetAlertData: state.globalSweetAlertData,
    location:ownProps.location,
    globalGetTimezones:state.globalGetTimezones,
    globalGetLanguages:state.globalGetLanguages,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
    // usersValidateEmailOnUserRegisterWizardFormRequest: (email) => dispatch(usersValidateEmailOnUserRegisterWizardFormRequest(email)),
    // usersSaveDataRequest: (formName) => dispatch(usersSaveDataRequest(formName)),
    // userVerify: () => dispatch(userVerify()),
    getTimeZones:()=>dispatch(getTimeZones()),
    trainingSendRequest:(data)=> dispatch(trainingSendRequest(data)),
    changeFieldValue: (field, value) => dispatch(change('TrainingForm', field, value, true, true)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TrainingForm)
