import configurations from '../../helpers/misc/configurations'
import { store } from "../../index";

const validate = values => {

	const state = store.getState();

	const lang = state.globalGetLanguages;

	const errors = {}
	if (lang == 'id') {
		console.log('id')
		if (!values.email) {
			errors.email = `Email harus diisi`
		} else {
			if (!configurations.emailRegex.test(values.email)) {
				errors.email = `Harap mencantumkan Email yang sesuai.`
			}
		}
	
		if (!values.name) {
			errors.name = `Nama harus diisi`
		}
	
		if (!values.company) {
			errors.company = `Institusi harus diisi`
		} else{
			if(values.company.length > 65){
				errors.company = `Company Name cannot more than 65 characters`
			}
		}
	
		if (!values.desc) {
			errors.desc = `Kolom ini harus diisi`
		}
	
		// if (!values.objective) {
		// 	errors.objective = `This field can’t be blank`
		// }
	
		if (!values.phone) {
			errors.phone = `Harap mencantumkan nomor telepon`
		} else{
			if(values.phone.length > 13){
				// errors.phone = `Mobile Number cannot more than 13 characters`
				errors.phone = `Harap mencantumkan nomor telepon.`
			}
	
			if(values.phone.length < 10){
				// errors.phone = `Mobile Number cannot less than 10 characters`
				errors.phone = `Harap mencantumkan nomor telepon.`
			}
		}

	}else{
		if (!values.email) {
			errors.email = `Company Email Address can’t be blank`
		} else {
			if (!configurations.emailRegex.test(values.email)) {
				errors.email = `Please enter a valid Email Address.`
			}
		}
	
		if (!values.name) {
			errors.name = `Name, Point of Contact can’t be blank`
		}
	
		if (!values.company) {
			errors.company = `Name of Company/Organisation can’t be blank`
		} else{
			if(values.company.length > 65){
				errors.company = `Company Name cannot more than 65 characters`
			}
		}
	
		if (!values.desc) {
			errors.desc = `This field is required`
		}
	
		// if (!values.objective) {
		// 	errors.objective = `This field can’t be blank`
		// }
	
		if (!values.phone) {
			errors.phone = `Mobile Phone, Point of Contact can’t be blank`
		} else{
			if(values.phone.length > 13){
				// errors.phone = `Mobile Number cannot more than 13 characters`
				errors.phone = `Please enter a valid mobile phone.`
			}
	
			if(values.phone.length < 10){
				// errors.phone = `Mobile Number cannot less than 10 characters`
				errors.phone = `Please enter a valid mobile phone.`
			}
		}

		if (!values.topic_of_interest) {
			errors.topic_of_interest = `Please choose at least 1 option`
		}else{
			if(values.topic_of_interest.length<1){
				errors.topic_of_interest = `Please choose at least 1 option`
			}
		}

		if (!values.delivery_method) {
			errors.delivery_method = `This field is required`
		}
	}
	
	return errors
}

export default validate
