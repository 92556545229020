// @flow

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Modal from 'react-modal';
import bindAll from "lodash/bindAll";
import { Map } from "immutable";
import videoFaq from "../../images/astronaut/faq-astrnt-small.mp4"
import UAParser from "ua-parser-js";
import { t } from "../../helpers/global";
import $ from "jquery"
import Button from "../../lib/elements/Button";

type Props = {
  text: string,
  title: string,
  style: ?Object,
  children?: any,
  isOpen: boolean,
  sessionCode: string,
  translations: Map<string, any>,
  sessionsStateInformation: Map<string, any>,
  sessionsUpdateIsOnBoardingSuccess: Function,
  sessionsFilterData: Map<string, any>,
  isVideoFinished: boolean
};

class ModalVideoFaq extends React.Component<Props> {
  constructor(props: Object) {
    super(props);

    this.state = {
      isModalOpen: true,
      isCloseButtonVisible: false,
      isHidePlay: true
    };

    bindAll(this, [
      "handleToggleModal",
      "handleCloseButtonVisibility",
      "getCookie",
      "handleRemoveModalOpenClass",
      "handleOpenModal",
    ]);
  }

  static defaultProps = {
    title: "",
    text: "",
    style: null,
    children: null,
    isOpen: true,
    modal: false
  };

  componentDidMount() {
    this.props.onRef(this);
    
    var ca = document.cookie.split(";");
    var isWatched = this.getCookie("isWatched");
   
    if (isWatched) {
      this.setState({ isModalOpen: false });
    }else{

        setTimeout(() => {
          document
          .getElementById("welcomeVideo")
          .addEventListener("ended", this.handleCloseButtonVisibility, false);
        }, 1000);

        setTimeout(() => {
            var vids = document.getElementById("welcomeVideo");
          
            const parser = new UAParser();
            const browserName = parser.getBrowser().name;
            vids.controls = false;
            var playButton = document.getElementById("play_button");
          
            // Event listener for the play/pause button
            playButton.addEventListener("click", function() {
              if (vids.paused == true) {
                // Play the video
                vids.play();
                // Update the button text to 'Pause'
                playButton.innerHTML = "<i class='fa fa-pause'></i>";
              } else {
                // Pause the video
                vids.pause();
                // Update the button text to 'Play'
                playButton.innerHTML = "<i class='fa fa-play'></i>";
              }
            });
          
        }, 1000);
    }
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  handleToggleModal(e) {
    e.preventDefault();

    this.handleRemoveModalOpenClass();
  }

  handleCloseButtonVisibility() {
    // document.cookie = "isWatched=true;";
    this.setState({ isCloseButtonVisible: true });
  }
  handleRemoveModalOpenClass() {
    document.getElementsByTagName("Body")[0].classList.remove("modal-open");
    this.setState({ isModalOpen: false });
  }
  handleOpenModal(buttonClose) {
    this.setState({ isModalOpen: true });

    const welcome_video = document.getElementsByClassName(
      "welcome-video-modal"
    );
  
    if (buttonClose == false) {
      this.setState({ isCloseButtonVisible: true });
    } else {
      this.setState({ isCloseButtonVisible: false });
    }

    setTimeout(() => {
      var vids = document.getElementById("welcomeVideo");
      const parser = new UAParser();
      const browserName = parser.getBrowser().name;
      vids.controls = false;
      var playButton = document.getElementById("play_button");
      // Event listener for the play/pause button
      playButton.addEventListener("click", function() {
        if (vids.paused == true) {
          // Play the video
          vids.play();
          // Update the button text to 'Pause'
          playButton.innerHTML = "<i class='fa fa-pause'></i>";
        } else {
          // Pause the video
          vids.pause();
          // Update the button text to 'Play'
          playButton.innerHTML = "<i class='fa fa-play'></i>";
        }
      });
    }, 1000);
  }

  
  render() {
    const {
      style,
     
    } = this.props;
    return (
      <Modal isOpen={this.state.isModalOpen} ariaHideApp={false} className="welcome-video-modal Modal" overlayClassName="Overlay">
        {/* <ModalBody className="content"> */}
          <div>
            <video
              autoPlay
              id="welcomeVideo"
              className="video-faq-body"
              playsinline
              src={videoFaq}
            />
            <button
            type="button"
            className={
              this.state.isHidePlay ? "play-button-video-faq" : "play-button-video-faq hide"
            }
            id="play_button"
          >
            <i className="fa fa-play" />
          </button>
          </div>
        <div className={this.state.isCloseButtonVisible ? "" : "invisible"} style={{textAlign: "center"}}>
          <Button
            type="button"
            theme="secondary"
            size="small"
            className="close-button"
            onClick={this.handleRemoveModalOpenClass}
          >
            <b>Close</b>
          </Button>
        </div>
      </Modal>
    );
  }
}


export default withRouter(
  connect(
    null,
    null
  )(ModalVideoFaq)
);
