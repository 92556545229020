import { Map } from 'immutable'

export function featureListCompany(state = Map({}), action) {
	switch(action.type) {
		case 'FEATURE_LIST_COMPANY_FETCH_DATA_SUCCESS':
			return Map(action.featureListCompany)
		default:
			return state
	}
}

export function featureListJob(state = Map({}), action) {
	switch(action.type) {
		case 'FEATURE_LIST_JOB_FETCH_DATA_SUCCESS':
			return Map(action.featureListJob)
		default:
			return state
	}
}
