// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import bindAll from 'lodash/bindAll'
import RenderSelect from './RenderSelect'
import RenderTextarea from './RenderTextarea'
import validateForUserRegisterWizardForm from './validateForUserRegisterWizardForm'
import { usersSaveDataRequest } from '../../../actions/users'

type Props = {
	pristine: boolean,
	submitting: boolean,
	handleSubmit: Function,
	asyncValidating: boolean,
	usersSaveDataRequest: Function
};

class UserRegisterWizardFormSecondPageNew extends React.Component<Props> {
	constructor(props) {
		super(props)

		bindAll(this, [
			'handleSaveData'
		])
	}

	handleSaveData(e) {
		this.props.usersSaveDataRequest('UserRegisterWizardForm')
	}

	render() {
		const {
			pristine,
			submitting,
			handleSubmit,
			asyncValidating
		} = this.props

		return(
			<form onSubmit={handleSubmit(this.handleSaveData)}>
				<div className="tit-reg">Tell Us Your Recruitment Needs</div>
		        <ul className="the-form">
		          <li>
		            <Field
			          	component={RenderSelect}
			          	name="total_roles_description"
			          	id="roles"
			          	label="How many roles are you recruiting each year?" />
		          </li>
		          <li>
		            <Field
			          	component={RenderTextarea}
			          	name="assist_description"
			          	rows="6"
			          	cols="80"
			          	id="desc"
			          	label="How do you hope ASTRNT can assist?" />
		          </li>
		          <li>
		            <button
            			disabled={submitting || pristine || asyncValidating}
            			className="btn-start-trial"
						type="submit">Start Free Trial</button>
		          </li>
		          <li>
		          	<div className="center">
						By clicking "Start Free Trial", you agree to ASTRNT's <a href={'/termsofservice'} target="_blank" rel="noopener noreferrer" className="underline">Terms of Service</a> and <a href={'/privacypolicy'} target="_blank" rel="noopener noreferrer" className="underline">Privacy Policy</a>.
					</div>
		          </li>
		        </ul>
			</form>
		)
	}
}

UserRegisterWizardFormSecondPageNew = reduxForm({
  form: 'UserRegisterWizardForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: validateForUserRegisterWizardForm
})(UserRegisterWizardFormSecondPageNew)

const mapDispatchToProps = (dispatch) => {
	return {
		usersSaveDataRequest: (formName) => dispatch(usersSaveDataRequest(formName))
	}
}

export default connect(null, mapDispatchToProps)(UserRegisterWizardFormSecondPageNew)
