import initializers from '../dependencies/initializers'
import NProgress from 'nprogress/nprogress.js'
import Notifications from 'react-notification-system-redux'
import axios from 'axios'
export function globalHideSweetAlertSuccess() {
	return {
		type: 'GLOBAL_HIDE_SWEET_ALERT_SUCCESS'
	}
}

export function globalAddSweetAlertDataSuccess(options) {
	return {
		type: 'GLOBAL_ADD_SWEET_ALERT_DATA_SUCCESS',
		options
	}
}

export function globalSetLanguagesSuccess(lang) {
	return {
		type: 'GLOBAL_SET_LANGUAGES_SUCCESS',
		lang
	}
}

export function globalHideSweetAlert() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(globalHideSweetAlertSuccess()))
		})
	}
}

export function globalAddSweetAlertData(options) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(globalAddSweetAlertDataSuccess(options)))
		})
	}
}

export function getTimeZones() {
	return (dispatch) => {
		
        
       
		return axios({
			url: initializers.api_host+`/api/internal/timezones`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
		})
		.then((response) => {
		
			console.log(response.data)
			dispatch({
				type:'TIMEZONES_FETCH_DATA_SUCCESS',
				timezones: response.data.timezones
			})
			
		})
		.catch((error) => {

			throw error
		})
	}
}
