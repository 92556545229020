import { List, Map, fromJS } from 'immutable'

const initUiData = Map({
	usage_activity_period: '',
	is_blocking_transition: false
})

const initTransactionsFilterData = Map({
	page: ''
})

export function companiesHasErrored(state = false, action) {
	switch (action.type) {
		case 'COMPANIES_HAS_ERRORED':
			return action.hasErrored
		default:
			return state
	}
}

export function companiesIsLoading(state = false, action) {
	switch (action.type) {
		case 'COMPANIES_IS_LOADING':
			return action.isLoading
		default:
			return state
	}
}

export function companies(state = List([]), action) {
	switch(action.type) {
		case 'COMPANIES_FETCH_DATA_SUCCESS':
			return fromJS(action.companies)
		default:
			return state
	}
}

export function company(state = Map({}), action) {
	switch(action.type) {
		case 'COMPANIES_GET_DETAIL_SUCCESS':
			return Map(action.company)
		default:
			return state
	}
}

export function companiesUpdateDataError(state = {}, action) {
	switch(action.type) {
		case 'COMPANIES_UPDATE_DATA_ERROR':
			return action.errors
		default:
			return state
	}
}

export function companiesUiData(state = initUiData, action) {
	switch(action.type) {
		case 'COMPANIES_ADD_UI_IS_BLOCKING_TRANSITION_SUCCESS':
			return state.set('is_blocking_transition', action.is_blocking_transition)
		case 'COMPANIES_ADD_UI_USAGE_ACTIVITY_PERIOD_SUCCESS':
			return state.set('usage_activity_period', action.usage_activity_period)
		default:
			return state
	}
}

export function companiesUsageActivity(state = List([]), action) {
	switch(action.type) {
		case 'COMPANIES_FETCH_USAGE_ACTIVITY_DATA_SUCCESS':
			return fromJS(action.usageActivity)
		default:
			return state
	}
}

export function companiesTransaction(state = Map({}), action) {
	switch(action.type) {
		case 'COMPANIES_GET_TRANSACTION_DETAIL_SUCCESS':
			return fromJS(action.transaction)
		default:
			return state
	}
}

export function companiesTransactions(state = List([]), action) {
	switch(action.type) {
		case 'COMPANIES_FETCH_TRANSACTIONS_DATA_SUCCESS':
			return fromJS(action.transactions)
		default:
			return state
	}
}

export function companiesTransactionsFilterData(state = initTransactionsFilterData, action) {
	switch(action.type) {
		case 'COMPANIES_TRANSACTIONS_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		case 'COMPANIES_TRANSACTIONS_ADD_FILTER_ALL_SUCCESS':
			return fromJS(action.all)
		default:
			return state
	}
}

export function companiesTransactionsPagination(state = Map({}), action) {
	switch(action.type) {
		case 'COMPANIES_TRANSACTIONS_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}
