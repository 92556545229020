import React from 'react'
import $ from 'jquery'

class RenderCheckboxTraining extends React.Component {
	renderCheckBox(){
		let {label, required, options, maxOptions, input, meta, click} = this.props;
		return options ? options.map((option, index) => {
			return(
					<div key={index}>
						<label className="inline normal checkbox-container">
							<input
								name={input.name}
								value={option}
								type="checkbox"
								checked={input.value.indexOf(option) !== -1}
								onChange={(event) => {
										const newValue = [...input.value] || [];
										if (event.target.checked) {
											if (input.value.length >= maxOptions) {
												var minus = (input.value.length+1) - maxOptions
												newValue.splice(0, minus);
												newValue.push(option);
											}else{
												newValue.push(option);
											}

										} else {
											if (input.value.length > maxOptions) {
												var minus = (input.value.length+1) - maxOptions
												newValue.splice(0, minus);
											} else {
												newValue.splice(newValue.indexOf(option), 1);
											}
										}
										if(click) click(event)
										return input.onChange(newValue);
								}}/>{option}<span className="checkmark"></span>
						</label>
					</div>
			)
		}) : ''
	}
	render() {
		const { input, label, formClass, placeholder, type, options, meta: { touched, error } } = this.props

		return(
			<div className={`form-group ${formClass} ${touched && error && 'has-error'}`}>
				<label>{label}</label>
				{this.renderCheckBox()}
				{touched && error && <div className="help-block"><div dangerouslySetInnerHTML={{__html: error}}></div></div>}
			</div>
		)
	}
}


export default RenderCheckboxTraining;