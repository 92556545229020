import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import history from "../dependencies/history";
import appReducer from "../reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import thunk from 'redux-thunk'

let composeEnhancers = compose;

const rootReducer = (state, action) => {
  if (action.type === "GLOBAL_RESET_STATE_SUCCESS") {
    state = undefined;
  }

  return appReducer(state, action);
};


export default function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const _routerMiddleware = routerMiddleware(history);

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(thunk,sagaMiddleware, _routerMiddleware)
      // applyMiddleware(_routerMiddleware)
    )
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
