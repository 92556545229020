import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import $ from 'jquery'

import feature1 from '../../images/feature1.png'
import feature2a from '../../images/feature2videointerviewing.png'
import feature2b from '../../images/feature2structure.png'
import feature2c from '../../images/feature2assessment.png'
import feature2d from '../../images/feature2showcase.png'
import feature2e from '../../images/feature2evaluation.png'
import feature2f from '../../images/feature2screening.png'
import feature2g from '../../images/feature2analytics.png'
import feature2h from '../../images/feature2permissions.png'
import feature2i from '../../images/feature2proctoring.png'
import feature2j from '../../images/feature2ai.png'
import feature2k from '../../images/feature2ats.png'
import feature2l from '../../images/feature2support.png'
import feature2m from '../../images/feature2devices.png'
import feature2n from '../../images/feature2partner.png'
import feature2o from '../../images/feature2tools.png'
import about1 from '../../images/about1.png'
import FooterNew from '../partials/FooterNew'
import HeaderNewClear from '../partials/HeaderNewClear'
import SupportListNew from '../partials/SupportListNew'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import { translate } from '../../helpers/global'

class Feature extends React.Component<Props>
{
  constructor(props)
  {
    super(props)
  }

  componentDidMount()
  {
    window.scrollTo(0,0)
    $('ul.price-faq li a.dropdown-new').click(function(event){
      event.preventDefault();
      
      if(!$(this).hasClass('is-on')){
          $(this).addClass('is-on').parent().find('div').slideDown('fast');
      }else{
          $(this).removeClass('is-on').parent().find('div').slideUp('fast');
      }
  });
  }

  render()
  {
    const { globalGetLanguages } = this.props

    const content1 = 
    (
      <div className='banner'>
        <section className="section-top-features">
          <label>
            {translate(globalGetLanguages, 'FEATURE_TITLE')}
          </label>
          <img src={feature1} alt="" />
        </section>
      </div>
    )

    const content2 = 
    (
      <div className="sec-white januar-li">
        <section className="section-all-features">
          <ul>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_1_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_1_TEXT')}
                  </p>
                </div>
                <img src={feature2a} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_2_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_2_TEXT')}
                  </p>
                </div>
                <img src={feature2b} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_3_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_3_TEXT')}
                  </p>
                </div>
                <img src={feature2c} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_4_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_4_TEXT')}
                  </p>
                </div>
                <img src={feature2d} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_5_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_5_TEXT')}
                  </p>
                </div>
                <img src={feature2e} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_6_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_6_TEXT')}
                  </p>
                </div>
                <img src={feature2f} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_7_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_7_TEXT')}
                  </p>
                </div>
                <img src={feature2g} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_8_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_8_TEXT')}
                  </p>
                </div>
                <img src={feature2h} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_9_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_9_TEXT')}
                  </p>
                </div>
                <img src={feature2i} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_10_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_10_TEXT')}
                  </p>
                </div>
                <img src={feature2j} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_11_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_11_TEXT')}
                  </p>
                </div>
                <img src={feature2k} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_12_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_12_TEXT')}
                  </p>
                </div>
                <img src={feature2l} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_13_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_13_TEXT')}
                  </p>
                </div>
                <img src={feature2m} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_14_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_14_TEXT')}
                  </p>
                </div>
                <img src={feature2n} />
              </div>
            </li>
            <li>
              <div className="on-top">
                <div className='text'>
                  <label>{translate(globalGetLanguages, 'FEATURE_15_TITLE')}</label>
                  <p>
                    {translate(globalGetLanguages, 'FEATURE_15_TEXT')}
                  </p>
                </div>
                <img src={feature2o} />
              </div>
            </li>
          </ul>
        </section>
      </div>
    )

    const content3 = 
    (
      <BannerDemoNew/>
    )

    const renderComponent = 
    (
      <div id='homepage'>
        <HeaderNewClear isOnStatusLogo="is-on" />
        <section className="desktop-hide"></section>
        { content1 }
        { content2 }
        { content3 }
        <SupportListNew />
        <FooterNew />
      </div>
    )
    return renderComponent
  }
}

const mapStateToProps = (state) => {
  return{ 
    globalGetLanguages:state.globalGetLanguages,
  }
}
export default connect(mapStateToProps, null)(Feature)