// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'

import {
	Field,
	reduxForm,
    change,
    formValueSelector,
} from 'redux-form'

import RenderInputV2 from './users/RenderInputV2'
import RenderTextareaV2 from './users/RenderTextareaV2'

import validatePartnershipForm from './validatePartnershipForm'

import $ from 'jquery'
import {Prompt} from 'react-router-dom'
import {partnershipSendRequest} from '../../actions/partnership'
import {getOSInfo , translate } from '../../helpers/global'
import SweetAlert from 'sweetalert-react'
import { renderToStaticMarkup } from 'react-dom/server';
import {TextRender} from './TextRender';
import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'
// import { usersValidateEmailOnUserRegisterWizardFormRequest, usersSaveDataRequest, userVerify } from '../../../actions/users'

type Props = {
	pristine: boolean,
	submitting: boolean,
	handleSubmit: Function,
	asyncValidating: boolean,
	usersValidateEmailOnUserRegisterWizardFormRequest: Function,
	usersSaveDataRequest: Function,
	userVerify: Function,
};

const normalizeEmail = (value, previousValue) => {
	return value.trim()
}

class PartnershipForm extends React.Component<Props> {
	constructor(props) {
    super(props)
    
    this.state = {
      isBlocking: false,
      hasUpper:false,
      hasLower:false,
      hasNumber:false,
      hasEightChar:false,
      passwordShown:false,
      isFocusNewPass:false
    }

		bindAll(this, [ 
      'handleSaveData',
      'renderError',
		])
	}

  renderError({ meta: { touched, error } }) {
    return touched && error ? <div><span className="error">{error}</span></div> : false
    }

  onUnload(e) {
    e.preventDefault()
    e.returnValue = ''
  }

  componentDidUpdate() {
    const { isBlocking } = this.state
    if(isBlocking) {
      window.addEventListener('beforeunload', this.onUnload)
    } else {
      window.removeEventListener('beforeunload', this.onUnload)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload)
  }

  handleSaveData(values) {
    
		this.props.partnershipSendRequest(values)
  }

  render() {
  	const {
  		pristine,
  		submitting,
  		handleSubmit,
  		asyncValidating,
        globalSweetAlertData,
        globalGetLanguages,
  	} = this.props

    const {passwordShown} = this.state
    console.log(globalSweetAlertData.toJS())
    return(
    	<Fragment>
            
            <SweetAlert 
            {...globalSweetAlertData.toJS()} />
        {/* <Prompt
          when={this.state.isBlocking}
          message={location => `Data is not saved! Are you sure you want to leave?`}
        /> */}
        
        <ul className="the-form">
          <li>
            <Field
            component={RenderInputV2}
            name="company"
            formClass={'partnership-form'}
            placeholder={translate(globalGetLanguages,'PARTNERS_FORM_PLACEHOLDER_1')}
            label={translate(globalGetLanguages,'PARTNERS_FORM_LABEL_1')} />
          </li>
        
          <li>
            <Field
            component={RenderInputV2}
            name="email"
            props={{
              isValidatingEmail: asyncValidating
            }}
            label={translate(globalGetLanguages,'PARTNERS_FORM_LABEL_2')}
            formClass={'partnership-form'}
            placeholder={translate(globalGetLanguages,'PARTNERS_FORM_PLACEHOLDER_2')}
            defaultValue=""
            normalize={normalizeEmail} />
          </li>
          <li>
            <Field
            component={RenderInputV2}
            name="name"
            formClass={'partnership-form'}
            placeholder={translate(globalGetLanguages,'PARTNERS_FORM_PLACEHOLDER_3')}
            label={translate(globalGetLanguages,'PARTNERS_FORM_LABEL_3')} />
          </li>
          <li>
            <Field
            component={RenderInputV2}
            name="phone"
            type="number"
            formClass={'partnership-form'}
            label={translate(globalGetLanguages,'PARTNERS_FORM_LABEL_4')}
            placeholder={translate(globalGetLanguages,'PARTNERS_FORM_PLACEHOLDER_4')}
            className="no-spinner" />
          </li>
          
          <li id="how-do-you-hope">
            <Field
            component={RenderTextareaV2}
            formClass={'partnership-form'}
            name="desc"
            id="objective"
            placeholder=""
            label={translate(globalGetLanguages,'PARTNERS_FORM_LABEL_5')} />
          </li>
          <li>
            <button
              onClick={handleSubmit(this.handleSaveData)}
              disabled={submitting || pristine || asyncValidating}
              className="btn-start-trial"
              type="submit">{translate(globalGetLanguages,'PARTNERS_FORM_SUBMIT_BUTTON')}</button>
          </li>
        
        </ul>
    	</Fragment>
    )
  }
}



  PartnershipForm = reduxForm({
  form: 'PartnershipForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate: validatePartnershipForm
})(PartnershipForm)


const selector = formValueSelector('ModalAddTemplate')
const mapStateToProps = (state, ownProps) => {
  const user = state.user
  const initialValues = ({
        'company': '',
        'email': '',
        'name': '',
        'phone': '',
        // 'company': user.get('company'),
        // 'email': user.get('email'),
        // 'name': user.get('name'),
        // 'phone': user.get('phone'),
        'desc':''
      });
	return {
    initialValues,
    form_values: selector(
        state,
        'company',
        'email',
        'name',
        'phone',
        'desc'
    ),
    globalSweetAlertData: state.globalSweetAlertData,
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
    // usersValidateEmailOnUserRegisterWizardFormRequest: (email) => dispatch(usersValidateEmailOnUserRegisterWizardFormRequest(email)),
    // usersSaveDataRequest: (formName) => dispatch(usersSaveDataRequest(formName)),
    // userVerify: () => dispatch(userVerify()),
    partnershipSendRequest:(data)=> dispatch(partnershipSendRequest(data)),
    changeFieldValue: (field, value) => dispatch(change('PartnershipForm', field, value, true, true)),
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnershipForm)
