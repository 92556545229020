import React from 'react'
import imgMobileFeature from '../../images/public/mobile-feature.svg'
import imgCollaborateFeature from '../../images/public/collaborate-feature.svg'
import imgAssessFeature from '../../images/public/assess-feature.svg'
import Header from '../partials/Header.jsx'
import Footer from '../partials/Footer.jsx'
import PartnerList from '../partials/PartnerList.jsx'
import PartnerListMobile from '../partials/PartnerListMobile.jsx'
import SupportList from '../partials/SupportList.jsx'
import BlogList from '../partials/BlogList.jsx'
import CallToActRegister from '../partials/CallToActRegister.jsx'

class HomePageVideo extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

  render() {
    return (
			<main>
	      <section className="homepage-feature">
					<Header isNavbarFixed={true} />
	        <div className="homepage-feature--image homepage">
	          <div className="container">
	            <div className="jumbotron">
	              <div className="row">
									<div className="col-sm-12 largedesktop-none tablet-show">
										<h1 className="bold">Uncover talent, engage & make confident offers fast.</h1>
									</div>
									<div className="col-md-5 col-sm-6 col-xs-12 col-md-push-7 col-sm-push-6">
										<iframe
											width="100%"
											height="270"
											src="https://www.youtube.com/embed/n8LyIipnvaM"
											frameborder="0"
											allow="autoplay; encrypted-media"
											allowfullscreen
											title="How to Use ASTRNT Interview App">
										</iframe>
									</div>
	                <div className="col-md-7 col-sm-6 col-xs-12 col-md-pull-5 col-sm-pull-6">
	                  <div className="flex vertical-item--between">
	                    <div className="flex-1">
	                      <h1 className="bold tablet-none mobile-show">Uncover talent, engage & make confident offers fast.</h1>
	                      <p>Give your candidates the most convenient way to answer your assessment questions & tests in a range of formats, including mobile video. They’ll reward you fast & love your brand.</p>
	                    </div>
	                    <div>
	                      <button className="button button__primary align-self-end m-t-16">Try for free</button>
												<p className="m-t-16">Or, <a href="mailto:help@astrnt.co" className="text-purple underline">talk to us</a></p>
	                    </div>
	                  </div>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
					<PartnerList />
	      </section>
				<PartnerListMobile />
	      <section className="m-t-24">
	        <div className="container">
	          <div className="media-showcase">
	            <div className="row">
	              <div className="col-sm-6 col-sm-push-6">
									<img className="media-object img-responsive center" src={imgMobileFeature} alt="" />
	              </div>
	              <div className="col-sm-6 col-sm-pull-6">
	                <div className="media-body text-left">
	                  <h4 className="media-heading heading1">Engage & assess your candidates with an experience that fits their lifestyle</h4>
	                  <p>If your aim is to find the best talent fast, you need a way to get fast responses from your candidates. Don’t make your candidates wait to get to a laptop.</p>
	                </div>
	              </div>
	            </div>
	          </div>
	          <div className="media-showcase">
	            <div className="row">
	              <div className="col-sm-6">
									<img className="media-object img-responsive" src={imgCollaborateFeature} alt="" />
	              </div>
	              <div className="col-sm-6">
	                <div className="media-body text-right">
	                  <h4 className="media-heading heading1">Collaborate, decide & make accurate hires, fast</h4>
	                  <p>Put the power in the hands of the key decision-makers. With alerts to request reviews and a dashboard for all candidates to be viewed, your full hiring team can access and decide anytime, anywhere.</p>
	                </div>
	              </div>
	            </div>
	          </div>
	          <div className="media-showcase">
	            <div className="row">
	              <div className="col-sm-6 col-sm-push-6">
									<img className="media-object img-responsive" src={imgAssessFeature} alt="" />
	              </div>
	              <div className="col-sm-6 col-sm-pull-6">
	                <div className="media-body text-left">
	                  <h4 className="media-heading heading1">What do you want to assess for? You choose.</h4>
	                  <p>Test for everything you need to know about the candidate. Psychometric assessment, skill tests, culture-fit tests, language skills, clarity, presence... all these are possible. You choose how you want to use it.</p>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	      </section>
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="section-divider"></div>
						</div>
					</div>
				</div>
	      <SupportList />
	      <CallToActRegister />
			  <BlogList />
	      <Footer />
    	</main>
    )
  }
}

export default HomePageVideo
