import { put, call, all, select, take ,delay} from "redux-saga/effects";
import { Map, List, fromJS } from "immutable";
import Notifications from 'react-notification-system-redux'
import NProgress from 'nprogress/nprogress.js'
import omit from 'lodash/omit'
import axios from 'axios'
import {
  companiesSwitchApi,
  publicCompaniesFetchDataApi
} from "../apis/companies";



export function* publicCompaniesFetchData({
    params
}:Object):Generator<*,*,*>{
    yield put({
        type:"PUBLIC_COMPANIES_IS_LOADING",
        isLoading:true
    })

    NProgress.start()
    try {
        
        let response: Object = {};
        response = yield call(publicCompaniesFetchDataApi,params)
        if(response.status >= 200 && response.status < 300){

            let publicCompanies = response.data.data
			let pagination = omit(response.data, 'data')
            yield put({
                type:"PUBLIC_COMPANIES_FETCH_DATA_SUCCESS",
                publicCompanies
            })
            yield put({
                type:"PUBLIC_COMPANIES_FETCH_PAGINATION_SUCCESS",
                pagination
            })
			yield put({
                type:"PUBLIC_COMPANIES_IS_LOADING",
                isLoading:false
            })
			

			NProgress.done()
            
        }else{
            throw response
        }
    } catch (error) {
        	NProgress.done()

			yield put({
                type:"PUBLIC_COMPANIES_IS_LOADING",
                isLoading:false
            })
			

			if (axios.isCancel(error)) {
		    console.log('Request canceled', error.message);
		  } else {
				console.error(error)
                yield put({
                    type:"PUBLIC_COMPANIES_HAS_ERRORED",
                    hasErrored:true
                })
				
		  }
    }
}
	
export function* companiesSwitch({
    id
}:Object):Generator<*,*,*>{
    try {
        let response: Object = {};
        
        response = yield call(companiesSwitchApi, id)

        if(response.status >= 200 && response.status < 300){
                window.location = `/companies/${id}/dashboard`
        }else{
            throw response
        }
    } catch (error) {
        yield put({
            type: "COMPANIES_HAS_ERRORED",
            hasErrored: true
        }); 

        Notifications.error({
				  title: 'Error',
				  message: 'An error occured when switching company.',
				  position: 'bl'
        })
            
    }
   


}