import axios from 'axios'
import Promise from 'bluebird'
import { reset } from 'redux-form'
import omit from 'lodash/omit'
import { push } from "react-router-redux";
import { List, fromJS } from 'immutable'
import initializers from '../dependencies/initializers'
import NProgress from 'nprogress/nprogress.js'
import Notifications from 'react-notification-system-redux'
import {
	globalHideSweetAlert,
	globalAddSweetAlertData
} from './global'
import Swal from 'sweetalert2'

import moment from 'moment'
export function partnershipSendRequest(data) {
	return (dispatch,getState) => {
		NProgress.start()
        const globalGetLanguages = getState().globalGetLanguages
       
		return axios({
			url: initializers.api_host+`/api/web/partnership/request`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'Team member successfully updated.',
				position: 'bl'
			}))
			if (globalGetLanguages == 'id') {
				dispatch(
					globalAddSweetAlertData({
					show: true,
					type: 'success',
					title: 'Terima kasih atas ketertarikan anda untuk bekerjasama.',
					html: true,
					customClass: 'swal-public',
					text:`Kami telah menyampaikan anda pilih ke tim kami.<br/>
					Mereka akan segera menghubungi anda dalam waktu dekat.<br/>
					Untuk sementara waktu, Anda dapat mencoba sendiri platform kami secara <b>GRATIS</b>!`,
					confirmButtonText: 'Coba produk kami secara gratis',
					confirmButtonColor: '#DB334D',
					confirmButtonClass: "button button__secondary m-r-20",
					cancelButtonClass: "button",
					cancelButtonText: 'Kembali ke laman depan',
					showCancelButton: true,
					showLoaderOnConfirm: true,
					onConfirm: () => {
						globalHideSweetAlert()
						window.location = '/register'
					},
					onCancel: () => {
						globalHideSweetAlert()
						window.location = '/'
					}
					})
				)
			}else{
				dispatch(
					globalAddSweetAlertData({
					show: true,
					type: 'success',
					title: 'Thank you for your interest in collaborating!',
					html: true,
					customClass: 'swal-public',
					text:`Your message will be sent to our team.<br/>
					Upon receiving your message, they will get in touch with you shortly.<br/>
					In the meantime, you can try out and play around with our platform for <b>FREE</b>!`,
					confirmButtonText: 'Try out free trial',
					confirmButtonColor: '#DB334D',
					confirmButtonClass: "button button__secondary m-r-20",
					cancelButtonClass: "button",
					cancelButtonText: 'Go back to the homepage',
					showCancelButton: true,
					showLoaderOnConfirm: true,
					onConfirm: () => {
						globalHideSweetAlert()
						window.location = '/register'
					},
					onCancel: () => {
						globalHideSweetAlert()
						window.location = '/'
					}
					})
				)
			}
            

			dispatch(reset('PartnershipForm'))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when updating team member.',
				position: 'bl'
			}))


			throw error
		})
	}
}



export function demoSendRequest(data) {
	return (dispatch,getState) => {
		NProgress.start()
		//declare ur endpoint
		const endpoint ='/api/internal/contact-schedule' 
		let entry = data
		entry.fullname=data.name
		entry.timezone_id=data.zone
		// console.log(values.date)
		entry.date = moment(entry.date).format('YYYY-MM-DD')
		entry.time = moment(entry.time).format('HH:MM')
		const globalGetLanguages = getState().globalGetLanguages
		
		// return dispatch(
		// 	globalAddSweetAlertData({
		// 	show: true,
		// 	type: 'success',
		// 	title: 'Thank you for setting up the schedule!',
		// 	html: true,
		// 	text:`We have sent the schedule demo to our team. 
		// 	Upon receiving your message, they will get in touch with you shortly.
		// 	In the meantime, you can try out and play around with our platform for <strong>FREE</strong>!`,
		// 	confirmButtonText: 'Try out free trial',
		// 	confirmButtonColor: '#DB334D',
		// 	confirmButtonClass: "button button__secondary m-r-20",
		// 	cancelButtonClass: "button",
		// 	cancelButtonText: 'Go back to the homepage',
		// 	showCancelButton: true,
		// 	showLoaderOnConfirm: true,
		// 	onConfirm: () => {
		// 		globalHideSweetAlert()
		// 		window.location = '/register'
		// 	},
		// 	onCancel: () => {
		// 		globalHideSweetAlert()
		// 		window.location = '/'
		// 	}
		// 	})
		// ) 
		// commment this fuction to use this param u can see @initial value
		// return dispatch(push('/request-demo?submit=success'))
		return axios({
			url: initializers.api_host+`${endpoint}`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data:entry
		})
		.then((response) => {
			NProgress.done()

			// dispatch(Notifications.success({
			// 	title: 'Success',
			// 	message: 'Team member successfully updated.',
			// 	position: 'bl'
			// }))
			
			if (globalGetLanguages == 'id') {
				dispatch(
					globalAddSweetAlertData({
					show: true,
					type: 'success',
					title: 'Terima kasih telah melakukan permintaan demo!',
					html: true,
					customClass: 'swal-public',
					text:`Kami telah menyampaikan jadwal yang anda pilih ke tim demo kami.<br/>
					Mereka akan segera menghubungi anda dalam waktu dekat.<br/>
					Untuk sementara waktu, Anda dapat mencoba sendiri platform kami secara <strong>GRATIS</strong>!`,
					confirmButtonText: 'Coba produk kami secara gratis',
					confirmButtonColor: '#DB334D',
					confirmButtonClass: "button button__secondary m-r-20",
					cancelButtonClass: "button",
					cancelButtonText: 'Kembali ke laman depan',
					showCancelButton: true,
					showLoaderOnConfirm: true,
					onConfirm: () => {
						globalHideSweetAlert()
						window.location = '/register'
					},
					onCancel: () => {
						globalHideSweetAlert()
						window.location = '/'
					}
					})
				)
			}else{
				dispatch(
					globalAddSweetAlertData({
					show: true,
					type: 'success',
					title: 'Thank you for setting up the schedule!',
					html: true,
					customClass: 'swal-public',
					text:`We have sent the schedule demo to our team.<br/>
					Upon receiving your message, they will get in touch with you shortly.<br/>
					In the meantime, you can try out and play around with our platform for <strong>FREE</strong>!`,
					confirmButtonText: 'Try out free trial',
					// confirmButtonText: 'Go to Astronaut Dashboard',
					confirmButtonColor: '#DB334D',
					confirmButtonClass: "button button__secondary m-r-20",
					cancelButtonClass: "button",
					cancelButtonText: 'Go back to the homepage',
					showCancelButton: true,
					showLoaderOnConfirm: true,
					onConfirm: () => {
						globalHideSweetAlert()
						window.location = '/register'
						// window.location = initializers.api_host
					},
					onCancel: () => {
						globalHideSweetAlert()
						window.location = '/'
					}
					})
				)
			}
            
			// dispatch(push('/request-demo?submit=success'))
			// dispatch(reset('DemonstrationForm'))
		})
		.catch((error) => {
			// dispatch(reset('DemonstrationForm'))
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when updating team member.',
				position: 'bl'
			}))


			throw error
		})
	}
}

export function trainingSendRequest(data) {
	return (dispatch,getState) => {
		NProgress.start()
		const endpoint ='/api/internal/request-training' 
		let entry = data
		entry.fullname=data.name
		const globalGetLanguages = getState().globalGetLanguages
		
		return axios({
			url: initializers.api_host+`${endpoint}`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data:entry
		})
		.then((response) => {
			NProgress.done()
			
			if (globalGetLanguages == 'id') {
				dispatch(
					globalAddSweetAlertData({
					show: true,
					type: 'success',
					title: 'Terima kasih telah mendaftar',
					html: true,
					customClass: 'swal-public',
					text:`Kami akan segera menghubungi Anda.<br/>
					Sementara itu, kami menyambut Anda untuk bermain dengan platform kami dengan uji coba gratis kami.`,
					confirmButtonText: 'Kembali ke laman depan',
					confirmButtonColor: '#DB334D',
					confirmButtonClass: "button button__secondary m-r-20",
					cancelButtonClass: "button",
					showLoaderOnConfirm: true,
					onConfirm: () => {
						globalHideSweetAlert()
						window.location = '/'
					}
					})
				)
			}else{
				dispatch(
					globalAddSweetAlertData({
					show: true,
					type: 'success',
					title: 'Thank you for registering!',
					html: true,
					customClass: 'swal-public',
					text:`We will get in touch with you shortly.<br/>
					In the meantime, we welcome you to play around with our platform with our free-trial.`,
					confirmButtonText: 'Go to the ASTRNT homepage',
					confirmButtonColor: '#DB334D',
					confirmButtonClass: "button button__secondary m-r-20",
					cancelButtonClass: "button",
					showLoaderOnConfirm: true,
					onConfirm: () => {
						globalHideSweetAlert()
						window.location = '/'
					}
					})
				)
			}
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when updating team member.',
				position: 'bl'
			}))


			throw error
		})
	}
}