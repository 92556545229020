import { List} from 'immutable'

export function publicFAQ(state = List([]), action) {
	switch(action.type) {
		case 'PUBLIC_FAQ_FETCH_DATA_SUCCESS':
			return List(action.faq)
		default:
			return state
	}
}
