import React from 'react'
import $ from 'jquery'
import bindAll from 'lodash/bindAll'
import UAParser from 'ua-parser-js'
import _axios from '../../dependencies/_axios'
import initializers from '../../dependencies/initializers'
class ModalEnterCode extends React.Component {
	constructor(props) {
		super(props)

		bindAll(this, [
			'removeCharacter',
			'handleCloseClick',
			'handleCheckCodeClick'
		])
	}

	handleCheckCodeClick(e) {
		e.preventDefault()

		const enterCode = $('[name="code"]').val()

		$('.wrapper-entercode').hide()
		$('#loader').show()

		_axios({
			url: `/api/popupcode`,
			timeout: 200000,
			method: 'post',
			crossdomain:true,
			data:{code:enterCode}
		})
		.then((data) => {
			console.log(data)
				const interviewCode = data.data.interview_code
		    const interviewUrl = data.data.url
		    const interview = data.data.interview
				const userAgent = navigator.userAgent || navigator.vendor
				const parser = new UAParser()
				const isChrome = /chrome/i.test(parser.getBrowser().name)
				const isFirefox = /firefox/i.test(parser.getBrowser().name)
		    const isSafari = /safari/i.test(parser.getBrowser().name)
	
		    if (/android/i.test(userAgent)) {

		      if (isChrome || isFirefox) {

		        if (!interview) {
						  window.location = 'astronaut://astrnt.co/code/' + interviewCode

		          setTimeout(function() {
		            window.location = 'https://play.google.com/store/apps/details?id=co.astrnt.androidqa'
		          }, 5000)
						} else {
							console.log(interviewCode)
		          window.location = 'astronaut://astrnt.co/code/' + interviewCode

		          setTimeout(function() {
		            window.location = 'https://play.google.com/store/apps/details?id=co.astrnt.androidqa'
		          }, 5000)
						}

		      } else {
				
		        window.location = 'astronaut://astrnt.co/code/' + interviewCode
		      }
		    } else if (/ipad|iphone|ipod/i.test(userAgent) && !window.MSStream) {

		      if (isChrome || isSafari || isFirefox) {

		        if (!interview) {
							  window.location = 'astronaut://entercode?code=' + interviewCode

		          	setTimeout(function() {
		            window.location = 'https://itunes.apple.com/id/app/astronaut-always-be-interviewing/id1098124247?mt=8'
		          }, 5000)
		        } else {
		          window.location = 'astronaut://entercode?code=' + interviewCode

		          setTimeout(function() {
		            window.location = 'https://itunes.apple.com/id/app/astronaut-always-be-interviewing/id1098124247?mt=8'
		          }, 5000)
		        }

		      }
		    } else {
		      window.location = interviewUrl
					
		    }
				// console.log(initializers.api_host+interviewUrl)
		})
		.catch((error) => {

				$('.wrapper-entercode').show()
		    $('#loader').hide()

		    const errors = error.response.data
		    if (errors.url) {
		      $('#errEnterCode').html(errors.url)
		    } else {
		      $("#errEnterCode").html("")
		    }
		})
		
	}

	handleCloseClick(e) {
		e.preventDefault()

		if ($('[name="code"]').val()) {
		  $('[name="code"]').val('')
		  $("#errEnterCode").html("")
		}
	}

	removeCharacter(e) {
		const enterCode = e.target.value.replace(/[^a-zA-Z0-9]/g, '')

		if ($('[name="code"]').val()) {
		  $('[name="code"]').val(enterCode)
		}
	}

	componentDidMount() {
		$('#loader').hide()
	}

	render() {
		return(
			<div className="modal" id="modalEnterCode" tabIndex="-1" role="dialog" aria-hidden="true">
			  <div className="modal-dialog modal-entercode">
			    <div className="modal-content">
			      <div className="modal-header">
			        <div className="flex-container flex-end">
			          <div
			            className="close-modal"
			            onClick={this.handleCloseClick}
			            data-dismiss="modal">
			            <span aria-hidden="true"><i className="icon btn-cross-white"></i></span><span className="sr-only">Close</span>
			          </div>
			        </div>
			      </div>
			      <div className="modal-body">
			        <div id="loader">
			          <i className="fa fa-spin fa-spinner"></i>
			          <p>Redirecting...</p>
			        </div>
			        <div className="wrapper-entercode center">
			          <form>
			            <div className="form-entercode">
			              <div className="input-group">
			  							<input
			  								type="text"
			  								name="code"
			                  className="form-control"
			                  onBlur={this.removeCharacter}
			                  placeholder="Enter Code" />
			  							<span className="input-group-btn">
			  								<button
			  									type="submit"
			  									onClick={this.handleCheckCodeClick}>
			  									<i className="icon-arrow-outline"></i>
			  								</button>
			  							</span>
			  						</div>
			            </div>
			            <div className="error m-t-10" htmlFor="code" id="errEnterCode"></div>
			          </form>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
		)
	}
}

export default ModalEnterCode
