// @flow
import React,{Fragment} from 'react'
import {connect} from 'react-redux'
import PrivacyContent from '../partials/PrivacyContent'
import { Link } from 'react-router-dom'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import $ from 'jquery'
class PrivacyPolicyNew extends React.Component<Props>{
    constructor(props) {
		super(props)

		// bindAll(this, [
		// 	'handleCheckInterviewCode'
		// ])
	}

    componentDidMount() {
		window.scrollTo(0, 0)
        $('#public').attr('id','not-public')
    }

  componentWillUnmount(){
    $('#not-public').attr('id','public')
  }
    render(){
        return(
            <div id="misc">
            <HeaderNewClear />
            <div className="pad-top desktop-hide" />
            <section className="section-misc">
              <div className="misc-tit">Privacy Policy</div>
              <div className="intro">
                <p>This Privacy Policy sets out our commitment to protecting the privacy of your personal information that we collect through this website astrnt.co (Site) or directly from you. This Privacy Policy governs our data collection and usage practices, and describes your choices regarding use, access and correction of your personal information. We enable employers to create fast and convincement tests and assessments to determine the suitability of candidates for their opportunities (Services).</p>
                <p>Please read this Privacy Policy carefully, and contact us if you have any questions.</p>
                <p>You providing us with personal information through your use of the Site indicates that you have had sufficient opportunity to access this Privacy Policy and that you have read and accepted it. By using the Site you acknowledge and agree that the Site provides a platform for you to post content, including videos, photos, documents, files, comments and other materials (Posted Content) which is shared with other users in certain circumstances for the purposes of the Services. In these circumstances, other users may see any of your Posted Content, consistent with the terms and conditions of this Privacy Policy and our Website Terms of Use.</p>
                <p>If you do not wish to provide personal information to us, then you do not have to do so, however it may affect your use of this Site or any products and services offered on it.</p>
                <p>This Privacy Policy applies to any person who accesses the Site, including visitors, Jobseekers, Employers and Authors.</p>
              </div>
              <ul className="list-misc">
                <li>
                  Type of information collected
                  <p>Personal Information: The type of personal information we collect includes:</p>
                  <ul>
                    <li>personal identification information (eg; username, password, email address, location and other personal information in your profile);</li>
                    <li>your Posted Content;</li>
                    <li>communications between you and other Site users which are mediated through the Site, and communications between you and us.</li>
                  </ul>
                  <p>Cookies, log file information, device identifiers, metadata and similar technology:</p>
                  <p>When you visit the Site, we may use cookies and similar technologies like pixels, web beacons, device identifiers and local storage to collect information about how you use the Site. We may also record certain log file information (such as your Internet Protocol ("IP") address, browser type, URLs, number of clicks and how you interact with links on the Site, landing pages, pages viewed, and other such information) when you access the Site. Your browser automatically reports log file information each time you visit the Site.</p>
                  <p>If any of your Posted Content contains metadata (technical data that describes your Posted Content), we may collect and retain this.</p>
                </li>
                <li>
                  Collection and use of personal information
                  <p>We collect and use your personal information for as long as reasonably required in order to provide the Services, which may include:</p>
                  <ul>
                    <li>assessing a Jobseeker’s attributes against an Employer’s requirements;</li>
                    <li>retaining information that will make your subsequent visits to the Site smoother and your access quicker;</li>
                    <li>monitoring performance metrics of the Site, such as total number of visitors, user demographics, website traffic, user preferences, etc;</li>
                    <li>improve, test, and monitor the delivery of our Services;</li>
                    <li>developing and testing new or updated products and features.</li>
                  </ul>
                  <p>We also collect and use the personal information for purposes including to contact and communicate with you, for internal record keeping and for marketing.</p>
                </li>
                <li>
                  Disclosure of personal information
                  <p>We may disclose personal information for the purposes of us providing the Services, and as required by law. Where we disclose your personal information to third parties for these purposes, we will request that the third party follow this Privacy Policy regarding handling of your personal information.</p>
                  <p>Subject to the privacy settings selected in your account, any Posted Content that you contribute is searchable by us for the purposes of providing the Services, and subject to use in accordance with our Website Terms of Use.</p>
                  <p>Copies of your Posted Content may remain viewable in cached and archived pages of the Site, or if other users or third parties using the Site have copied or saved that information, even after you have removed any Posted Content.</p>
                  <p>It may be necessary for us to share your information (including information about you that arises from cookies, log files, device identifiers, location data and other similar tools) with third-party organizations that help us provide the Service. Such service providers will be given access to your information as is reasonably necessary to enable us to provide the Services, but will be subject to reasonable confidentiality restrictions.</p>
                  <p>We may combine your information with other information in a way that it is no longer associated with you and share that anonymised data in aggregated form, with third parties for the purposes of data analysis and marketing.</p>
                  <p>We may share information about your interactions on our website with ASTRNT partners to provide them with information relating to how ASTRNT users 'use' the site.</p>
                  <p>Users of the Site are global. Therefore the disclosure of your information as described in this Privacy Policy may occur in Singapore or in any other jurisdiction where users of the Site are located.</p>
                </li>
                <li>
                  Payment information
                  <p>We collect and process payment information from you when you subscribe to the Services, including credit cards numbers and billing information, using third party PCI-compliant service providers.</p>
                </li>
                <li>
                  Information about children
                  <p>The Site is not intended for or targeted at children under 13, and we do not knowingly or intentionally collect information about children under 13.</p>
                </li>
                <li>
                  Access to and correction of personal information
                  <p>Access: You may request details of personal information that we hold about you. An administrative fee may be payable for the provision of information. We may refuse to provide you with information that we hold about you, in certain circumstances set out in the Privacy Act.</p>
                  <p>Correction: Ensuring that your information is not inaccurate, out of date, incomplete, irrelevant or misleading is your responsibility, through management of your personal account. However, if you believe that we hold any inaccurate, out of date, incomplete, irrelevant or misleading information about you that you are not able to update yourself, please contact us using the live chat on the Site or at <a href="mailto:help@astrnt.co">help@astrnt.co</a>. We will endeavour to promptly correct any such information found to be inaccurate, incomplete or out of date.</p>
                </li>
                <li>
                  Complaints about breach
                  <p>If you believe that we have breached the privacy law and wish to make a complaint about that breach, please contact immediately us at <a href="mailto:help@astrnt.co">help@astrnt.co</a>.</p>
                </li>
                <li>
                  Unsubscribe
                  <p>We do not intend to contact you with regular electronic mail-outs, periodicals or other advertisements. However, we may contact you from time to time as necessary for the purposes of providing the services described on the Site. You may unsubscribe at any time by updating your notification preferences. There are some communications (eg; related to your account verification or security notices) that you may not be able to opt out of.</p>
                </li>
                <li>
                  Storage and Security
                  <p>The information you provide through the Site may be stored in Singapore, the United States of America, or in any other country where <strong>Astronaut Technologies Pte Ltd</strong> stores information. By registering with the Site, you consent to the transfer of information to Singapore, the United States of America or any other country where <strong>Astronaut Technologies Pte Ltd</strong> stores information. Please be aware that these locations may have different data protection laws to your own jurisdiction.</p>
                  <p>We are committed to ensuring that the information you provide is secure, and as such we use commercially reasonable endeavours to keep personal information collected through the Site secure. Such endeavours include requesting your username and password to verify your identity before you are granted access to your account. However, we cannot guarantee the security of your personal information that you choose to provide to the Site, or that your personal information on the Site will not be accessed, disclosed, altered, or destroyed. Maintaining the secrecy of your login information is your responsibility, please help us by not disclosing this information to third parties and by ensuring you are comfortable with the Privacy Settings on your account.</p>
                </li>
                <li>
                  Updates to Privacy Policy
                  <p>Please review this Privacy Policy from time to time, as we may modify or update it periodically. Your acceptance of such modifications or updates will be deemed to have occurred upon your continued use of the Site.</p>
                </li>
              </ul>
            </section>
            <FooterNew />
          </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
	return {
		
	}
}


export default connect(mapStateToProps, null)(PrivacyPolicyNew)