import React, {Fragment} from 'react'

class FaqEn extends React.Component{
render(){
    return(
        <Fragment>
            <div class="faq-page">
  <div class="jumbotron blue-bg">
    <div class="container">
      <center>
        <h2>We’re here to help, always.</h2>
        <p class="whiteFont">
          Take a look below for answers to the most commonly asked questions.
        </p>
      </center>
    </div>
  </div>
  <div class="container">
    <div class="panel-group faq-accordion" id="accordion" role="tablist" aria-multiselectable="true">
      <div class="panel panel-white">
        <div class="panel-heading" role="tab" id="headingOneCode">
          <a
            class="panel-title"
            role="button"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#faqOneCode"
            aria-expanded="true"
            aria-controls="faqOneCode">
            <h4>
              <span class="fa-stack fa-lg">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="numbers fa-stack-1x fa-inverse">1</i>
              </span> What is a code? Why do I need one?
             </h4>
          </a>
        </div>
        <div
          id="faqOneCode"
          class="panel-collapse collapse in"
          role="tabpanel"
          aria-labelledby="headingOneCode">
          <div class="panel-body">
            <p>The ASTRNT Q&amp;A code is what ASTRNT uses to make sure you get the right questions. For every code, there is a specific set of questions and scenarios, depending on what your recruiter wants to know about you.</p>
          </div>
        </div>
      </div>
      <div class="panel panel-white">
        <div class="panel-heading" role="tab" id="headingTwoCode">
          <a
            class="panel-title"
            role="button"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#faqTwoCode"
            aria-expanded="false"
            aria-controls="faqTwoCode">
            <h4>
              <span class="fa-stack fa-lg">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="numbers fa-stack-1x fa-inverse">2</i>
              </span> Where do I find my code?
             </h4>
          </a>
        </div>
        <div
          id="faqTwoCode"
          class="panel-collapse collapse"
          role="tabpanel"
          aria-labelledby="headingTwoCode">
          <div class="panel-body">
            <p>If you are invited by your recruiter to do a Q&amp;A on ASTRNT, please check your email to find your code.<br/>
            If you download the app because of an advertisement, please check the advertisement to find an open code.<br/>
            If you’d like to apply to ASTRNT, please see the last FAQ for our code.</p>
          </div>
        </div>
      </div>
      <div class="panel panel-white">
        <div class="panel-heading" role="tab" id="headingOne">
          <a
            class="panel-title"
            role="button"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#faqOne"
            aria-expanded="false"
            aria-controls="faqOne">
            <h4>
              <span class="fa-stack fa-lg">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="numbers fa-stack-1x fa-inverse">3</i>
              </span> I have a code for an ASTRNT Q&amp;A session, what should I do with it?
             </h4>
          </a>
        </div>
        <div
          id="faqOne"
          class="panel-collapse collapse"
          role="tabpanel"
          aria-labelledby="headingOne">
          <div class="panel-body">
            <p>You’re all set for your Q&amp;A session! There are two ways you can do it:</p>
            <p>
              <b>Doing the Q&amp;A session on ASTRNT mobile app</b><br/>
              Install the ASTRNT Q&amp;A mobile app (available in both <a href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247" target="_blank">iOS</a>
              and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa"
              target="_blank">Android</a>), input your code, and follow the instructions in the mobile app.
            </p><br/>
            <p>
              <b>Doing the Q&amp;A session on desktop browser</b><br/>
              Go to <a href="http://astrnt.co" target="_blank">http://astrnt.co</a>, click on "I have a code", and input your code. Then follow the instructions to complete your Q&amp;A session.
            </p><br/>
            <p>We recommend you find a quiet place and enough time to do the test without distractions. Make sure you have a stable internet connection as well. Good luck!</p>
          </div>
        </div>
      </div>
      <div class="panel panel-white">
        <div class="panel-heading" role="tab" id="headingTwo">
          <a
            class="panel-title collapsed"
            role="button" data-toggle="collapse"
            data-parent="#accordion"
            href="#faqTwo"
            aria-expanded="false"
            aria-controls="faqTwo">
            <h4>
              <span class="fa-stack fa-lg">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="numbers fa-stack-1x fa-inverse">4</i>
              </span> I have received an invitation email, what do I do next?
            </h4>
          </a>
        </div>
        <div
          id="faqTwo"
          class="panel-collapse collapse"
          role="tabpanel"
          aria-labelledby="headingTwo">
          <div class="panel-body">
            <p>You’re all set for your Q&amp;A session! You can do it using our mobile apps or from your desktop browser.</p>
            <p>
              <b>Doing the Q&amp;A session on ASTRNT mobile app</b><br/>
              Install the ASTRNT Q&amp;A mobile app (available in both <a href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247" target="_blank">iOS</a>
              and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa"
              target="_blank">Android</a>), input your code (or click the link in your email), and follow the instructions in the mobile app.
            </p><br/>
            <p>
              <b>Doing the Q&amp;A session on desktop browser</b><br/>
              Click the link in your email or go to <a href="http://astrnt.co" target="_blank">http://astrnt.co</a>, click on "I have a code", and input your code. Then follow the instructions to complete your Q&amp;A session.
            </p><br/>
            <p>
              We recommend you find a quiet place and enough time to do the test without distractions. Make sure you have a stable internet connection as well. Good luck!
            </p>
          </div>
        </div>
      </div>
      <div class="panel panel-white">
        <div class="panel-heading" role="tab" id="headingThree">
          <a
            class="panel-title collapsed"
            role="button" data-toggle="collapse"
            data-parent="#accordion"
            href="#faqThree"
            aria-expanded="false"
            aria-controls="faqThree">
            <h4>
              <span class="fa-stack fa-lg">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="numbers fa-stack-1x fa-inverse">5</i>
              </span>
              I can’t see my face on the screen, what should I do?
            </h4>
          </a>
        </div>
        <div
          id="faqThree"
          class="panel-collapse collapse"
          role="tabpanel"
          aria-labelledby="headingThree">
          <div class="panel-body">
            <p>Please check your camera permission. Here’s how:</p>
            <p>
              <b>Using Chrome</b><br/>
              Follow the instructions in <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">this link</a> to change camera access permission.
            </p><br/>
            <p>
              <b>Using Mozilla Firefox</b><br/>
              On menu bar, go to Tools > Page Info > Permissions, then scroll down to "Use the Camera". Make sure the website is allowed to use the camera.
            </p><br/>
            <p>
              <b>Using Opera</b><br/>
              By default, Opera will always ask you to allow a site to access your camera. If this isn’t the case, go to Settings > Websites, under Camera, and click Manage Exceptions button. For more information, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCamera" target="_blank">click here</a>.
            </p><br/>
            <p>
              <b>Using ASTRNT Q&amp;A app on iOS</b><br/>
              Go to Settings > Privacy > Camera, and make sure ASTRNT has access to your camera.
            </p><br/>
            <p>
              <b>Using ASTRNT Q&amp;A app on Android</b><br/>
              Go to Settings > Security & Privacy > Application Permissions and make sure ASTRNT has access to your camera.
            </p><br/>
          </div>
        </div>
      </div>
      <div class="panel panel-white">
        <div class="panel-heading" role="tab" id="headingFour">
          <a
            class="panel-title collapsed"
            role="button" data-toggle="collapse"
            data-parent="#accordion"
            href="#faqFour"
            aria-expanded="false"
            aria-controls="faqFour">
            <h4>
              <span class="fa-stack fa-lg">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="numbers fa-stack-1x fa-inverse">6</i>
              </span>
              The application isn’t recording my voice, what should I do?
            </h4>
          </a>
        </div>
        <div
          id="faqFour"
          class="panel-collapse collapse"
          role="tabpanel"
          aria-labelledby="headingFour">
          <div class="panel-body">
            <p>Please check your microphone permission. Here’s how:</p>
            <p>
              <b>Using Chrome</b><br/>
              Follow the instructions in <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">this link</a> to change microphone access permission.
            </p><br/>
            <p>
              <b>Using Mozilla Firefox</b><br/>
              On menu bar, go to Tools > Page Info > Permissions, then scroll down to "Use the Microphone". Make sure the website is allowed to use the microphone.
            </p><br/>
            <p>
              <b>Using Opera</b><br/>
              By default, Opera will always ask you to allow a site to access your microphone. If this isn’t the case, go to Settings > Websites, under Microphone, and click Manage Exceptions button. For more information, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageMic" target="_blank">click here</a>.
            </p><br/>
            <p>
              <b>Using ASTRNT Q&amp;A app on iOS</b><br/>
              Go to Settings > Privacy > Microphone, and make sure ASTRNT has access to your microphone.
            </p><br/>
            <p>
              <b>Using ASTRNT Q&amp;A app on Android</b><br/>
              Go to Settings > Security & Privacy > Application Permissions and make sure ASTRNT has access to your microphone.
            </p><br/>
          </div>
        </div>
      </div>
        <div class="panel panel-white">
          <div class="panel-heading" role="tab" id="headingFive">
            <a
              class="panel-title collapsed"
              role="button" data-toggle="collapse"
              data-parent="#accordion"
              href="#faqFive"
              aria-expanded="false"
              aria-controls="faqFive">
              <h4>
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="numbers fa-stack-1x fa-inverse">7</i>
                </span>
                I can’t install the ASTRNT Q&amp;A mobile app. Why?
              </h4>
            </a>
          </div>
          <div
            id="faqFive"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingFive">
            <div class="panel-body">
              <p>The ASTRNT Q&amp;A app is available in both <a href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247" target="_blank">iOS</a> (iOS 9 & above, including iPhone 5s) and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa"
              target="_blank">Android</a> (6.0 & above, with minimum 2GB RAM). If your phone doesn’t fulfill this requirement, we recommend doing the Q&amp;A session on your desktop browser.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-white">
          <div class="panel-heading" role="tab" id="headingSix">
            <a
              class="panel-title collapsed"
              role="button" data-toggle="collapse"
              data-parent="#accordion"
              href="#faqSix"
              aria-expanded="false"
              aria-controls="faqSix">
              <h4>
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="numbers fa-stack-1x fa-inverse">8</i>
                </span>
                The Android app keeps crashing on my device. What should I do?
              </h4>
            </a>
          </div>
          <div
            id="faqSix"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingSix">
            <div class="panel-body">
              <p>
                Sorry about that! On a very rare occasion we do encounter a problem in Android, particularly Nexus devices. If you experience this with your device, we encourage you to <a href="mailto:help@astrnt.co">report it to us</a>. To complete your Q&amp;A session, we recommend using our <a href="http://astrnt.co" target="_blank">website</a> on your desktop.
              </p>
            </div>
          </div>
        </div>

        <div class="panel panel-white">
          <div class="panel-heading" role="tab" id="headingSeven">
            <a
              class="panel-title collapsed"
              role="button" data-toggle="collapse"
              data-parent="#accordion"
              href="#faqSeven"
              aria-expanded="false"
              aria-controls="faqSeven">
              <h4>
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="numbers fa-stack-1x fa-inverse">9</i>
                </span>
                In which web browsers can I do my Q&amp;A session?
              </h4>
            </a>
          </div>
          <div
            id="faqSeven"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingSeven">
            <div class="panel-body">
              <p>
                We support <a href="https://www.google.com/intl/en/chrome/browser/" target="_blank">Google Chrome</a> (recommended), <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a>, and <a href="http://www.opera.com/" target="_blank">Opera</a>.
                Unfortunately, we do not currently support Safari, Microsoft Edge, and Internet Explorer.
                These browsers are still in the experimental stage since WebRTC support was only recently released in these systems. Our team is working hard to work out the bugs before we roll out support for these browsers.
              </p>
            </div>
          </div>
        </div>

        <div class="panel panel-white">
          <div class="panel-heading" role="tab" id="headingEight">
            <a
              class="panel-title collapsed"
              role="button" data-toggle="collapse"
              data-parent="#accordion"
              href="#faqEight"
              aria-expanded="false"
              aria-controls="faqEight">
              <h4>
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="numbers fa-stack-1x fa-inverse">10</i>
                </span>
                I ran out of time but I haven’t completed all the questions. What do I do?
              </h4>
            </a>
          </div>
          <div
            id="faqEight"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingEight">
            <div class="panel-body">
              <p>
                Don’t worry, all the answers you sent before the time run out are uploaded to our database. However, Team ASTRNT don’t have any rights to give you a second chance. If you wish, you can contact your recruiter directly to request a second chance.
                If you think the timer ran out by mistake, please contact us at <a href="mailto:help@astrnt.co">help@astrnt.co</a>.
              </p>
            </div>
          </div>
        </div>

        <div class="panel panel-white">
          <div class="panel-heading" role="tab" id="headingNine">
            <a
              class="panel-title collapsed"
              role="button" data-toggle="collapse"
              data-parent="#accordion"
              href="#faqNine"
              aria-expanded="false"
              aria-controls="faqNine">
              <h4>
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="numbers fa-stack-1x fa-inverse">11</i>
                </span>
                I got disconnected from the internet during my Q&amp;A session. What should I do?
              </h4>
            </a>
          </div>
          <div
            id="faqNine"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingNine">
            <div class="panel-body">
              <p>
                Unfortunately, if you are in the middle of your Q&amp;A session, the timer will keep running even though you get disconnected. Please try to reconnect immediately.
              </p>
              <p>
                If you lost connection after you have completed all the questions, no need to worry because your answers are still there—you just need to reconnect to the internet and the upload process will continue.
                Our system does spot checks of your speed to avoid this problem.
              </p>
            </div>
          </div>
        </div>

        <div class="panel panel-white">
          <div class="panel-heading" role="tab" id="headingTen">
            <a
              class="panel-title collapsed"
              role="button" data-toggle="collapse"
              data-parent="#accordion"
              href="#faqTen"
              aria-expanded="false"
              aria-controls="faqTen">
              <h4>
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="numbers fa-stack-1x fa-inverse">12</i>
                </span>
                Have you received my answers?
              </h4>
            </a>
          </div>
          <div
            id="faqTen"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingTen">
            <div class="panel-body">
              <p>
                At the end of your Q&amp;A session, you should see an upload page that shows the status of your upload. Once all answers are uploaded, our system will send you a confirmation email. This means that your answers are safe and sound in our servers.
              </p>
            </div>
          </div>
        </div>

        <div class="panel panel-white">
          <div class="panel-heading" role="tab" id="headingEleven">
            <a
              class="panel-title collapsed"
              role="button" data-toggle="collapse"
              data-parent="#accordion"
              href="#faqEleven"
              aria-expanded="false"
              aria-controls="faqEleven">
              <h4>
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="numbers fa-stack-1x fa-inverse">13</i>
                </span>
                I’ve finished my Q&amp;A session. How do I get my results? What do I do next?
              </h4>
            </a>
          </div>
          <div
            id="faqEleven"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingEleven">
            <div class="panel-body">
              <p>
                Great job! For further information about your results and recruitment process, please contact your recruiter directly.
              </p>
            </div>
          </div>
        </div>

        <div class="panel panel-white">
          <div class="panel-heading" role="tab" id="headingTwelve">
            <a
              class="panel-title collapsed"
              role="button" data-toggle="collapse"
              data-parent="#accordion"
              href="#faqTwelve"
              aria-expanded="false"
              aria-controls="faqTwelve">
              <h4>
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="numbers fa-stack-1x fa-inverse">14</i>
                </span>
                I received an email asking for my document. What do I do?
              </h4>
            </a>
          </div>
          <div
            id="faqTwelve"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingTwelve">
            <div class="panel-body">
              <p>
                You received this email because the recruiter would like to have your document. Please read the email carefully to know what document is requested, and reply to the email with your document attached. The document must be in PDF format.
              </p>
            </div>
          </div>
        </div>

        <div class="panel panel-white">
          <div class="panel-heading" role="tab" id="headingThreeCode">
            <a
              class="panel-title collapsed"
              role="button" data-toggle="collapse"
              data-parent="#accordion"
              href="#faqThreeCode"
              aria-expanded="false"
              aria-controls="faqThreeCode">
              <h4>
                <span class="fa-stack fa-lg">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="numbers fa-stack-1x fa-inverse">15</i>
                </span>
                ASTRNT sounds awesome. How do I join the team?
              </h4>
            </a>
          </div>
          <div
            id="faqThreeCode"
            class="panel-collapse collapse"
            role="tabpanel"
            aria-labelledby="headingThreeCode">
            <div class="panel-body">
              <p>
                Team ASTRNT is always interested in receiving and watching video applications. Use the code "<b>futurefounder</b>" on ASTRNT to apply to work with us.
              </p>
            </div>
          </div>
        </div>

      </div>
      <p class="faq-note">Can’t find the answer you’re looking for? Please email us at <a href="mailto:help@astrnt.co">help@astrnt.co.</a></p>
      </div>
    </div>
  

        </Fragment>

    )
}
}

export default FaqEn