import React from 'react'
import imgLoader from '../../images/loader.gif'

class Loader extends React.Component {
	render() {
		return (
			<div className="wrapper-overlay" id="modalOnBoarding">
				<div className="overlay-content flex-container align-items-center">
					<img src={imgLoader} className="img-responsive img-loader" alt="Loading..." />
				</div>
      </div>
		)
	}
}

export default Loader
