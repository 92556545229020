import { List,Map,Set } from 'immutable'
import UAParser from 'ua-parser-js'
import history from '../dependencies/history'
import {en} from './lang_en'
import {id} from './lang_id'
/**
 * source: https://stackoverflow.com/a/18706818/9938539
 */
 const ua = navigator.userAgent;
 const nVer = navigator.appVersion;
 let cookieEnabled = navigator.cookieEnabled;

export function setHttpHeaders(params: Object = {}): Object {
    const parser = new UAParser()
    const device = parser.getDevice()
    const os = parser.getOS()
    const browser = parser.getBrowser()

    const headers = {
      device: '',
      os: `${os.name} ${os.version}`,
      browser: `${browser.name} ${browser.version}`,
      screenresolution: `${window.screen.availHeight}x${window.screen.availWidth}`,
      token: 'permintaanrizal'
    }

    if (Object.keys(params).length) {
      Object.assign(headers, params)
    }

    if (device.type) {
      Object
        .assign(
          headers,
          {
            device: `${device.type} ${device.model} ${device.vendor}`
          }
        )
    }

    return headers
  }

export function objectToFormData(obj: Object, form: Object, namespace: string) {
  let fd = form || new FormData()
  let formKey

  for(let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']'
      } else {
        formKey = property
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, formKey)
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property])
      }
    }
  }

  return fd
}

export function replaceAll(word: string, params: Object): string {
  let _word: string = word

  for (const x in params) {
    _word = _word.replace(new RegExp(x, 'g'), params[x])
  }

  return _word
}

export function t(translations: Map<string, any>, key: string, params: Object = {}): string {
  const word: string = translations.get(key)

  if (word) {
    return replaceAll(word, params)
  } else {
    return ''
  }
}

export function globalBlockingTransition(conditionFunction: ?Function = null) {
	return history.block((location, action) => {
		if (conditionFunction) {
			return conditionFunction(location, action)
		} else {
			return "Are you sure you want to leave this page?"
		}
	})
}

export function globalConcatImmutableData(data1: List<Map<string, any>>, data2: List<Map<string, any>>, orderBy: string = 'asc'): List<Map<string, any>> {
	const data: List<Map<string, any>> = data1
		.concat(data2)
		.toSet()
		.toList()

	return orderBy === 'asc' ? data.sortBy((a) => a.get('id')) : data.sortBy((a) => -a.get('id'))
}


export const getOSInfo = () => {
  let os = "-";
  let osVersion = "-";

  const clientStrings = [
    { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Windows Vista", r: /Windows NT 6.0/ },
    { s: "Windows Server 2003", r: /Windows NT 5.2/ },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
    { s: "Windows 98", r: /(Windows 98|Win98)/ },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: "Windows CE", r: /Windows CE/ },
    { s: "Windows 3.11", r: /Win16/ },
    { s: "Android", r: /Android/ },
    { s: "Open BSD", r: /OpenBSD/ },
    { s: "Sun OS", r: /SunOS/ },
    { s: "Linux", r: /(Linux|X11)/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "QNX", r: /QNX/ },
    { s: "UNIX", r: /UNIX/ },
    { s: "BeOS", r: /BeOS/ },
    { s: "OS/2", r: /OS\/2/ },
    {
      s: "Search Bot",
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
    }
  ];

  for (let id in clientStrings) {
    let cs = clientStrings[id];
    if (cs.r.test(ua)) {
      os = cs.s;
      break;
    }
  }

  if (/Windows/.test(os)) {
    osVersion = /Windows (.*)/.exec(os)[1];
    os = "Windows";
  }

  switch (os) {
    case "Mac OS X":
      osVersion = /Mac OS X (10[\.\_\d]+)/.exec(ua)
        ? /Mac OS X (10[\.\_\d]+)/.exec(ua)[1]
        : "11";
      break;

    case "Android":
      osVersion = /Android ([\.\_\d]+)/.exec(ua)[1];
      break;

    case "iOS":
      osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
      osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
      break;
  }

  let webkitVer = parseInt(
    /WebKit\/([0-9]+)|$/.exec(navigator.appVersion)[1],
    10
  ); // also matches AppleWebKit
  let isGoogle = webkitVer && navigator.vendor.indexOf("Google") === 0; // Also true for Opera Mobile and maybe others
  let isAndroid = isGoogle && navigator.userAgent.indexOf("Android") > 0; // Careful - Firefox and Windows Mobile also have Android in user agent
  let androidDesktopMode =
    !isAndroid &&
    isGoogle &&
    navigator.platform.indexOf("Linux a") === 0 &&
    "ontouchstart" in document.documentElement;
  if (androidDesktopMode) {
    os = "Android";
  }

  let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  if (isIOS) {
    os = "iOS"
  }
  // let osMobile = /Windows NT 10/.test(ua) ? "win10" : /Windows NT 6\.0/.test(ua) ? "winvista" : /Windows NT 6\.1/.test(ua) ? "win7" : /Windows NT 6\.\d/.test(ua) ? "win8" : /Windows NT 5\.1/.test(ua) ? "winxp" : /Windows NT [1-5]\./.test(ua) ? "winnt" : /Mac/.test(ua) ? "mac" : /Linux/.test(ua) ? "linux" : /X11/.test(ua) ? "nix" : ""
  // let mobile = /IEMobile|Windows Phone|Lumia/i.test(ua) ? 'w' : /iPhone|iP[oa]d/.test(ua) ? 'i' : /Android/.test(ua) ? 'a' : /BlackBerry|PlayBook|BB10/.test(ua) ? 'b' : /Mobile Safari/.test(ua) ? 's' : /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua) ? 1 : 0
  // let tablet = /Tablet|iPad/i.test(ua)

  // if (mobile) {
  //
  // }

  return {
    name: os,
    version: osVersion
  };
};


export function translate(
  languanges: string,
  key: string,
  params: Object = {}
): string {
  const word: string =  languanges=='en'? en[key] : id[key];
 
  if (word) {
    return replaceAll(word, params);
  } else {
    return "";
  }
}