import React from 'react'
import imgSupport from '../../images/public/support.svg'
import imgDevices from '../../images/public/devices.svg'
import imgCalChecked from '../../images/public/calendar-checked.svg'

class SupportList extends React.Component {
  render() {
    return (
			<section className="p-t-40 p-l-40 p-r-40">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <div className="media support">
                <div className="media-left">
									<img className="media-object" src={imgSupport} alt="Customer Support" />
                </div>
                <div className="media-body media-middle">
                  Support is included in all packages
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="media support">
                <div className="media-left">
									<img className="media-object" src={imgDevices} alt="Available devices" />
                </div>
                <div className="media-body media-middle">
                  Candidate convenience with desktop, iOS or Android
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="media support">
                <div className="media-left">
									<img className="media-object" src={imgCalChecked} alt="Schedule" />
                </div>
                <div className="media-body media-middle">
                  Try for free, cancel anytime
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
		);
  }
}

export default SupportList
