import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import imgFeatureList1 from '../../images/public/users.png'
import imgFeatureList2 from '../../images/public/star.png'
import imgFeatureList3 from '../../images/public/badge.png'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import BlogListNew from '../partials/BlogListNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import CallToActRegister from '../partials/CallToActRegister.jsx'
import BlogList from '../partials/BlogList.jsx'

import {getOSInfo , translate } from '../../helpers/global'
import homeIMG from '../../images/solution-2-big.png'

import personReview from '../../images/person_telkom.png'
import logoReview from '../../images/logo_telkom.png'

import imgSec1 from '../../images/solution-2-sec1.png'
import imgSec2 from '../../images/solution-2-sec2.png'
import imgSec3 from '../../images/solution-2-sec3.png'

import travelokaLogo from '../../images/logo/traveloka.png'
import philipLogo from '../../images/logo/philip.png'
import bcaLogo from '../../images/logo/bca.png'
import mbsbLogo from '../../images/logo/mbsb.png'
import nusLogo from '../../images/logo/nus.png'
import shopeeLogo from '../../images/logo/shopee.png'
import bniLogo from '../../images/logo/bni.png'
import infocommLogo from '../../images/logo/infocomm.png'
import darwinboxLogo from '../../images/logo/darwinbox.png'
import homecreditLogo from '../../images/logo/homecredit.png'

import teamtailorLogo from '../../images/logo/teamtailor.png'
import workableLogo from '../../images/logo/workable.png'
import pulsifiLogo from '../../images/logo/pulsifi.png'
import talentdatalabsLogo from '../../images/logo/talentdatalabs.png'
import podiumLogo from '../../images/logo/podium.png'
import greenhouseLogo from '../../images/logo/greenhouse.png'

import imgSeamless from '../../images/seamless.png'

import imgCard1 from '../../images/university-1.png'
import imgCard2 from '../../images/university-2.png'
import imgCard3 from '../../images/university-3.png'

import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

class ArticlesUniversityNew extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

  render() {
    const {
      globalGetLanguages,
      user
    } = this.props
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
			<div id="homepage">
        <HeaderNewClear isOnStatusFor="is-on" />
        <div className="section-top-solution relative double-triangle triangle-left" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='solution-top-box-hanging red'>
                  <h1 className='m-t-0 m-b-16'>
                    NextGen Assesment Centers Reimagining The Model 
                  </h1>
                  <p className='m-b-0'>The NextGen Assessment Center streamlines talent management with its asynchronous technology, simplifying complex processes and saving both time and money. This practical and efficient solution enhances the hiring experience, making it smoother and faster for all parties involved.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right flex-container flex-end align-items-center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={homeIMG} />
              </div>
            </div>
          </section>
          <div className='solution-back-top red desktop-hide'></div>
        </div>
        <div className="section-white" style={{overflow: 'hidden'}}>
          <section>
            <div className="text-center review-slider solution">
              <p>"The assessment results conducted by the NextGen Assessment Center method are identical to those of traditional assessment centers, but achieved much faster."</p>
              <div className='horizontal-item--between source-div telkom'>
                <div className='vertical-item--center'>
                  <img className='person-img' src={personReview} />
                  <div className='flex-column'>
                    <h5>Effy Abadi</h5>
                    <h6>SGM HC Management<br/>Telkom Akses</h6>
                  </div>
                </div>
                <div className='vertical-item--center'>
                  <img className='img-100 logo-review' src={logoReview} />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img style={{padding: '0 16px'}} className='img-100' src={imgSec1} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging-right-sol'>
                  <h1 className='m-t-0 m-b-16'>
                    Reliable, Flexible
                    Cost-Effective & Scalable
                  </h1>
                  <p className='m-b-0'>Experience the benefits of our NextGen Assessment Center: reliability, flexibility, and cost-effectiveness. Our method efficiently evaluates hundreds of employees within a month, ensuring a seamless and efficient hiring process.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='navy-back-feature-right for-2 desktop-hide'></div>
        </div>
        <div className="section-white relative double-triangle triangle-left disable-small-triangle sol-sec2-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
            <div className='flex-item-left'>
              <div className='vertical-item--center'>
                <div className='navy-box-hanging force-aqua'>
                  <h1 className='m-t-0 m-b-16'>
                  Scale Up Your Impact With Digitalisation
                  </h1>
                  <p className='m-b-0'>Technology-driven talent management method is highly scalable, eliminating the need for in-person assessments. This allows businesses using NextGen Assessment Center to efficiently evaluate a large number of employees, saving time and resources while connecting with candidates globally.</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
            <div className='flex-item-right horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgSec2} />
              </div>
            </div>
          </section>
          <div className='navy-back-feature-left for-2 desktop-hide'></div>
        </div>
        <div className="section-white relative double-triangle triangle-right sol-sec1-mobile" style={{position: 'relative'}}>
          <section className='flex-container' style={{zIndex: '2'}}>
          <div className='flex-item-left horizontal-item--center'>
              <div className='horizontal-item--center'>
                <img className='img-100' src={imgSec3} />
              </div>
            </div>
            <div className='flex-item-right'>
              <div className='vertical-item--center'>
                <div className='purple-box-hanging-right'>
                  <h1 className='m-t-0 m-b-16'>
                  Elevate Your Assessment Center With NextGen Model
                  </h1>
                  <p className='m-b-0'>By integrating the latest technology, we enhance existing Assessment Center’s delivery, accuracy, speed, and assessment quality. This modernization ensures efficient processes, delivering better insights and an improved candidate experience. Lets elevate your Assessment Center</p>
                  <a className='btn-primary' href='https://meetings.hubspot.com/nadhilah-yusuf/astrnt-discovery-call' target='_blank'>Consult with Our Assessment Experts</a>
                </div>
              </div>
            </div>
          </section>
          <div className='purple-back-feature-right for-2 desktop-hide'></div>
        </div>
        <FooterNew />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
	}
}

const mapDispatchToProps = (dispatch) => {
  return {
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesUniversityNew)
