import { List, Map } from 'immutable'

const initFilterData = Map({
	page: ''
})

const initCustomData = Map({
	color:'#564FDB',
	backgroundImage:'',
	social_media:[],
})

export function publicCompanies(state = List([]), action) {
	switch(action.type) {
		case 'PUBLIC_COMPANIES_FETCH_DATA_SUCCESS':
			return List(action.publicCompanies)
		default:
			return state
	}
}

export function publicCompaniesPagination(state = Map({}), action) {
	switch(action.type) {
		case 'PUBLIC_COMPANIES_FETCH_PAGINATION_SUCCESS':
			return Map(action.pagination)
		default:
			return state
	}
}

export function publicCompaniesFilterData(state = initFilterData, action) {
	switch(action.type) {
		case 'PUBLIC_COMPANIES_ADD_FILTER_PAGE_NUMBER_SUCCESS':
			return state.set('page', action.page)
		default:
			return state
	}
}

export function publicCompany(state = Map({}), action) {
  switch(action.type) {
    case 'PUBLIC_COMPANIES_GET_DETAIL_SUCCESS':
    	return Map(action.publicCompany)
		case 'PUBLIC_COMPANY_FETCH_DATA_SUCCESS':
    	return Map(action.publicCompany)
    default:
    	return state
  }
}

export function publicJobDetail(state = Map({}), action) {
  switch(action.type) {
    case 'PUBLIC_JOB_GET_DETAIL_SUCCESS':
    	return Map(action.publicJobDetail)
		case 'PUBLIC_JOB_FETCH_DATA_SUCCESS':
			return Map(action.publicJobDetail)
    default:
    	return state
  }
}

export function publicJobCustom(state= initCustomData, action){
	switch(action.type) {
	case 'PUBLIC_JOB_CUSTOM_COLOR_SUCCESS':
		return state.set('color',action.color)
	case 'PUBLIC_JOB_CUSTOM_IMAGE_SUCCESS':
		return state.set('backgroundImage',action.backgroundImage)
	case 'PUBLIC_JOB_CUSTOM_SOCIAL_SUCCESS':
		return state.set('social_media',action.social_media)
	 default:
    	return state
  }
}

export function registrationHasErrored(state = false, action) {
	switch (action.type) {
		case 'REGISTRATION_HAS_ERRORED':
			return action.hasErrored
		default:
			return state
	}
}

export function registrationSaveDataError(state = Map({}), action) {
	switch(action.type) {
		case 'REGISTRATION_SAVE_DATA_ERROR':
			return Map(action.errors)
		default:
			return state
	}
}
