import axios from 'axios'
import Promise from 'bluebird'
import { reset } from 'redux-form'
import omit from 'lodash/omit'
import { List, fromJS } from 'immutable'
import NProgress from 'nprogress/nprogress.js'
import Notifications from 'react-notification-system-redux'
import initializers from '../dependencies/initializers'
import { globalHideSweetAlert,globalAddSweetAlertData } from './global'

import { globalConcatImmutableData } from '../helpers/global'

require('es6-promise').polyfill()

export function usersFetchDataSuccess(users) {
	return {
		type: 'USERS_FETCH_DATA_SUCCESS',
		users
	}
}

export function usersFetchDataHiringTeamSuccess(users) {
	return {
		type: 'USERS_FETCH_DATA_HIRING_TEAM_SUCCESS',
		users
	}
}

export function usersGetDetailSuccess(user) {
	return {
		type: 'USERS_GET_DETAIL_SUCCESS',
		user
	}
}

export function usersGetDetailTempSuccess(user) {
	return {
		type: 'USERS_GET_DETAIL_TEMP_SUCCESS',
		user
	}
}

export function usersHasErrored(bool) {
	return {
		type: 'USERS_HAS_ERRORED',
		hasErrored: bool
	}
}

export function usersSaveDataError(errors) {
	return {
		type: 'USERS_SAVE_DATA_ERROR',
		errors
	}
}

export function usersAddFilterPageNumberSuccess(page) {
	return {
		type: 'USERS_ADD_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function usersAddFilterSelectedIdsSuccess(selected_ids) {
	return {
		type: 'USERS_ADD_FILTER_SELECTED_IDS_SUCCESS',
		selected_ids
	}
}

export function usersAddFilterSortSuccess(sort) {
	return {
		type: 'USERS_ADD_FILTER_SORT_SUCCESS',
		sort
	}
}

export function usersAddFilterFullnameSuccess(fullname) {
	return {
		type: 'USERS_ADD_FILTER_FULLNAME_SUCCESS',
		fullname
	}
}

export function usersAddFilterJobIdSuccess(job_id) {
	return {
		type: 'USERS_ADD_FILTER_JOB_ID_SUCCESS',
		job_id
	}
}

export function usersClearFilterDataSuccess() {
	return {
		type: 'USERS_CLEAR_FILTER_DATA_SUCCESS'
	}
}

export function usersAddHiringTeamFilterPageNumberSuccess(page) {
	return {
		type: 'USERS_ADD_HIRING_TEAM_FILTER_PAGE_NUMBER_SUCCESS',
		page
	}
}

export function usersAddHiringTeamFilterLastPageNumberSuccess(last_page) {
	return {
		type: 'USERS_ADD_HIRING_TEAM_FILTER_LAST_PAGE_NUMBER_SUCCESS',
		last_page
	}
}

export function usersAddHiringTeamFilterSelectedIdsSuccess(selected_ids) {
	return {
		type: 'USERS_ADD_HIRING_TEAM_FILTER_SELECTED_IDS_SUCCESS',
		selected_ids
	}
}

export function usersAddHiringTeamFilterFullnameSuccess(fullname) {
	return {
		type: 'USERS_ADD_HIRING_TEAM_FILTER_FULLNAME_SUCCESS',
		fullname
	}
}

export function usersClearHiringTeamFilterDataSuccess() {
	return {
		type: 'USERS_CLEAR_HIRING_TEAM_FILTER_DATA_SUCCESS'
	}
}

export function usersAddFormIsUpdateSuccess(is_update) {
	return {
		type: 'USERS_ADD_FORM_IS_UPDATE_SUCCESS',
		is_update
	}
}

export function usersAddFormIsAccessIncJobsSuccess(is_access_inc_jobs) {
	return {
		type: 'USERS_ADD_FORM_IS_access_inc_jobs_SUCCESS',
		is_access_inc_jobs
	}
}

export function usersAddFormIsAccessExistingJobSuccess(is_access_existing_job) {
	return {
		type: 'USERS_ADD_FORM_IS_ACCESS_EXISTING_JOB_SUCCESS',
		is_access_existing_job
	}
}

export function usersAddFormIsDisableFullnameSuccess(is_disable_fullname) {
	return {
		type: 'USERS_ADD_FORM_IS_DISABLE_FULLNAME_SUCCESS',
		is_disable_fullname
	}
}

export function usersClearFormDataSuccess() {
	return {
		type: 'USERS_CLEAR_FORM_DATA_SUCCESS'
	}
}

export function usersFetchIdsSuccess(ids) {
	return {
		type: 'USERS_FETCH_IDS_SUCCESS',
		ids
	}
}

export function usersFetchPaginationSuccess(pagination) {
	return {
		type: 'USERS_FETCH_PAGINATION_SUCCESS',
		pagination
	}
}

export function usersFetchDataDropdownSuccess(users) {
	return {
		type: 'USERS_FETCH_DATA_DROPDOWN_SUCCESS',
		users
	}
}

export function usersAddNavigationPage(page) {
	return {
		type: 'USERS_ADD_NAVIGATION_PAGE_SUCCESS',
		page
	}
}

export function usersSaveDataRequest(formName) {
	return {
		type: 'USERS_SAVE_DATA_REQUEST',
		formName
	}
}

export function usersValidateEmailOnUserRegisterWizardFormRequest(email) {
	return {
		type: 'USERS_VALIDATE_EMAIL_ON_USER_REGISTER_WIZARD_FORM_REQUEST',
		email
	}
}

export function usersAddFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFilterPageNumberSuccess(page)))
		})
	}
}

export function usersAddFilterSelectedIds(selected_ids) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFilterSelectedIdsSuccess(selected_ids)))
		})
	}
}

export function usersAddFilterSort(sort) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFilterSortSuccess(sort)))
		})
	}
}

export function usersAddFilterFullname(fullname) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFilterFullnameSuccess(fullname)))
		})
	}
}

export function usersAddFormIsUpdate(is_update) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFormIsUpdateSuccess(is_update)))
		})
	}
}

export function usersGetDetailAsync(user) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersGetDetailSuccess(user)))
		})
	}
}

export function usersGetDetailTempAsync(user) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersGetDetailTempSuccess(user)))
		})
	}
}

export function usersAddFilterJobId(job_id) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddFilterJobIdSuccess(job_id)))
		})
	}
}

export function usersClearFilterData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersClearFilterDataSuccess()))
		})
	}
}

export function usersAddHiringTeamFilterPageNumber(page) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddHiringTeamFilterPageNumberSuccess(page)))
		})
	}
}

export function usersAddHiringTeamFilterSelectedIds(selectedIds) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddHiringTeamFilterSelectedIdsSuccess(selectedIds)))
		})
	}
}

export function usersAddHiringTeamFilterFullname(fullname) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersAddHiringTeamFilterFullnameSuccess(fullname)))
		})
	}
}

export function usersClearHiringTeamFilterData() {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(usersClearHiringTeamFilterDataSuccess()))
		})
	}
}

export function usersFetchData(companyId, filterData) {
	return (dispatch) => {
		NProgress.start()

		const jobId = filterData.get('job_id')
		let url = initializers.api_host+`/companies/${companyId}/team-member/filter`

		if (jobId) {
			url = initializers.api_host+`/companies/${companyId}/jobs/${filterData.get('job_id')}/hiring-team/filter`
		}

		return axios({
			url,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			params: filterData.toJS()
		})
		.then((response) => {
			NProgress.done()

			const userIds = response.data.ids
			const users = response.data.users.data
			const pagination = omit(response.data.users, 'data')

			dispatch(usersFetchIdsSuccess(userIds))
			dispatch(usersFetchPaginationSuccess(pagination))
			dispatch(usersFetchDataSuccess(users))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading team member.',
				position: 'bl'
			}))

			console.error(error)
		})
	}
}

export function usersFetchDataHiringTeam(companyId, jobId, filterData, currentUsers = List([])) {
	return (dispatch) => {
		return axios({
			url: initializers.api_host+`/companies/${companyId}/jobs/${jobId}/hiring-team/list`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			params: filterData.toJS()
		})
		.then((response) => {
			const users = fromJS(response.data.users.data)
			const pagination = omit(response.data.users, 'data')

			dispatch(usersAddHiringTeamFilterLastPageNumberSuccess(pagination.last_page))
			dispatch(usersAddHiringTeamFilterPageNumberSuccess(pagination.current_page))

			if (filterData.get('page') || filterData.get('last_page')) {
				const currentUserIds = currentUsers
					.map((currentUser) => {
						return currentUser.get('id')
					})

				const filteredUsers = users
					.filter((user) => {
						return !currentUserIds.includes(user.get('id'))
					})

				const _currentUsers = globalConcatImmutableData(
					currentUsers,
					filteredUsers,
					'desc'
				)

				dispatch(usersFetchDataHiringTeamSuccess(_currentUsers))
			} else {
				dispatch(usersFetchDataHiringTeamSuccess(users))
			}
		})
		.catch((error) => {
			console.error(error)
		})
	}
}

export function usersAddHiringTeamToJob(companyId, jobId, userIds) {
	return (dispatch) => {
		return axios({
			url: initializers.api_host+`/companies/${companyId}/jobs/${jobId}/hiring-team`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			params: {
				user_ids: userIds.toJS()
			}
		})
		.catch((error) => {
			console.error(error)
		})
	}
}

// When we need to assign user to temporary data like on edit team member, set temp parameter to true
export function usersGetDetailTemp(company_id, user_id) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: initializers.api_host+`/companies/${company_id}/team-member/${user_id}/detail`,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			NProgress.done()

			const user = response.data
			dispatch(usersGetDetailTempSuccess(user))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when loading user.',
				position: 'bl'
			}))

			console.error(error)
		})
	}
}

export function usersCreateData(companyId, data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: initializers.api_host+`/companies/${companyId}/team-member`,
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data.toJS()
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'Team member successfully created.',
				position: 'bl'
			}))

			dispatch(reset('ModalAddMember'))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when creating team member.',
				position: 'bl'
			}))

			dispatch(usersSaveDataError(error.response.data.message))

			throw error
		})
	}
}

export function usersUpdateData(companyId, userId, data) {
	return (dispatch) => {
		NProgress.start()

		return axios({
			url: initializers.api_host+`/companies/${companyId}/team-member/${userId}`,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: data.toJS()
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'Team member successfully updated.',
				position: 'bl'
			}))

			dispatch(reset('ModalAddMember'))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
				title: 'Error',
				message: 'An error occured when updating team member.',
				position: 'bl'
			}))

			dispatch(usersSaveDataError(error.response.data.message))

			throw error
		})
	}
}

export function usersUpdateProfileData(company_id, user_id, data, user) {
	return (dispatch) => {
		NProgress.start()

		axios({
			url: initializers.api_host+`/companies/${company_id}/users/${user_id}`,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'User successfully updated.',
				position: 'bl'
			}))

			const _user = user.set('preferredName', response.data.user.preferred_name)

			dispatch(usersGetDetailSuccess(_user))
			dispatch(usersSaveDataError({}))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'An error occured.',
			  position: 'bl'
			}))

			dispatch(usersSaveDataError(error.response.data.message))

			console.error(error)
		})
	}
}

export function usersDelete(companyId, filterData, jobId = '') {
	return (dispatch) => {
		NProgress.start()

		let url = initializers.api_host+`/companies/${companyId}/team-member`

		if (jobId) {
			url = initializers.api_host+`/companies/${companyId}/jobs/${jobId}/hiring-team`
		}

		return axios({
			url,
			timeout: 20000,
			method: 'delete',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: filterData.toJS()
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
				title: 'Success',
				message: 'Member successfully deleted.',
				position: 'bl'
			}))

			dispatch(usersAddFilterSelectedIds([]))
			dispatch(globalHideSweetAlert())
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'An error occured when deleting member.',
			  position: 'bl'
			}))

			console.error(error)
		})
	}
}

// Get detail user without store the value to reducer.
export function usersGetDetailByParam(company_id, filter_data) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			axios({
				url: initializers.api_host+`/users/filter`,
				timeout: 20000,
				method: 'get',
				responseType: 'json',
				params: filter_data
			})
			.then((response) => {
				const user = response.data.user

				resolve(user)
			})
			.catch((error) => {
				dispatch(usersHasErrored(true))

				console.error(error)
			})
		})
	}
}

export function usersUpdateIsNotifiedAndLevel(companyId, userId, data, jobId = '') {
	return (dispatch) => {
		let url = initializers.api_host+`/companies/${companyId}/team-member/${userId}/job`
		let messageSuccess = 'Team member successfully updated.'
		let messageError = 'An error occured when updating team member.'

		if (jobId) {
			url = initializers.api_host+`/companies/${companyId}/jobs/${jobId}/hiring-team/${userId}`
			messageSuccess = 'Hiring team successfully updated.'
			messageError = 'An error occured when updating hiring team.'
		}

		return axios({
			url,
			timeout: 20000,
			method: 'put',
			responseType: 'json',
			data: data
		})
		.then((response) => {
			dispatch(Notifications.success({
				title: 'Success',
				message: messageSuccess,
				position: 'bl'
			}))
		})
		.catch((error) => {
			dispatch(Notifications.error({
			  title: 'Error',
			  message: messageError,
			  position: 'bl'
			}))

			console.error(error)
		})
	}
}

export function userAddTempEmail(email) {
	return {
		type: 'USER_TEMP_EMAIL',
		email
	}
}

// export function usersResendVerificationEmailRequest(email) {
// 	return {
// 		type: 'USERS_RESEND_VERIFICATION_EMAIL_REQUEST',
// 		email
// 	}
// }

export function usersResendVerificationEmailRequest(email) {
	return (dispatch) => {
		return axios({
			url: initializers.recruiter_host+'/users/resend_verification_email',
			timeout: 20000,
			method: 'post',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'},
			data: {
				email: email
			}
		})
		.then((response) => {
			NProgress.done()

			dispatch(Notifications.success({
			  title: 'Success',
			  message: 'Company profile successfully updated.',
				position: 'bl'
			}))
		})
		.catch((error) => {
			NProgress.done()

			dispatch(Notifications.error({
			  title: 'Error',
			  message: 'Updating company profile failed.',
			  position: 'bl'
			}))
			throw error
		})
	}
}

export function userVerify() {
	return (dispatch) => {

		let url = initializers.api_host+`/verify`

		return axios({
			url,
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
		.then((response) => {
			dispatch(userAddCSRFToken(response.data._token))
		})
		.catch((error) => {
			dispatch(userAddCSRFToken(''))
		})
	}
}

export function userAddCSRFToken(csrf_token) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			resolve(dispatch(userAddCSRFTokenSuccess(csrf_token)))
		})
	}
}

export function userAddCSRFTokenSuccess(csrf_token) {
	return {
		type: 'USERS_ADD_CSRF_TOKEN_SUCCESS',
		csrf_token
	}
}