import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import { Map } from 'immutable'
import Initializers from '../../../dependencies/initializers'
import { usersResendVerificationEmailRequest } from '../../../actions/users'
import {
	globalHideSweetAlert,
	globalAddSweetAlertData
} from '../../../actions/global'
type Props = {
	userTempEmail: Map<string, any>,
	usersResendVerificationEmailRequest: Function,
	globalHideSweetAlert: Function,
	globalAddSweetAlertData: Function
};
class UserRegisterWizardFormThirdPageNew extends React.Component<Props> {
	constructor(props) {
		super(props)

		bindAll(this, [
			'handleLoginClick', 'handleResendVerificationEmailClick'
		])
	}

	handleLoginClick(e) {
		e.preventDefault()

		
			window.location = Initializers.api_host+'/auth/login'
	
	}

	handleResendVerificationEmailClick(e) {
		e.preventDefault()

		const {
			userTempEmail,
			usersResendVerificationEmailRequest,
			globalHideSweetAlert,
			globalAddSweetAlertData
		} = this.props
		console.log('resendEmail', userTempEmail);
		usersResendVerificationEmailRequest(userTempEmail.get('email'))
		.then(()=>{
			globalAddSweetAlertData({
				show: true,
				title: 'Please Verify Your Email',
				text: "Your ASTRNT account has been successfully created. Check your email to confirm your account",
				confirmButtonText: 'OK',
				confirmButtonColor: '#564FDB',
				showLoaderOnConfirm: true,
				onConfirm: () => {
					globalHideSweetAlert()
					window.location = Initializers.api_host+'/auth/login'
				}
			})
		})
	}

	render() {
		return(
			<Fragment>
				<div className="tit-reg tit-reg-verify">Please Verify Your Email</div>
		        <div className="center text-success-top">
	              Your ASTRNT account has been successfully created. Check your email to confirm your account.
	            </div>
	            <div className="center text-success-bottom">
	              <p>If you can’t find the email, check your spam folder or</p>
	              <p><b><a onClick={this.handleResendVerificationEmailClick} className="cursor-pointer">Resend Email</a></b>.</p>
	            </div>
			</Fragment>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		userTempEmail: state.userTempEmail
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		usersResendVerificationEmailRequest: (email) => dispatch(usersResendVerificationEmailRequest(email)),
		globalAddSweetAlertData: (options) => dispatch(globalAddSweetAlertData(options)),
		globalHideSweetAlert: () => dispatch(globalHideSweetAlert()),
	}
}
export default connect(mapStateToProps,mapDispatchToProps)(UserRegisterWizardFormThirdPageNew)
