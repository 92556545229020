import React from 'react'

class RenderInputV2 extends React.Component {
	render() {
		const { dontShowError, input, label, formClass, placeholder, type, PassReqMobile, isValidatingEmail, meta: { touched, error }, disabled, name } = this.props

		return(
			<div className={`form-group ${formClass} ${touched && error && 'has-error'} ${isValidatingEmail && 'has-warning'}`}>
		    <label>{label}</label>
		    {PassReqMobile}
		    <div>
		      <input
		      	{...input}
		      	placeholder={placeholder}
		      	type={type}
		      	className={`form-control ${touched && error && 'error-form'}`}
				disabled={disabled}
				ref={name}
				autoComplete={type=='password'?'off':''} />
			{dontShowError?
			''
			:
			touched && error && <div className="help-block"><div dangerouslySetInnerHTML={{__html: error}}></div></div>
			}
		      {isValidatingEmail && <div className="help-block">Checking your email...</div>}
		    </div>
		  </div>
		)
	}
}


export default RenderInputV2
