
import React,{Fragment} from 'react'
import {connect} from 'react-redux'
import PrivacyContent from '../partials/PrivacyContent'
import { Link } from 'react-router-dom'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import $ from 'jquery'

import ImgError404 from '../../images/404.svg'
import { translate } from '../../helpers/global'

class PageNotFound extends React.Component<Props>{
    constructor(props) {
        super(props)

        // bindAll(this, [
        //  'handleCheckInterviewCode'
        // ])
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        $('#public').attr('id','not-public')
    }

  componentWillUnmount(){
    $('#not-public').attr('id','public')
  }
    render(){
        const{globalGetLanguages} = this.props
        const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
        const btnBack = (<Link to="/">{translate(globalGetLanguages, '404_BUTTON_BACK')}</Link>)

        return(
            <div id="misc">
            <HeaderNewClear />
            <div className="pad-top desktop-hide" />
            <section>
                <div className="error-404 flex-container">
                    <div className="text-404">
                        <label>{translate(globalGetLanguages, '404_OOPS')}</label>
                        <div className="desktop-hide">
                            {renderHTML(translate(globalGetLanguages, '404_DESKTOP_HIDE_TEXT'))}
                            {btnBack}
                        </div>
                    </div>
                    <div><img src={ImgError404} /></div>
                    <div className="mobile-hide">
                        <div className="text-404">
                            {renderHTML(translate(globalGetLanguages, '404_MOBILE_HIDE_TEXT'))}
                            {btnBack}
                        </div>
                    </div>
                </div>
            </section>
            <FooterNew />
          </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        globalGetLanguages: state.globalGetLanguages,
    }
}


export default connect(mapStateToProps, null)(PageNotFound)

