// @flow

import React from 'react'
import bindAll from 'lodash/bindAll'
import { connect } from 'react-redux'
import { withRouter } from "react-router"
type Props = {};

class CallToActRegister extends React.Component<Props> {

	constructor(props) {
		super(props)

		bindAll(this, [
			'handleRegisterLink'
			
		])
	}

	handleRegisterLink(e: Object): void {
		e.preventDefault()

		const{
			history
		}=this.props
		
		history.push('/register')
	}

  render() {
    return (
			<section className="p-24 center">
				<button className="button button__primary align-self-end" onClick={this.handleRegisterLink} >Try for free</button>
				<p className="m-t-16 text-link">Or, <a href="mailto:help@astrnt.co" className="underline text-default">talk to us</a></p>
			</section>
		);
  }
}

const mapStateToProps = (state, ownProps) => {
	return {
		
	}
}

export default withRouter(connect(mapStateToProps,null)(CallToActRegister))
