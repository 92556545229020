import React from 'react'
import { connect } from 'react-redux'
import { translate } from '../../helpers/global'

import { Link } from 'react-router-dom'

import imgSeamless from '../../images/seamless.png'

import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

class BannerDemoNew extends React.Component {
  render() {
    const{globalGetLanguages,user} = this.props
    return (
        <div className="sec-light-purple banner">
            <section>
            <div className="ban-text">
                <div className="big-text">{translate(globalGetLanguages,'HOMEPAGE_READY_TO_CREATE')}</div>
                {translate(globalGetLanguages,'HOMEPAGE_WE_HELP')}
                <div className={`ban-link`}>
                  <div>
                      <Link to={{pathname: `/register`, query: { plan: 'es2t' }}} className="try">{translate(globalGetLanguages,'HOMEPAGE_BUTTON_START_FREE_TRIAL')}</Link>
                      <br/>
                      <span className="span-cc">{translate(globalGetLanguages,'HOMEPAGE_LABEL_NO_CREDIT_CARD')}</span>
                  </div>
                  <Link to='/request-demo'>{translate(globalGetLanguages,'HOMEPAGE_BUTTON_REQUEST_DEMO')}</Link>
                </div>
                <div className={`ban-link hide`}>
                  <Link to='/request-demo' className='request-demo-logged'>{translate(globalGetLanguages,'HOMEPAGE_BUTTON_REQUEST_DEMO')}</Link>
                </div>
                <a href="#" className="vid-msg">
                <img src={imgSeamless} />
                </a>
            </div>
            </section>
      </div>
		);
  }
}

const mapStateToProps = (state) => {
    return{ 
      globalGetLanguages:state.globalGetLanguages,
      user: state.user
    }
    
  }

const mapDispatchToProps = (dispatch) => {
  return {
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerDemoNew)
