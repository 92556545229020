// @flow

import React, { Fragment } from 'react'
import {
	Field,
	reduxForm,
  change
} from 'redux-form'

import { connect } from 'react-redux'

import { Link } from 'react-router-dom'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import imgFirstAsia from '../../images/public/FirstAsiaConsultants.png'
import imgKarirpad from '../../images/public/Karirpad.png'
import imgUrbanHire from '../../images/public/Urbanhire.png'
import imgIndotrainee from '../../images/public/Indotrainee.png'
import imgHCC from '../../images/public/hcc.png'
import imgPulsifi from '../../images/public/pulsifi.png'
import CallToActRegister from '../partials/CallToActRegister.jsx'
import imgPartnership from '../../images/partnership-banner.png'
import imgCard1 from '../../images/Card1partner.png'
import imgCard2 from '../../images/Card2partner.png'
import imgCard3 from '../../images/Card3partner.png'
import imgSeamless from '../../images/seamless.png'

import PartnershipForm from './PartnershipForm'


import {getOSInfo , translate } from '../../helpers/global'

type State = {
	jobs: Array<Object>
};

class PartnersPageNew extends React.Component<State> {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

  render() {
    const{globalGetLanguages} = this.props
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    return (
			<div id="homepage">
	        <HeaderNewClear isOnStatusFor="is-on" />
	        <div className="banner home">
	
				<section>
					<div className="ban-text partnership-ban">
						<div className="big-text">{translate(globalGetLanguages,'PARTNERS_PAGE_CARD_1_TITLE')}</div>
						<span className="partnership-desc">{translate(globalGetLanguages,'PARTNERS_PAGE_CARD_1_TEXT')}</span>
						<div className="ban-link">
							{/* <Link to={{pathname: `/register`, query: { plan: 'es2t' }}} className="try">Start your Free Trial</Link><Link to='/request-demo'>Request Demo</Link> */}
						</div>
						<a href="#" className="vid-msg partner-msg">
							<img src={imgPartnership} />
						</a>
						
					</div>
				</section>
			</div>

		<div className="sec-white">
			<section className="partner partner-page-sec">
				<p className="big-letter">{translate(globalGetLanguages,'PARTNERS_PAGE_CARD_2_TITLE')}</p>
			</section>
        </div>
		<div className="sec-white">
          <section className="sec-con partnership-section">
            <ul>
              <li>
                <ul className="on-left card-partner">
                  <li>
                  {translate(globalGetLanguages,'PARTNERS_PAGE_CARD_2_CONTENT_TITLE_1')}
                    <span>{translate(globalGetLanguages,'PARTNERS_PAGE_CARD_2_CONTENT_TEXT_1')} </span>
                  </li>
                  <li>
                    <img className="img-card-left" src={imgCard1} />
                  </li>
                </ul>
              </li>
            </ul>
          </section>
        </div>
		<div className="sec-white">
          <section className="sec-con partnership-section">
            <ul>
              <li>
                <ul className="on-right card-partner">
                  <li>
                  {translate(globalGetLanguages,'PARTNERS_PAGE_CARD_2_CONTENT_TITLE_2')}
                    <span>{translate(globalGetLanguages,'PARTNERS_PAGE_CARD_2_CONTENT_TEXT_2')}</span>
                  </li>
                  <li>
                    <img className="img-card-right" src={imgCard2} />
                  </li>
                </ul>
              </li>
            </ul>
          </section>
        </div>
		<div className="sec-white">
          <section className="sec-con partnership-section">
            <ul>
              <li>
                <ul className="on-left card-partner last-card">
                  <li>
                  {translate(globalGetLanguages,'PARTNERS_PAGE_CARD_2_CONTENT_TITLE_3')}
                    <span>{translate(globalGetLanguages,'PARTNERS_PAGE_CARD_2_CONTENT_TEXT_3')}</span>
                  </li>
                  <li>
                    <img className="img-card-left" src={imgCard3} />
                  </li>
                </ul>
              </li>
            </ul>
          </section>
        </div>
		
		<div className="sec-purple">
			
			<section className="partner-no-margin">
			<p className="big-letter">{translate(globalGetLanguages,'PARTNERS_PAGE_CARD_3_TITLE')}</p>
      
			<p>{renderHTML(translate(globalGetLanguages,'PARTNERS_PAGE_CARD_3_TEXT'))} </p>
				<div id='register' className='partnership-container'>
					<div className="center box-reg partnership-form">
						<PartnershipForm />
					</div>
				</div>
			</section>
        </div>
      <BannerDemoNew/>
	        
	        <SupportListNew />
	        <FooterNew />
	      </div>
		)
  }
}

const mapStateToProps = (state) => {
  return{ 
    globalGetLanguages:state.globalGetLanguages,
  }
  
}

export default connect(mapStateToProps, null )(PartnersPageNew)
