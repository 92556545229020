//flow
import React,{Fragment} from 'react'

class FaqId extends React.Component{
    render(){
        return (
            <Fragment>
                <div className="faq-page">
                    <div className="jumbotron blue-bg">
                        <div className="container">
                            <center>
                                <h2>Kami selalu siap membantu Anda.</h2>
                                <p className="whiteFont">
                                Temukan jawaban atas beberapa pertanyaan paling sering kami terima di bawah ini.
                                </p>
                            </center>
                        </div>
                    </div>
                    <div className="container">
                        <div className="panel-group faq-accordion" id="accordion" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingOneCode">
                            <a
                                className="panel-title"
                                role="button"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqOneCode"
                                aria-expanded="true"
                                aria-controls="faqOneCode">
                                <h4>
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">1</i>
                                </span> Apa itu kode? Kenapa saya butuh kode?
                                </h4>
                            </a>
                            </div>
                            <div
                            id="faqOneCode"
                            className="panel-collapse collapse in"
                            role="tabpanel"
                            aria-labelledby="headingOneCode">
                            <div className="panel-body">
                                <p>Kode ASTRNT Q&A adalah kode yang ASTRNT gunakan untuk memastikan bahwa Anda mendapatkan pertanyaan yang tepat. Untuk setiap kode, terdapat sejumlah pertanyaan dan skenario spesifik, sesuai dengan apa yang perekrut ingin tahu tentang Anda.</p>
                            </div>
                            </div>
                        </div>
                        <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingTwoCode">
                            <a
                                className="panel-title"
                                role="button"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqTwoCode"
                                aria-expanded="false"
                                aria-controls="faqTwoCode">
                                <h4>
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">2</i>
                                </span> Di mana saya bisa mendapatkan kode?
                                </h4>
                            </a>
                            </div>
                            <div
                            id="faqTwoCode"
                            className="panel-collapse collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwoCode">
                            <div className="panel-body">
                                <p>Jika Anda diundang oleh perekrut untuk melakukan tanya jawab di ASTRNT, silakan cek email Anda untuk menemukan kode.<br/>
                                Jika Anda mengunduh aplikasi ASTRNT karena sebuah iklan/pengumuman, silakan cek iklan/pengumuman tersebut untuk menemukan kode.<br/>
                                Jika Anda ingin melamar ke ASTRNT, silakan cek pertanyaan terakhir pada halaman ini untuk menemukan kode kami.</p>
                            </div>
                            </div>
                        </div>
                        <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingOne">
                            <a
                                className="panel-title"
                                role="button"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqOne"
                                aria-expanded="false"
                                aria-controls="faqOne">
                                <h4>
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">3</i>
                                </span> Saya memiliki kode untuk sesi tanya jawab ASTRNT, apa langkah selanjutnya?
                                </h4>
                            </a>
                            </div>
                            <div
                            id="faqOne"
                            className="panel-collapse collapse"
                            role="tabpanel"
                            aria-labelledby="headingOne">
                            <div className="panel-body">
                                <p>Anda sudah siap untuk melakukan sesi tanya jawab Anda! Ada dua cara untuk melakukannya:</p>
                                <p>
                                <b>Menggunakan aplikasi ASTRNT Q&A pada perangkat mobile</b><br/>
                                Anda dapat mengunduh aplikasi ASTRNT Q&A (tersedia di <a href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247" target="_blank">iOS</a>
                                dan <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa"
                                target="_blank">Android</a>), masukkan kode Anda, dan ikuti petunjuk pada aplikasi.
                                </p><br/>
                                <p>
                                <b>Menggunakan website ASTRNT di desktop</b><br/>
                                Buka <a href="http://astrnt.co" target="_blank">http://astrnt.co</a>, klik "I have a code", dan masukkan kode Anda. Kemudian ikuti instruksi untuk melakukan sesi tanya jawab Anda.
                                </p><br/>
                                <p>Sebelum memulai, pastikan koneksi internet Anda stabil. Carilah tempat yang nyaman dan sediakan waktu yang cukup untuk melakukan sesi tanya jawab tanpa distraksi. Semoga sukses!</p>
                            </div>
                            </div>
                        </div>
                        <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingTwo">
                            <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqTwo"
                                aria-expanded="false"
                                aria-controls="faqTwo">
                                <h4>
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">4</i>
                                </span> Saya menerima email undangan untuk sesi tanya jawab, apa yang harus saya lakukan selanjutnya?
                                </h4>
                            </a>
                            </div>
                            <div
                            id="faqTwo"
                            className="panel-collapse collapse"
                            role="tabpanel"
                            aria-labelledby="headingTwo">
                            <div className="panel-body">
                                <p>Anda sudah siap untuk melakukan sesi tanya jawab Anda! Anda dapat melakukan sesi tanya jawab di aplikasi ASTRNT Q&A atau melalui website kami di desktop Anda.</p>
                                <p>
                                <b>Menggunakan aplikasi ASTRNT Q&A pada perangkat mobile</b><br/>
                                Unduh aplikasi ASTRNT Q&A (tersedia di <a href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247" target="_blank">iOS</a>
                                dan <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa"
                                target="_blank">Android</a>), masukkan kode (atau klik tautan di email Anda), dan ikuti petunjuk pada aplikasi.
                                </p><br/>
                                <p>
                                <b>Menggunakan website ASTRNT di desktop</b><br/>
                                Klik tautan di email Anda atau buka <a href="http://astrnt.co" target="_blank">http://astrnt.co</a>, klik "I have a code", dan masukkan kode Anda. Kemudian ikuti petunjuk untuk menyelesaikan sesi Tanya Jawab Anda.
                                </p><br/>
                                <p>
                                Sebelum memulai, pastikan koneksi internet Anda stabil. Carilah tempat yang nyaman dan sediakan waktu yang cukup untuk melakukan sesi tanya jawab tanpa distraksi. Semoga sukses!
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingThree">
                            <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqThree"
                                aria-expanded="false"
                                aria-controls="faqThree">
                                <h4>
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">5</i>
                                </span>
                                Wajah saya tidak muncul di layar, apa yang harus saya lakukan?
                                </h4>
                            </a>
                            </div>
                            <div
                            id="faqThree"
                            className="panel-collapse collapse"
                            role="tabpanel"
                            aria-labelledby="headingThree">
                            <div className="panel-body">
                                <p>Anda perlu memeriksa izin akses kamera Anda. Lihat petunjuk di bawah ini:</p>
                                <p>
                                <b>Menggunakan browser Chrome</b><br/>
                                Ikuti instruksi pada <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">tautan ini</a> untuk mengubah izin akses kamera.
                                </p><br/>
                                <p>
                                <b>Menggunakan browser Mozilla Firefox</b><br/>
                                Di bagian atas jendela Firefox, klik tombol Firefox (atau menu Alat), lalu pilih Page Info. Pastikan website diizinkan untuk mengakses kamera.
                                </p><br/>
                                <p>
                                <b>Menggunakan browser Opera</b><br/>
                                Secara default, Opera akan selalu meminta Anda memberi akses kamera jika sebuah situs membutuhkannya. Jika hal ini tidak terjadi saat anda membuka website ASTRNT, klik Settings > Websites, temukan menu Camera dan klik tombol Manage Exceptions. Untuk informasi lebih lanjut <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageCamera" target="_blank">buka tautan ini</a>.
                                </p><br/>
                                <p>
                                <b>Menggunakan aplikasi IOS, ASTRNT Q&A</b><br/>
                                Buka Pengaturan > Privasi > Kamera, dan pastikan ASTRNT memiliki akses menggunakan kamera.
                                </p><br/>
                                <p>
                                <b>Menggunakan aplikasi Android, ASTRNT Q&A</b><br/>
                                Buka Pengaturan > Keamanan & Privasi > Izin Aplikasi dan pastikan ASTRNT memiliki akses menggunakan kamera.
                                </p><br/>
                            </div>
                            </div>
                        </div>
                        <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingFour">
                            <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqFour"
                                aria-expanded="false"
                                aria-controls="faqFour">
                                <h4>
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">6</i>
                                </span>
                                Suara saya tidak terekam oleh aplikasi, apa yang harus saya lakukan?
                                </h4>
                            </a>
                            </div>
                            <div
                            id="faqFour"
                            className="panel-collapse collapse"
                            role="tabpanel"
                            aria-labelledby="headingFour">
                            <div className="panel-body">
                                <p>Anda perlu memeriksa izin akses mikrofon Anda. Lihat petunjuk di bawah ini:</p>
                                <p>
                                <b>Menggunakan browser Chrome</b><br/>
                                Ikuti instruksi pada <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">tautan ini</a> untuk mengubah izin akses mikrofon.
                                </p><br/>
                                <p>
                                <b>Menggunakan browser Mozilla Firefox</b><br/>
                                Di bagian atas jendela Firefox, klik tombol Firefox (atau menu Alat), lalu pilih Page Info. Pastikan website diizinkan untuk mengakses mikrofon.
                                </p><br/>
                                <p>
                                <b>Menggunakan browser Opera</b><br/>
                                Secara default, Opera akan selalu meminta Anda memberi akses mikrofon jika sebuah situs membutuhkannya. Jika hal ini tidak terjadi saat anda membuka website ASTRNT, klik Settings > Websites, temukan menu Microphone dan klik tombol Manage Exceptions. Untuk informasi lebih lanjut <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#manageMic" target="_blank">buka tautan ini</a>.
                                </p><br/>
                                <p>
                                <b>Menggunakan aplikasi IOS, ASTRNT Q&A</b><br/>
                                Buka Pengaturan > Privasi > Mikrofon, dan pastikan ASTRNT memiliki akses menggunakan mikrofon.
                                </p><br/>
                                <p>
                                <b>Menggunakan aplikasi Android, ASTRNT Q&A</b><br/>
                                Buka Pengaturan > Keamanan & Privasi > Izin Aplikasi dan pastikan ASTRNT memiliki akses menggunakan mikrofon.
                                </p><br/>
                            </div>
                            </div>
                        </div>
                            <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingFive">
                                <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqFive"
                                aria-expanded="false"
                                aria-controls="faqFive">
                                <h4>
                                    <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">7</i>
                                    </span>
                                    Kenapa saya tidak dapat meng-install aplikasi ASTRNT Q&A?
                                </h4>
                                </a>
                            </div>
                            <div
                                id="faqFive"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                aria-labelledby="headingFive">
                                <div className="panel-body">
                                <p>Aplikasi ASTRNT Q&A tersedia di <a href="https://itunes.apple.com/us/app/astronaut-always-be-interviewing/id1098124247" target="_blank">iOS</a> (iOS 9 & ke atas, termasuk iPhone 5s) dan <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa"
                                target="_blank">Android</a> (6.0 & ke atas, dengan minimal 2GB RAM). Jika perangkat  Anda tidak memenuhi persyaratan ini, kami merekomendasikan Anda untuk melakukan sesi tanya jawab melalui <a href="http://astrnt.co" target="
                                ">website</a> kami di desktop Anda.</p>
                                </div>
                            </div>
                            </div>
                            <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingSix">
                                <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqSix"
                                aria-expanded="false"
                                aria-controls="faqSix">
                                <h4>
                                    <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">8</i>
                                    </span>
                                    Aplikasi Android ASTRNT selalu crash di perangkat saya. Apa yang harus saya lakukan?
                                </h4>
                                </a>
                            </div>
                            <div
                                id="faqSix"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                aria-labelledby="headingSix">
                                <div className="panel-body">
                                <p>
                                Meskipun sangat jarang, kami memang menghadapi masalah pada Android, terutama di perangkat Nexus. Jika Anda mengalami masalah ini pada perangkat Anda, harap <a href="mailto:help@astrnt.co">laporkan pada kami</a>. Untuk menyelesaikan sesi tanya jawab Anda, kami merekomendasikan untuk menggunakan <a href="http://astrnt.co" target="_blank">website</a> kami di desktop Anda.
                                </p>
                                </div>
                            </div>
                            </div>

                            <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingSeven">
                                <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqSeven"
                                aria-expanded="false"
                                aria-controls="faqSeven">
                                <h4>
                                    <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">9</i>
                                    </span>
                                    Di browser mana sajakah saya dapat melakukan sesi tanya jawab saya?
                                </h4>
                                </a>
                            </div>
                            <div
                                id="faqSeven"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                aria-labelledby="headingSeven">
                                <div className="panel-body">
                                <p>
                                    Kami mendukung <a href="https://www.google.com/intl/en/chrome/browser/" target="_blank">Google Chrome</a> (disarankan), <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a>, dan <a href="http://www.opera.com/" target="_blank">Opera</a>.
                                    Sayangnya saat ini kami tidak mendukung Safari, Microsoft Edge, dan Internet Explorer. Dukungan untuk ketiga browser tersebut masih dalam tahap percobaan karena dukungan WebRTC belum lama diluncurkan untuk sistem ini. Tim kami bekerja keras untuk menguji dan mengatasi bug sebelum kami meluncurkan dukungan untuk browser-browser tersebut.
                                </p>
                                </div>
                            </div>
                            </div>

                            <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingEight">
                                <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqEight"
                                aria-expanded="false"
                                aria-controls="faqEight">
                                <h4>
                                    <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">10</i>
                                    </span>
                                    Saya kehabisan waktu sebelum menyelesaikan semua pertanyaan. Apa yang harus saya lakukan?
                                </h4>
                                </a>
                            </div>
                            <div
                                id="faqEight"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                aria-labelledby="headingEight">
                                <div className="panel-body">
                                <p>
                                    Jangan khawatir, semua jawaban yang Anda kirimkan sebelum batas waktu berakhir sudah diunggah ke database kami. Namun Team ASTRNT tidak memiliki hak untuk memberi Anda kesempatan kedua. Jika Anda mau, Anda dapat menghubungi perekrut Anda secara langsung untuk meminta kesempatan kedua.
                                    Jika menurut Anda timer berakhir karena kesalahan sistem, mohon hubungi kami di <a href="mailto:help@astrnt.co">help@astrnt.co</a>.
                                </p>
                                </div>
                            </div>
                            </div>

                            <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingNine">
                                <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqNine"
                                aria-expanded="false"
                                aria-controls="faqNine">
                                <h4>
                                    <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">11</i>
                                    </span>
                                    Koneksi internet saya terputus ketika sedang melakukan sesi tanya jawab. Apa yang harus saya lakukan?
                                </h4>
                                </a>
                            </div>
                            <div
                                id="faqNine"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                aria-labelledby="headingNine">
                                <div className="panel-body">
                                <p>
                                    Jika Anda sedang di tengah sesi tanya jawab Anda, timer akan terus berjalan meskipun internet Anda terputus. Cobalah untuk segera menyambungkan kembali perangkat Anda.
                                </p>
                                <p>
                                    Jika koneksi Anda terputus setelah menyelesaikan semua pertanyaan, jangan khawatir karena jawaban Anda tidak terhapus—Anda hanya perlu menyambungkan perangkat Anda dan proses pengunggahan akan berlanjut.
                                    Sistem kami akan melakukan pengecekan terhadap kecepatan internet Anda untuk menghindari permasalahan ini.
                                </p>
                                </div>
                            </div>
                            </div>

                            <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingTen">
                                <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqTen"
                                aria-expanded="false"
                                aria-controls="faqTen">
                                <h4>
                                    <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">12</i>
                                    </span>
                                    Apakah jawaban saya sudah diterima?
                                </h4>
                                </a>
                            </div>
                            <div
                                id="faqTen"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                aria-labelledby="headingTen">
                                <div className="panel-body">
                                <p>
                                    Di akhir sesi tanya jawab Anda, Anda akan melihat halaman yang menunjukkan status pengunggahan. Setelah semua jawaban selesai diunggah, sistem kami akan mengirimkan email konfirmasi kepada Anda. Jika Anda telah menerima email konfirmasi ini, artinya jawaban Anda sudah tersimpan di sistem kami.
                                </p>
                                </div>
                            </div>
                            </div>

                            <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingEleven">
                                <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqEleven"
                                aria-expanded="false"
                                aria-controls="faqEleven">
                                <h4>
                                    <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">13</i>
                                    </span>
                                    Saya telah menyelesaikan sesi tanya jawab. Bagaimana cara mengecek hasilnya? Apa langkah selanjutnya?
                                </h4>
                                </a>
                            </div>
                            <div
                                id="faqEleven"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                aria-labelledby="headingEleven">
                                <div className="panel-body">
                                <p>
                                    Untuk informasi lebih lanjut tentang hasil dan proses rekrutmen Anda, silakan langsung hubungi perekrut Anda.
                                </p>
                                </div>
                            </div>
                            </div>

                            <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingTwelve">
                                <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqTwelve"
                                aria-expanded="false"
                                aria-controls="faqTwelve">
                                <h4>
                                    <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">14</i>
                                    </span>
                                    Saya menerima email yang meminta saya mengirimkan dokumen. Apa yang harus saya lakukan?
                                </h4>
                                </a>
                            </div>
                            <div
                                id="faqTwelve"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                aria-labelledby="headingTwelve">
                                <div className="panel-body">
                                <p>
                                    Anda menerima email ini karena perekrut menginginkan dokumen Anda. Bacalah email dengan seksama untuk mengetahui dokumen apa yang harus Anda kirimkan, kemudian balas email tersebut dengan lampiran dokumen Anda. Dokumen harus dalam format PDF.
                                </p>
                                </div>
                            </div>
                            </div>

                            <div className="panel panel-white">
                            <div className="panel-heading" role="tab" id="headingThreeCode">
                                <a
                                className="panel-title collapsed"
                                role="button" data-toggle="collapse"
                                data-parent="#accordion"
                                href="#faqThreeCode"
                                aria-expanded="false"
                                aria-controls="faqThreeCode">
                                <h4>
                                    <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="numbers fa-stack-1x fa-inverse">15</i>
                                    </span>
                                    Bekerja di ASTRNT sepertinya menyenangkan. Bagaimana saya bisa melamar?
                                </h4>
                                </a>
                            </div>
                            <div
                                id="faqThreeCode"
                                className="panel-collapse collapse"
                                role="tabpanel"
                                aria-labelledby="headingThreeCode">
                                <div className="panel-body">
                                <p>
                                    Team ASTRNT selalu senang menerima dan menonton video lamaran. Gunakan kode "<b>futurefounder</b>" pada aplikasi ASTRNT Q&A untuk melamar ke ASTRNT.
                                </p>
                                </div>
                            </div>
                            </div>

                        </div>
                        <p className="faq-note">Tidak menemukan jawaban yang Anda cari? Kirimkan pertanyaan Anda ke <a href="mailto:help@astrnt.co">help@astrnt.co.</a></p>
                        </div>
                    </div>
                
            </Fragment>
        )
    }
}

export default FaqId