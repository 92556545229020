import React from 'react'

class RenderTextareaV2 extends React.Component {
	render() {
		const { input, label,formClass, placeholder, type, meta: { touched, error } } = this.props

		return(
			<div className={`form-group ${formClass} ${touched && error && 'has-error'}`}>
		    <label>{label}</label>
		    <div>
		      <textarea
		      	{...input}
		      	placeholder={placeholder}
		      	type={type}
		      	className={`form-control ${touched && error && 'error-form'}`} />
		      {touched && error && <div className="help-block"><div dangerouslySetInnerHTML={{__html: error}}></div></div>}
		    </div>
		  </div>
		)
	}
}


export default RenderTextareaV2
