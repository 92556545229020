import React,{Fragment} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import $ from 'jquery'
import initializers from '../../dependencies/initializers'

import about1 from '../../images/about1.png'
import candidatesupport1 from '../../images/candidatesupport1.png'
import appRateIOS from '../../images/rateAppApple.png'
import appRateAndroid from '../../images/rateAppGoogle.png'
import appRateIOSMobile from '../../images/rateAppAppleMobile.png'
import appRateAndroidMobile from '../../images/rateAppGoogleMobile.png'
import weGotYourBack from '../../images/wegotyourback.png'
import imgSeamless from '../../images/seamless.png'
import quoteBlueIcon from '../../images/quote-blue.svg'
import iconFiveStar from '../../images/5-star.svg'

import FooterNew from '../partials/FooterNew'
import HeaderNewClear from '../partials/HeaderNewClear'
import SupportListNew from '../partials/SupportListNew'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import bindAll from 'lodash/bindAll'

import {getOSInfo , translate } from '../../helpers/global'
import ReactHtmlParser from 'react-html-parser'; 


const detailCard1 = [
  {
    testimoni:`HOME_PAGE_CUSTOMERS_SAY_2`,
    user:'Heidi Smith'
  },
  {
    testimoni:`HOME_PAGE_CUSTOMERS_SAY_3`,
    user:'Franklin Mal'
  },
  {
    testimoni:`HOME_PAGE_CUSTOMERS_SAY_4`,
    user:'Solihatin Azizah'
  }

]

const detailCard2 = [
  {
    testimoni:`HOME_PAGE_CUSTOMERS_SAY_5`,
    user:'Fahmi Hasya'
  },
  {
    testimoni:`HOME_PAGE_CUSTOMERS_SAY_1`,
    user:'Otniel Susanto Gunawan'
  },
  {
    testimoni:`HOME_PAGE_CUSTOMERS_SAY_6`,
    user:'Eri Montinger'
  }

]

const detailCard1Mobile = [
  {
    testimoni:`HOME_PAGE_CUSTOMERS_SAY_2`,
    user:'Heidi Smith'
  }
]

const detailCard2Mobile = [
  {
    testimoni:`HOME_PAGE_CUSTOMERS_SAY_3`,
    user:'Franklin Mal'
  }
]

let dataCard = [

]

dataCard.push(detailCard1);
dataCard.push(detailCard2);

let dataCardMobile = [

]

dataCardMobile.push(detailCard1Mobile);
dataCardMobile.push(detailCard2Mobile);

class CandidateSupport extends React.Component<Props>
{
  constructor(props)
  {
    super(props)

    this.state={
      sliderStateIndex:0,
    }

    bindAll(this, [
      'renderSlider',
      'moveSlide',
      'renderButtonDot',
      'renderContentSlider',
		])
  }

  renderContentSlider(obj, injectedClass){
    const{globalGetLanguages}=this.props
    let element = []
    console.log(obj)
    obj.map((val,index)=>{

      element.push(
        <div className={injectedClass}>
          <img src={quoteBlueIcon} />
          <p>
          {translate(globalGetLanguages,val.testimoni)}
          </p>
          <label>
            {val.user} <img src={iconFiveStar} />
          </label>
        </div>
      )
      
    })
  
    console.log(obj)
    return element
  }

  moveSlide(e,params,quote=null){
    const{sliderStateIndex} = this.state
   
      if (params) {
        this.setState({sliderStateIndex:sliderStateIndex+1})
      }else{
        this.setState({sliderStateIndex:sliderStateIndex-1})
      }

   
  }

  renderButtonDot(quote=null){
    const{sliderStateIndex} = this.state
    let buttonSlider = []
   
      dataCard.map((obj,index)=>{
        if (sliderStateIndex == index) {
          buttonSlider.push(
          <button ></button> 
  
            )
        }else{
          buttonSlider.push(
            <button
            onClick={()=>{
              this.setState({sliderStateIndex:index})
            }}
             className="disabled"></button>
            )
        }
       
      })
    
    

    return buttonSlider
  }

  renderButtonDotMobile(quote=null){
    const{sliderStateIndex} = this.state
    let buttonSlider = []
   
      dataCardMobile.map((obj,index)=>{
        if (sliderStateIndex == index) {
          buttonSlider.push(
          <button ></button> 
  
            )
        }else{
          buttonSlider.push(
            <button
            onClick={()=>{
              this.setState({sliderStateIndex:index})
            }}
             className="disabled"></button>
            )
        }
       
      })
    
    

    return buttonSlider
  }

  renderSlider(){
    const{sliderStateIndex} = this.state
    let element = []

    dataCard.map((obj,index)=>{
      console.log(index)
      let injectedClass = sliderStateIndex === index  ? "slider-item active-anim" : "slider-item absolute "
      element.push(
        <div className={`review-text-app horizontal-item--between flex-row ${sliderStateIndex === index ? 'active-anim': 'absolute'} `}>
              
                {this.renderContentSlider(obj , injectedClass)}
         
              {/* <div>
                <img src={quoteBlueIcon} />
                <p>
                  What an absolutely amazing experience and I love that one has 3 chances to retake your videos. 
                  Initially I was so nervous, however with the practice session it allows one to become more relaxed.
                </p>
                <label>
                  Heidi Smith  <img src={iconFiveStar} />
                </label>
              </div>
              <div>
                <img src={quoteBlueIcon} />
                <p>
                  Woww, I’m impressed by this technology! Using this as a candidate, it enable me to access easily the video interviewing platform. I believe this is the future of a recruitment process that will allow the candidates and recruiters easy to access and also to provide the best talents for the company. Thank you Astronaut!
                </p>
                <label>
                  Franklin Mal  <img src={iconFiveStar} />
                </label>
              </div>
              <div>
                <img src={quoteBlueIcon} />
                <p>
                  First time using this app, and surprised. This app is very helpful and effective, but  not familiar with this kind of interview. Good job and keep up the good work!
                </p>
                <label>
                  Solihatin Azizah  <img src={iconFiveStar} />
                </label>
              </div> */}
        </div>
      )
    })
    

    return element
  }

  renderSliderMobile(){
   
    const{sliderStateIndex} = this.state
    let element = []

    dataCardMobile.map((obj,index)=>{
      console.log(index)
      let injectedClass = sliderStateIndex === index  ? "slider-item active-anim" : "slider-item absolute "
      element.push(
        <div className={`review-text-app horizontal-item--between flex-row ${sliderStateIndex === index ? 'active-anim': 'absolute'} `}>
              
                {this.renderContentSlider(obj , injectedClass)}
         
              {/* <div>
                <img src={quoteBlueIcon} />
                <p>
                  What an absolutely amazing experience and I love that one has 3 chances to retake your videos. 
                  Initially I was so nervous, however with the practice session it allows one to become more relaxed.
                </p>
                <label>
                  Heidi Smith  <img src={iconFiveStar} />
                </label>
              </div>
              <div>
                <img src={quoteBlueIcon} />
                <p>
                  Woww, I’m impressed by this technology! Using this as a candidate, it enable me to access easily the video interviewing platform. I believe this is the future of a recruitment process that will allow the candidates and recruiters easy to access and also to provide the best talents for the company. Thank you Astronaut!
                </p>
                <label>
                  Franklin Mal  <img src={iconFiveStar} />
                </label>
              </div>
              <div>
                <img src={quoteBlueIcon} />
                <p>
                  First time using this app, and surprised. This app is very helpful and effective, but  not familiar with this kind of interview. Good job and keep up the good work!
                </p>
                <label>
                  Solihatin Azizah  <img src={iconFiveStar} />
                </label>
              </div> */}
        </div>
      )
    })
    

    return element
  }

  componentDidMount()
  {
    window.scrollTo(0,0)
    $('ul.price-faq li a.dropdown-new').click(function(event){
        event.preventDefault();
        
        if(!$(this).hasClass('is-on')){
            $(this).addClass('is-on').parent().find('div').slideDown('fast');
        }else{
            $(this).removeClass('is-on').parent().find('div').slideUp('fast');
        }
    });
    $('ul.support-faq li a.dropdown-new').click(function(event){
        event.preventDefault();
        
        if(!$(this).hasClass('is-on')){
            $(this).addClass('is-on').parent().find('div').slideDown('fast');
        }else{
            $(this).removeClass('is-on').parent().find('div').slideUp('fast');
        }
    });
    $('ul.flow-faq li a.dropdown-new').click(function(event){
        event.preventDefault();
        
        if(!$(this).hasClass('is-on')){
            $(this).addClass('is-on').parent().find('div').slideDown('fast');
        }else{
            $(this).removeClass('is-on').parent().find('div').slideUp('fast');
        }
    });
  }

  render()

  {
    const{globalGetLanguages}=this.props
    const { sliderStateIndex } =this.state
    const currentSize = dataCard.length

    const content1 = 
    (
      <section className="sec-con januar-div1">
        <div className='januar-div2 vertical-item--center'>
          <div>
            <label>{translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_1_TITLE')}</label>
            <p>
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_1_TEXT')}
            </p>
          </div>
        </div>
        <div className='januar-div2 right'>
          <img src={candidatesupport1} />
        </div>
      </section>
    )

    const content2 = 
    (
      <div className="sec-white">
        <section className="experience-review">
          <div>
            <label>{translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_2_TITLE')}</label>
            <span>{translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_2_TEXT')}</span>
          </div>
          <div className="desktop-hide">
            <div className="horizontal-item--center rate">
              <a className="a-transparent" href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer"><img src={appRateIOS} /></a>
              <a className="a-transparent" href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer"><img src={appRateAndroid} /></a>
            </div>
          </div>
          <div className="mobile-hide">
            <div className="horizontal-item--around rate">
              <a className="a-transparent" href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer"><img src={appRateIOSMobile} /></a>
              <a className="a-transparent" href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer"><img src={appRateAndroidMobile} /></a>
            </div>
          </div>
          <div className="arrow-slider flex-container flex-end">
            <div><button
            onClick={(e)=>{this.moveSlide(e,false)}} 
            className={`${sliderStateIndex == 0? 'disabled':''}`}
            disabled={sliderStateIndex == 0? true:false}
            ><i className="fas fa-arrow-left"></i></button></div>
            <div><button
            onClick={(e)=>{this.moveSlide(e,true)}}
            className={`${sliderStateIndex == (currentSize-1)? 'disabled':''}`}
            disabled={sliderStateIndex == (currentSize-1)? true:false}
            ><i className="fas fa-arrow-right"></i></button></div>
          </div>
         
          <Fragment>
            <div className="desktop-hide">{this.renderSlider()}</div>
            <div className="mobile-hide">{this.renderSliderMobile()}</div>
          </Fragment>
      
          <div className="horizontal-item--center">
            <div className="bottom-dots">

               <div className="desktop-hide">{this.renderButtonDot()}</div>
               <div className="mobile-hide">{this.renderButtonDotMobile()}</div>
            </div>
          </div>
          <div className="button-read-review">
            <a href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer">{translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_REVIEW_APPLE')}</a>
            <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer">{translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_REVIEW_GOOGLE')}</a>
          </div>
        </section>
      </div>
    )

    const contentent6prepare = 
    (
      <div style={{display: 'none'}}>
        <ul>
          <li>
            <i className="far fa-thumbs-up"></i>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_1')}
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_1')}
            </span>
          </li>
          <li>
          {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_2')}
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_2')}
            </span>
          </li>
          <li>
          {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_3')}
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_3')}
             </span>
          </li>
          <li>
          {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_4')}
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_4')}
             </span>
          </li>
          <li>
          {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_5')}
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_5')}
              </span>
          </li>
          <li>
          {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_LABEL_6')}
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_6')}
             </span>
          </li>
          <li>
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_FIND_MORE')} <a href="https://life.astrnt.co" target="_blank">life.astrnt.co <i className='fas fa-external-link-alt'></i></a>
            </span>
          </li>
        </ul>
      </div>
    )

    const content3testQna = 
    (
      <div style={{display: 'none'}}>
        <ul>
          <li>
            <span className="mobile-text-force">{translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_2_TEXT')}</span>
            <a className="button-test" href={`${initializers.recruiter_host}/test-qna-session`} target="_blank" rel="noopener noreferrer">{translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_2_TEST_QNA')}</a>
          </li>
        </ul>
      </div>
    )

    const content3express =
    (
      <div style={{display: 'none'}}>
        <ul>
          <li>
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_INTRO_TEXT')}
              </span>
          </li>
          <li>
          {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_LABEL_1')}
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TEXT_1')}
               </span>
          </li>
          <li>
          {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_LABEL_2')}
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TEXT_2')}
                </span>
          </li>
          <li>
          {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_LABEL_3')}
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TEXT_3')}
              </span>
          </li>
          <li>
            <span>
            {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TEXT_FIND_MORE')} <a href="https://life.astrnt.co" target="_blank">life.astrnt.co <i className='fas fa-external-link-alt'></i></a>
            </span>
          </li>
        </ul>
      </div>
    )

    const content3 =
    (
      <div id='pricing'>
        <section className='sec-con flow-container'>
          <label>{translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_TITLE')}</label>
          <p>{translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_TEXT')}</p>
          <ul>
            <li>
              <ul className='on-right'>
                <li>
                  <ul className='flow-faq'>
                    <li>
                      <a href="#" className='dropdown-new'>
                      {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_1_TITLE')}
                        <i className='fa fa-chevron-down'></i>
                      </a>
                      { contentent6prepare }
                    </li>
                    <li>
                      <a href="#" className='dropdown-new'>
                      {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_2_TITLE')}
                        <i className='fa fa-chevron-down'></i>
                      </a>
                      { content3testQna }
                    </li>
                    <li>
                      <a href="#" className='dropdown-new'>
                      {translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_3_CONTENT_3_TITLE')}
                        <i className='fa fa-chevron-down'></i>
                      </a>
                      { content3express }
                    </li>
                  </ul>
                </li>
                <li className="desktop-hide"><img src={weGotYourBack} alt="" /></li>
              </ul>
            </li>
          </ul>
        </section>
      </div>
    )

    const content4 =
    (
      <div id='pricing' className='sec-white'>
        <section>
          <div className="tit-faq">{translate(globalGetLanguages,'CANDIDATE_SUPPORT_CARD_4_TITLE')}</div>
          <ul className="support-faq">
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_1')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                  <li>
                  {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_1_TEXT')}
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_2')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                  {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_2_TEXT'))}
                 
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_3')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                  {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_3_TEXT'))}
                  {/* <li>
                    You’re all set for your Q&A session! There are two ways you can do it:
                    <ul>
                      <li>
                        <b>Doing the Q&A session on Astronaut mobile app</b>
                        Install the Astronaut Q&A mobile app (available in both <a href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer">iOS</a> and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer">Android</a>), input your code, and 
                        follow the instructions in the mobile app. 
                      </li>
                      <li>
                        <b>Doing the Q&A session on desktop browser</b>
                        Go to <a href="http://astrnt.co" target="_blank" rel="noopener noreferrer">http://astrnt.co</a>, click on "I have a code", and input your code. Then follow the instructions to complete your Q&A session.
                      </li>
                    </ul>
                    We recommend you find a quiet place and enough time to do the test without distractions. Make sure you have a stable internet connection as well. Good luck!
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_4')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_4_TEXT'))}
                  {/* <li>
                    You’re all set for your Q&A session! There are two ways you can do it:
                    <ul>
                      <li>
                        <b>Doing the Q&A session on Astronaut mobile app</b>
                        Install the Astronaut Q&A mobile app (available in both <a href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer">iOS</a> and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer">Android</a>), input your code, and 
                        follow the instructions in the mobile app. 
                      </li>
                      <li>
                        <b>Doing the Q&A session on desktop browser</b>
                        Go to <a href="http://astrnt.co" target="_blank" rel="noopener noreferrer">http://astrnt.co</a>, click on "I have a code", and input your code. Then follow the instructions to complete your Q&A session.
                      </li>
                    </ul>
                    We recommend you find a quiet place and enough time to do the test without distractions. Make sure you have a stable internet connection as well. Good luck!
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_5')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_5_TEXT'))}
                  {/* <li>
                    Please check your camera permission. Here’s how:
                    <ul>
                      <li>
                        <b>Using Chrome</b>
                        Follow the instructions in <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">this link</a> to change camera access permission.
                      </li>
                      <li>
                        <b>Using Mozilla Firefox</b>
                        {`On the menu bar, go to Tools > Page Info > Permissions, then scroll down to "Use the Camera". Make sure the website is allowed to use the camera.`}
                      </li>
                      <li>
                        <b>Using Opera</b>
                        By default, Opera will always ask you to allow a site to access your camera. If this isn’t the case, go to Settings > Websites, under Camera, and click Manage Exceptions button. For more information, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#camera" target="_blank">click here</a>.
                      </li>
                      <li>
                        <b>Using Astronaut Q&A app on iOS</b>
                        {`Go to Settings > Privacy > Camera, and make sure Astronaut has access to your camera.`}
                      </li>
                      <li>
                        <b>Using Astronaut Q&A app on Android</b>
                        {`Go to Settings > Security & Privacy > Application Permissions and make sure Astronaut has access to your camera.`}
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_6')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_6_TEXT'))}
                  {/* <li>
                    Please check your microphone permission. Here’s how:
                    <ul>
                      <li>
                        <b>Using Chrome</b>
                        Follow the instructions in <a href="https://support.google.com/chrome/answer/2693767?hl=en" target="_blank">this link</a> to change camera access permission.
                      </li>
                      <li>
                        <b>Using Mozilla Firefox</b>
                        {`On the menu bar, go to Tools > Page Info > Permissions, then scroll down to "Use the Microphone". Make sure the website is allowed to use the microphone.`}
                      </li>
                      <li>
                        <b>Using Opera</b>
                        By default, Opera will always ask you to allow a site to access your microphone. If this isn’t the case, go to Settings > Websites, under Microphone, and click Manage Exceptions button. For more information, <a href="http://help.opera.com/opera/Windows/2393/en/controlPages.html#microphone" target="_blank">click here</a>.
                      </li>
                      <li>
                        <b>Using Astronaut Q&A app on iOS</b>
                        {`Go to Settings > Privacy > Microphone, and make sure Astronaut has access to your microphone.`}
                      </li>
                      <li>
                        <b>Using Astronaut Q&A app on Android</b>
                        {`Go to Settings > Security & Privacy > Application Permissions and make sure Astronaut has access to your microphone.`}
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_7')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_7_TEXT'))}
                  {/* <li>
                    The Astronaut Q&A app is available in both <a href="https://apps.apple.com/id/app/astronaut-q-a/id1098124247" target="_blank" rel="noopener noreferrer">iOS</a> (iOS 12 & above) and <a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" target="_blank" rel="noopener noreferrer">Android</a> (6.0 & above, with minimum 2GB RAM). 
                    If your phone doesn’t meet this requirement, we recommend doing the Q&A session on your desktop browser.
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_8')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_8_TEXT'))}
                  {/* <li>
                    Sorry about that! On a very rare occasion we do encounter a problem in Android, particularly Nexus devices. 
                    If you experience this with your device, we encourage you to report it to us. To complete your Q&A session, we recommend using our <a href="http://astrnt.co" target="_blank">website</a> on your desktop.
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_9')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_9_TEXT'))}
                  {/* <li>
                    We support <a href="https://www.google.com/intl/en/chrome/browser/" target="_blank">Google Chrome</a> (recommended), <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a>, and <a href="http://www.opera.com/" target="_blank">Opera</a>. Unfortunately, we do not currently support Safari, 
                    Microsoft Edge, and Internet Explorer. These browsers are still in the experimental stage since WebRTC support 
                    was only recently released in these systems. Our team is working hard to work out the bugs before we roll out support for these browsers.
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_10')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_10_TEXT'))}
                  {/* <li>
                    Don’t worry, all the answers you sent before the time runs out are uploaded to our database. However, Team Astronaut 
                    doesn't have any rights to give you a second chance. If you wish, you can contact your recruiter directly to request 
                    a second chance. If you think the timer ran out by mistake, please contact us at <a href="mailto:help@astrnt.co" target="_blank">help@astrnt.co</a>.
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_11')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_11_TEXT'))}
                  {/* <li>
                    Unfortunately, if you are in the middle of your Q&A session, the timer will keep running even 
                    though you get disconnected. Please try to reconnect immediately.
                    If you lose connection after you have completed all the questions, no need to worry because your 
                    answers are still there—you just need to reconnect to the internet and the upload process will 
                    continue. Our system does spot checks of your speed to avoid this problem.
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_12')}
                  <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_12_TEXT'))}
                  {/* <li>
                    If you experienced any technical issues during your Q&A session, please contact <a href="mailto:help@astrnt.co" target="_blank">help@astrnt.co</a>. 
                    However, Team Astronaut doesn't have any rights to give you a second chance. Please contact your 
                    recruiter directly to request a second chance.
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_13')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_13_TEXT'))}
                  {/* <li>
                    At the end of your Q&A session, you should see an upload page that shows the status of your upload. 
                    Once all answers are uploaded, our system will send you a confirmation email. This means that your 
                    answers are safe and sound on our servers.
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_14')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_14_TEXT'))}
                  {/* <li>
                    Great job! For further information about your results and recruitment process, please contact your recruiter directly.
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_15')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_15_TEXT'))}
                  {/* <li>
                    You received this email because the recruiter would like to have your document. Please read 
                    the email carefully to know what document is requested, and reply to the email with your 
                    document attached. The document must be in PDF format.
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a className="dropdown-new" href="#">
              {translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_16')}
                <i className="fa fa-angle-down" />
              </a>
              <div style={{display: 'none'}}>
                <ul>
                {ReactHtmlParser(translate(globalGetLanguages,'CANDIDATE_SUPPORT_FAQ_16_TEXT'))}
                  {/* <li>
                    Astronaut team is always interested in receiving and watching video applications. 
                    Use the code "talentpool" on Astronaut to apply to work with us, or <a href="https://bit.ly/astrnt_talentpool" target="_blank">click here</a>.
                  </li> */}
                </ul>
              </div>
            </li>
          </ul>
        </section>
      </div>
    )

    const content5 = 
    (
      <BannerDemoNew/>
    )

    const renderComponent = 
    (
      <div id='homepage'>
        <HeaderNewClear isOnStatusLogo="is-on" />
        { content1 }
        { content2 }
        { content3 }
        { content4 }
        { content5 }
        <SupportListNew />
        <FooterNew />
      </div>
    )
    return renderComponent
  }
}

const mapStateToProps = (state) => {
  return{ 
    globalGetLanguages:state.globalGetLanguages,
  }
  
}

export default connect(mapStateToProps, null)(CandidateSupport)