// @flow

import React from 'react'
// import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import _axios from '../../dependencies/_axios'

import insightImg1 from '../../styles/img/insight/insight-1.jpg'
import insightImg2 from '../../styles/img/insight/insight-2.jpg'

type Props = {}

type State = {
	blogs: Array<Object>
};

class BlogListNew extends React.Component<Props, State> {
	constructor() {
		super()

		this.state = {
			blogs: []
		}
	}

	componentDidMount() {
		_axios({
			url: `/blogs`,
			timeout: 200000,
			method: 'get'
		})
		.then((response) => {
			this.setState({blogs: response.data})
		})
		.catch((error) => {
			console.error(error)
		})
	}

  render() {
		const { blogs } = this.state

		let cols = ''
		let blogPosts

		if (blogs.length) {
			if (blogs.length === 1) {
				cols = 'col-sm-12'
			} else if ( blogs.length === 2 ) {
				cols = 'col-sm-6'
			} else {
				cols = 'col-sm-4'
			}

			blogPosts =	blogs
				.map((item, index) => (
					<li key={index}>
		              <a href={item.blog_url}>
		                <img src={item.thumbnail} alt={item.blog_title} />
		                <ul className="ins-con">
		                  <li>{item.blog_title}</li>
		                  <li>{item.category}</li>
		                  <li>{item.blog_content.substring(0, 250)}</li>
		                </ul>
		              </a>
		            </li>
				))
			}
    return (
			<section className="sec-insight">
	          <div className="sec-tit">Latest News</div>
	          <ul className="the-insight">
	            {blogPosts}
	          </ul>
	        </section>
		);
  }
}

export default BlogListNew
