// @flow

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Loader from '../../partials/Loader'
import SweetAlert from 'sweetalert-react'

import 'sweetalert/dist/sweetalert.css'
import ReactGA from 'react-ga';

require('es6-promise').polyfill()

type Props = {
	children: Object,
};

type State = {
	displayMainLayout: boolean
};

class PublicIndex extends React.Component<Props, State> {
	constructor(props) {
		super(props)

		this.state = {
			displayMainLayout: false
		}
	}

	componentDidMount(){
		ReactGA.initialize(
			[
				{
				  trackingId: 'G-L8W9XW2QNK',
				  gaOptions: {
					name: 'tracker1',
				  }
				},
				{
				  trackingId: 'AW-10891606469',
				  gaOptions: { name: 'tracker2' }
				},
				{
					trackingId: 'G-MFHSF4814Q',
					gaOptions: { name: 'tracker3' }
				}
			  ],
		);
		
	}

	componentDidUpdate(nextProps){
		// console.log(this.props.location)
		// console.log(nextProps.location.pathname)
		if (this.props.location.pathname !== nextProps.location.pathname || this.props.location.search !== nextProps.location.search ){
			ReactGA.pageview(window.location.pathname + window.location.search,['tracker1','tracker2']);
			/* or some other black magic here */
		}
	}
	render() {
		const {
			children,
			globalSweetAlertData
		} = this.props

		return(
      <div>
      	{true ?
      		<div id="public">
		        {children}
				<SweetAlert 
				html={globalSweetAlertData.get('html')}
				{...globalSweetAlertData.toJS()} />
		      </div>
		      :
					<Loader />
      	}
      </div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		globalSweetAlertData: state.globalSweetAlertData,
		location:ownProps.location,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicIndex))
