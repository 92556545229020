import React from 'react'

class RenderTextarea extends React.Component {
	render() {
		const { input, label, type, meta: { touched, error } } = this.props

		return(
			<div className={`form-group ${touched && error && 'has-error'}`}>
		    <label>{label}</label>
		    <div>
		      <textarea
		      	{...input}
		      	placeholder={label}
		      	type={type}
		      	className="form-control" />
		      {touched && error && <div className="help-block">{error}</div>}
		    </div>
		  </div>
		)
	}
}


export default RenderTextarea
