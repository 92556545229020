import { combineReducers } from "redux";
import { reducer as form } from 'redux-form'
import { routerReducer as routing } from "react-router-redux";
import {
  usersHasErrored,
  usersSaveDataError,
  usersPagination,
  usersFilterData,
  usersHiringTeamFilterData,
  usersFormData,
  users,
  usersHiringTeam,
  user,
  userTemp,
  userIds,
  usersDropdown,
  usersNavigationData,
  userTempEmail,
  csrfToken,
} from "./users"

import {
	company,
	companies,
	companiesUiData,
	companiesIsLoading,
	companiesHasErrored,
	companiesTransaction,
	companiesTransactions,
	companiesUsageActivity,
	companiesUpdateDataError,
	companiesTransactionsPagination,
	companiesTransactionsFilterData
} from './companies'

import {
	registrationSaveDataError,
	publicCompanies,
	publicCompany,
	publicCompaniesFilterData,
	publicCompaniesPagination,
	publicJobCustom,
	publicJobDetail,
} from './public/companies'

import {
	globalClass,
	globalUiData,
	globalActiveMenu,
	globalAjaxRequest,
	globalIndicatorData,
	globalSweetAlertData,
	globalGetTimezones,
	globalGetLanguages,
} from './global'


import {
	featureListCompany,
	featureListJob
} from './feature_list'

import {
	publicFAQ
} from './public/faq'


const reduxForm = form.plugin({
  SectionModalForm: (state, action) => {
    switch (action.type) {
      case 'SECTIONS_RESET_SECTION_MODAL_FORM_SUCCESS':
        return {
          values: {},
          syncErrors: state.syncErrors,
          registeredFields: state.registeredFields
        }
      default:
        return state
    }
  }
})

export default combineReducers({
  routing,
  company,
	companies,
	companiesUiData,
	companiesIsLoading,
	companiesHasErrored,
	companiesTransaction,
	companiesTransactions,
	companiesUsageActivity,
	companiesUpdateDataError,
	companiesTransactionsPagination,
	companiesTransactionsFilterData,
  usersHasErrored,
  usersSaveDataError,
  usersPagination,
  usersFilterData,
  usersHiringTeamFilterData,
  usersFormData,
  users,
  usersHiringTeam,
  user,
  userTemp,
  userIds,
  usersDropdown,
  usersNavigationData,
  form: reduxForm,
  globalClass,
	globalUiData,
	globalActiveMenu,
	globalAjaxRequest,
	globalIndicatorData,
	globalSweetAlertData,
  featureListCompany,
	featureListJob,
	registrationSaveDataError,
	publicCompanies,
	publicCompany,
	publicCompaniesFilterData,
	publicCompaniesPagination,
	publicJobDetail,
	publicJobCustom,
	userTempEmail,
	csrfToken,
	publicFAQ,
	globalGetTimezones,
	globalGetLanguages,
});