import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import imgFeatureList1 from '../../images/public/users.png'
import imgFeatureList2 from '../../images/public/star.png'
import imgFeatureList3 from '../../images/public/badge.png'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import BlogListNew from '../partials/BlogListNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import CallToActRegister from '../partials/CallToActRegister.jsx'
import BlogList from '../partials/BlogList.jsx'

import homeIMG from '../../images/assessment.png'

import travelokaLogo from '../../images/logo/traveloka.png'
import philipLogo from '../../images/logo/philip.png'
import bcaLogo from '../../images/logo/bca.png'
import mbsbLogo from '../../images/logo/mbsb.png'
import nusLogo from '../../images/logo/nus.png'
import shopeeLogo from '../../images/logo/shopee.png'
import bniLogo from '../../images/logo/bni.png'
import infocommLogo from '../../images/logo/infocomm.png'
import darwinboxLogo from '../../images/logo/darwinbox.png'
import homecreditLogo from '../../images/logo/homecredit.png'

import teamtailorLogo from '../../images/logo/teamtailor.png'
import workableLogo from '../../images/logo/workable.png'
import pulsifiLogo from '../../images/logo/pulsifi.png'
import talentdatalabsLogo from '../../images/logo/talentdatalabs.png'
import podiumLogo from '../../images/logo/podium.png'
import greenhouseLogo from '../../images/logo/greenhouse.png'

import imgSeamless from '../../images/seamless.png'

import imgCard1 from '../../images/assess-1.png'
import imgCard2 from '../../images/assess-2.png'
import imgCard3 from '../../images/assess-3.png'

import {getOSInfo , translate } from '../../helpers/global'
import Cookies from 'js-cookie';
import { usersGetDetailAsync } from '../../actions/users.js'

class ArticlesAssessNew extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const {
      globalGetLanguages,
      user
    } = this.props
    return (
      <div id="homepage">
        <HeaderNewClear isOnStatusFor="is-on" />
        {/*        <div class="pad-top"></div>*/}
        <div className="banner home">
          <section>
            <div className="ban-text for">
              <div className="for-tag assess">{translate(globalGetLanguages,'ASSESS_COLLAR_TAG')}</div>
              <div className="big-text">{translate(globalGetLanguages,'ASSESS_COLLAR_TAG_BIG_TEXT')}</div>
              {translate(globalGetLanguages,'ASSESS_COLLAR_TAG_FILL_TEXT')}
              <div className={`ban-link`}>
                <Link to={{pathname: `/register`, query: { plan: 'es2t' }}} className="try">{translate(globalGetLanguages,'HOMEPAGE_BUTTON_GET_ASTRNT_FOR_FREE')}</Link>
                <Link to='/request-demo'>{translate(globalGetLanguages,'HOMEPAGE_BUTTON_REQUEST_DEMO')}</Link>
              </div>
              <div className={`ban-link hide`}>
                <Link to='/request-demo' className='request-demo-logged'>{translate(globalGetLanguages,'HOMEPAGE_BUTTON_REQUEST_DEMO')}</Link>
              </div>
              <span className={`span-cc desktop-hide`}>{translate(globalGetLanguages,'HOMEPAGE_LABEL_NO_CREDIT_CARD')}</span>
              <a href="#" className="vid-msg for-msg">
                <img src={homeIMG} />
              </a>
              <div className="background-color assess"></div>
            </div>
          </section>
          {/*<div className="ban-img" />*/}
        </div>
        <div className="sec-white">
          <section className="partner">
          <p>{translate(globalGetLanguages,'ACCELERATE_VIDEO')}</p>
            <div className="part-wrap">
              <ul>
                <li><a><img src={travelokaLogo} /></a></li>
                <li><a><img src={philipLogo} /></a></li>
                <li><a><img src={bcaLogo} /></a></li>
                <li><a><img src={mbsbLogo} /></a></li>
                <li><a><img src={nusLogo} /></a></li>
                <li><a><img src={shopeeLogo} /></a></li>
                <li><a><img src={bniLogo} /></a></li>
                <li><a><img src={infocommLogo} /></a></li>
                <li><a><img src={darwinboxLogo} /></a></li>
                <li><a><img src={homecreditLogo} /></a></li>
              </ul>
            </div>
          </section>
        </div>
        <div className="sec-white">
          <section className="sec-con for-astrnt">
            <ul>
              <li>
                <ul className="on-left">
                  <li>
                  {translate(globalGetLanguages,'ASSESS_COLLAR_CARD_1')}
                    <span>{translate(globalGetLanguages,'ASSESS_COLLAR_CARD_TEXT_1')}</span>
                    <Link to={{pathname: `/register`, query: { plan: 'es1' }}}>{translate(globalGetLanguages,'EXPERIENCE_OUR_PLATFORM')} <i className="fas fa-arrow-right"></i></Link>
                  </li>
                  <li>
                    <img src={imgCard1} />
                  </li>
                </ul>
              </li>
            </ul>
          </section>
        </div>
        <div className="sec-white">
          <section className="sec-con for-astrnt">
            <ul>
              <li>
                <ul className="on-right">
                  <li>
                  {translate(globalGetLanguages,'ASSESS_COLLAR_CARD_2')}
                    <span>{translate(globalGetLanguages,'ASSESS_COLLAR_CARD_TEXT_2')}</span>
                    <Link to={{pathname: `/register`, query: { plan: 'es1' }}}>{translate(globalGetLanguages,'EXPERIENCE_OUR_PLATFORM')} <i className="fas fa-arrow-right"></i></Link>
                  </li>
                  <li>
                    <img src={imgCard2} />
                  </li>
                </ul>
              </li>
            </ul>
          </section>
        </div>
         <div className="sec-white">
          <section className="sec-con for-astrnt">
            <ul>
              <li>
                <ul className="on-left">
                  <li>
                  {translate(globalGetLanguages,'ASSESS_COLLAR_CARD_3')}
                    <span>{translate(globalGetLanguages,'ASSESS_COLLAR_CARD_TEXT_3')}</span>
                    <Link to={{pathname: `/register`, query: { plan: 'es1' }}}>{translate(globalGetLanguages,'EXPERIENCE_OUR_PLATFORM')} <i className="fas fa-arrow-right"></i></Link>
                  </li>
                  <li>
                    <img src={imgCard3} />
                  </li>
                </ul>
              </li>
            </ul>
          </section>
        </div>
        <BannerDemoNew/>
        <SupportListNew />
        <FooterNew />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    globalGetLanguages:state.globalGetLanguages,
    user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    usersGetDetailAsync: (user) => dispatch(usersGetDetailAsync(user))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesAssessNew)
