import configurations from '../../helpers/misc/configurations'
import { store } from "../../index";


const validate = values => {
	const state = store.getState();

	const lang = state.globalGetLanguages;
	const errors = {}
	if (lang == 'id') {
		console.log('id')
		if (!values.email) {
			errors.email = `Email harus diisi`
		} else {
			if (!configurations.emailRegex.test(values.email)) {
				errors.email = `Harap mencantumkan Email yang sesuai.`
			}
		}
	
		if (!values.name) {
			errors.name = `Nama harus diisi`
		}
	
		if (!values.company) {
			errors.company = `Institusi harus diisi`
		} else{
			if(values.company.length > 65){
				errors.company = `Company Name cannot more than 65 characters`
			}
		}
	
		if (!values.desc) {
			errors.desc = `Kolom ini harus diisi`
		}
	
		// if (!values.objective) {
		// 	errors.objective = `This field can’t be blank`
		// }
	
		if (!values.phone) {
			errors.phone = `Harap mencantumkan nomor telepon`
		} else{
			if(values.phone.length > 13){
				// errors.phone = `Mobile Number cannot more than 13 characters`
				errors.phone = `Harap mencantumkan nomor telepon.`
			}
	
			if(values.phone.length < 10){
				// errors.phone = `Mobile Number cannot less than 10 characters`
				errors.phone = `Harap mencantumkan nomor telepon.`
			}
		}

	}else{
		console.log('en')

		if (!values.email) {
			errors.email = `Email address can’t be blank`
		} else {
			if (!configurations.emailRegex.test(values.email)) {
				errors.email = `Please enter a valid Email Address.`
			}
		}
	
		if (!values.name) {
			errors.name = `Name can’t be blank`
		}
	
		if (!values.company) {
			errors.company = `Institution can’t be blank.`
		} else{
			if(values.company.length > 65){
				errors.company = `Company Name cannot more than 65 characters`
			}
		}
	
		if (!values.desc) {
			errors.desc = `This field can’t be blank`
		}
	
		// if (!values.objective) {
		// 	errors.objective = `This field can’t be blank`
		// }
	
		if (!values.phone) {
			errors.phone = `Please enter a mobile phone`
		} else{
			if(values.phone.length > 13){
				// errors.phone = `Mobile Number cannot more than 13 characters`
				errors.phone = `Please enter a valid mobile phone.`
			}
	
			if(values.phone.length < 10){
				// errors.phone = `Mobile Number cannot less than 10 characters`
				errors.phone = `Please enter a valid mobile phone.`
			}
		}

	}
	

	return errors
}

export default validate
