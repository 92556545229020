import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import bindAll from 'lodash/bindAll'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import SupportListNew from '../partials/SupportListNew.jsx'
import BannerDemoNew from '../partials/BannerDemoNew.jsx'
import iconAssessmentPartner from '../../styles/img/icon/icon-assessment-partners.png'
import iconAssessmentTools from '../../styles/img/icon/icon-assessment-tools.png'
import iconAssyncronousVideo from '../../styles/img/icon/iconassynchronous-video.png'
import iconLibrary from '../../styles/img/icon/icon-library.png'
import iconShare from '../../styles/img/icon/icon-share.png'
import about1 from '../../styles/img/unsplash_VtKoSy_XzNU.png'
import iconSearch from '../../styles/img/icon-search.png'
import iconAvatar from '../../styles/img/avatar.png'
import imgBanner from '../../styles/img/banner/top-banner.png'
import img404 from '../../styles/img/illust-404.png'
import imgSeamless from '../../images/seamless.png'

import { getFAQ} from '../../actions/public/faq'


import {getOSInfo , translate } from '../../helpers/global'

type Props = {
	getFAQ: Function,
	usersAddNavigationPage: Function,
	usersNavigationData: Map<string, any>
};
class HowItWorksNew extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			selectedMenu: '',
			selectedPost:{
				title:'',
				description:'',
				author:'',
				humanDate:''
			}
		}

		bindAll(this, [
			'handleSelectMenu',
			'handleSearchFAQ'
		])
	}
	componentDidMount() {
		window.scrollTo(0, 0)
		this.props.getFAQ().then(()=>{
			let faq=this.props.publicFAQ.toJS();
			this.setState({selectedMenu:faq[0].id, selectedPost: faq[0]})
		});
	}

	handleSelectMenu(e, menu, selectedPost){
		e.preventDefault()
		this.setState({selectedMenu:menu, selectedPost: selectedPost})
	}

	handleSearchFAQ(e){
		e.preventDefault();
		this.props.getFAQ(e.target.value).then(()=>{
			let faq=this.props.publicFAQ.toJS();
			if(faq.size>0){
				this.setState({selectedMenu:faq[0].id, selectedPost: faq[0]})
			}else{
				this.setState({selectedMenu:'', selectedPost: {
					title:'',
					description:'',
					author:'',
					humanDate:''
				}})
			}
		});
	}

  	render() {
		const {
			publicFAQ,
			globalGetLanguages,
		} = this.props

		const { selectedPost } = this.state
		const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    	return (
			<div id="how-it-work">
				<HeaderNewClear isOnStatusWork="is-on" />
				<div className="top-banner">
					<section>
						<div className="banner-text">
						<h1>{translate(globalGetLanguages,'HOW_IT_WORKS_TITLE')}</h1>
						</div>
						<div className='banner-img'>
							<img src={imgBanner}/>
						</div>
					</section>
				</div>
				<div className="sec-white">
					<section>
						<div className="integration container">
							<div className='row'>
								<div className='col-md-4 col-xs-12'>
									<div>
										<div className='box-image'><img src={iconAssyncronousVideo}/></div>
									</div>
									<div className='box-content'>
										<div className='box-title'><h2>{translate(globalGetLanguages,'HOW_IT_WORKS_CARD_TITLE_1')}</h2></div>
										<div className='box-text'><p>{translate(globalGetLanguages,'HOW_IT_WORKS_CARD_TEXT_1')}</p></div>
									</div>
								</div>
								<div className='col-md-4 col-xs-12'>
									<div>
										<div className='box-image'><img src={iconAssessmentTools}/></div>
									</div>
									<div className='box-content'>
										<div className='box-title'><h2>{translate(globalGetLanguages,'HOW_IT_WORKS_CARD_TITLE_2')}</h2></div>
										<div className='box-text'><p>{translate(globalGetLanguages,'HOW_IT_WORKS_CARD_TEXT_2')}</p></div>
									</div>
								</div>
								<div className='col-md-4 col-xs-12'>
									<div>
										<div className='box-image'><img src={iconAssessmentPartner}/></div>
									</div>
									<div className='box-content'>
										<div className='box-title'><h2>{translate(globalGetLanguages,'HOW_IT_WORKS_CARD_TITLE_3')}</h2></div>
										<div className='box-text'><p>{translate(globalGetLanguages,'HOW_IT_WORKS_CARD_TEXT_3')} </p></div>
									</div>
								</div>
								<div className='col-md-4 col-md-offset-2 col-xs-12' >
									<div>
										<div className='box-image'><img src={iconShare}/></div>
									</div>
									<div className='box-content'>
										<div className='box-title'><h2>{translate(globalGetLanguages,'HOW_IT_WORKS_CARD_TITLE_4')}</h2></div>
										<div className='box-text'><p>{translate(globalGetLanguages,'HOW_IT_WORKS_CARD_TEXT_4')}</p></div>
									</div>
								</div>
								<div className='col-md-4 col-xs-12'>
									<div>
										<div className='box-image'><img src={iconLibrary}/></div>
									</div>
									<div className='box-content'>
										<div className='box-title'><h2>{translate(globalGetLanguages,'HOW_IT_WORKS_CARD_TITLE_5')}</h2></div>
										<div className='box-text'><p>{translate(globalGetLanguages,'HOW_IT_WORKS_CARD_TEXT_5')}</p></div>
									</div>
								</div>
							</div>
						</div>		
					</section>
				</div>
				<div className='faq sec-white'>
					<div className='container'>
						<div className='row'>
							<div><h1 className='faq-head-text'>{translate(globalGetLanguages,'HOW_IT_WORKS_CAN_WE_HELP')}</h1></div>
							<div className="col-xs-12 col-sm-12 col-md-8 col-md-offset-2">
								<div class="has-search has-feedback ">
									<input className="form-control" type="search" placeholder={translate(globalGetLanguages,'SEARCH')} onKeyUp={(e)=>this.handleSearchFAQ(e)}/>
									<span class="glyphicon glyphicon-search form-control-feedback" aria-hidden="true"></span>
								</div>
							</div>
						</div>
					</div>
					{
						publicFAQ.size < 1 && 
						<div className='container search-result'>
							<div className='row'>
								<div className='col-md-7 col-xs-12'>
									<h1>{translate(globalGetLanguages, '404_OOPS')}</h1>
									<h2>{translate(globalGetLanguages,'SEARCH_PAGE_NOT_FOUND')}</h2>
									<p>{translate(globalGetLanguages,'SEARCH_PAGE_INCORRECT')}</p>
									<div>
										<Link to="/" className='btn btn-lg btn-danger'>{translate(globalGetLanguages, '404_BUTTON_BACK')}</Link>
									</div>
								</div>
								<div className='col-md-5'>
									<img className="img img-responsive" src={img404}/>
								</div>
							</div>
						</div>
					}
					<div className='container  hidden-xs hidden-sm'>
						<div className='row'>
							<div className='col-md-4'>
								<div className="sidenav">
									<ul>
										{
											publicFAQ.map((faq, index)=>{
												return(
													<li onClick={(e)=>this.handleSelectMenu(e, faq.id, faq)} key={index} className={`${this.state.selectedMenu === faq.id ? 'selected':''}`}>{faq.title}</li>
												)
											})
										}
									</ul>
									
								</div>
							</div>
							{
								selectedPost.title !== '' &&
								<div className='col-md-8'>
									<div className='post-title'>
										<h1>{selectedPost.title}</h1>
									</div>
									<div className='post-author'>
										<div className='author-avatar'>
											<img src={iconAvatar}/>
										</div>
										<div className="author-detail">
											<span className='author-name'>{selectedPost.author}</span>
											<span className='post-date'>{selectedPost.human_date}</span>
										</div>
									</div>
									<div className='post-content'>
										{renderHTML(selectedPost.description)}
									</div>
								</div>
							}
							
						</div>
					</div>
					<div className="container visible-xs visible-sm">
						<div className="accordion" id="accordionFAQ">
							{
								publicFAQ.map((faq, index)=>{
									return(
										<div className="card" key={index}>
											<div className="card-header" id={`heading${faq.id}`} data-toggle="collapse" data-target={`#collapse${faq.id}`} aria-expanded="false" aria-controls={`collapse${faq.id}`}>
												<h2 className="mb-0">
													{faq.title}
												</h2>
											</div>

											<div id={`collapse${faq.id}`} className="collapse" aria-labelledby={`heading${faq.id}`} data-parent="#accordionFAQ">
												<div className="card-body">
													{renderHTML(faq.description)}
												</div>
											</div>
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
				
				<div id="homepage">
					<BannerDemoNew/>
				</div>
				<SupportListNew />
				<FooterNew />
			</div>
		)
  	}
}

const mapStateToProps = (state) => {
	return {
		publicFAQ: state.publicFAQ,
		globalGetLanguages: state.globalGetLanguages
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getFAQ: (params) => dispatch(getFAQ(params))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorksNew)
