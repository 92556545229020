import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'

class RenderDatePicker extends React.Component {
	render() {
		const { input, label, formClass, placeholder, type, PassReqMobile, isValidatingEmail, meta: { touched, error } } = this.props

		return(
			<div className={`form-group ${formClass} ${touched && error && 'has-error'} ${isValidatingEmail && 'has-warning'}`}>
		    <label>{label}</label>
		    {PassReqMobile}
		    <div>

                <DatePicker
                    className={`form-control ${touched && error && 'error-form'}`}
                   
                    selected={input.value || null}
                    onChange={input.onChange}
                    minDate={new Date()}
                    maxDate={null}
                    disabledKeyboardNavigation
                    placeholderText={placeholder}
                />
		      {touched && error && <div className="help-block"><div dangerouslySetInnerHTML={{__html: error}}></div></div>}
		      {isValidatingEmail && <div className="help-block">Checking your email...</div>}
		    </div>
		  </div>
		)
	}
}


export default RenderDatePicker

