import configurations from '../../../helpers/misc/configurations'

const  checkPasswordPolicy=(val)=>{
    let hasUpper = /^.*(?=.*[A-Z]).*$/.test(val)
    let hasLower = /^.*(?=.*[a-z]).*$/.test(val)
    let hasNumber = /^.*(?=.*\d).*$/.test(val)
    let hasEightChar = val.length >= 8 ? true : false 
    let hasMaxLimitChar = val.length <= 100 ? true : false 
    
    if (hasUpper && hasLower && hasNumber && hasEightChar && hasMaxLimitChar) {
		return true
	}else{
		return false
	}
}

const validate = values => {
	const errors = {}
	
	if (!values.company_name || !values.company_name.trim()) {
		errors.company_name = `Please enter a company/organization name.`
	} else{
		if(values.company_name.length > 65){
			errors.company_name = `Company Name cannot more than 65 characters`
		}
	}

	if (!values.email) {
		errors.email = `Please enter a work email.`
	} else {
		if (!configurations.emailRegex.test(values.email)) {
			errors.email = `Please enter a valid work email.`
		}
	}

	if (!values.mobile_number) {
		errors.mobile_number = `Please enter a work phone.`
	} else{
		let plus = ''
		if('0' == values.mobile_number[0]) {

		}else{
			if('+' == values.mobile_number[0]) {
				plus = '+'
			}
			const test = (plus + parseInt(values.mobile_number.replace(/\D/g, ""), 10))
			if(test != values.mobile_number) {
				errors.mobile_number = `Please enter a valid work phone.`
			}
		}
		

		if(values.mobile_number.length > 15){
			// errors.mobile_number = `Mobile Number cannot more than 13 characters`
			errors.mobile_number = `Please enter a valid work phone.`
		}

		if(values.mobile_number.length < 10){
			// errors.mobile_number = `Mobile Number cannot less than 10 characters`
			errors.mobile_number = `Please enter a valid work phone.`
		}
	}


	if (!values.fullname || !values.fullname.trim()) {
		errors.fullname = `Please enter your full name.`
	} else if(values.fullname.length > 255){
		errors.fullname = `Full Name cannot more than 255 characters`
	}



	if (!values.preferred_name || !values.preferred_name.trim()) {
		errors.preferred_name = `Please enter your preferred name.`
	} else if(values.preferred_name.length > 255){
		errors.preferred_name = `Preferred name cannot more than 255 characters`
	}


	if (!values.total_roles_description) {
		errors.total_roles_description = `Please select the number of roles`
	}

	if (!values.assist_description) {
		errors.assist_description = `Description can't be blank`
	}

	// if (!values.objective) {
	// 	errors.objective = `This field can’t be blank`
	// }

	if(!values.password){
		errors.password = `Please enter a password.`
	}else{
		if (!checkPasswordPolicy(values.password)) {
			errors.password = `Please enter a valid password.`
		}
	}

	return errors
}

export default validate
