// @flow

import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import ModalEnterCode from './ModalEnterCode'
import imgAppStore from '../../images/astronaut/appstore.png'
import imgGooglePlay from '../../images/astronaut/gplay.png'
import initializers from '../../dependencies/initializers'
type Props = {};

class Footer extends React.Component<Props> {
  handleEnterCodeClick(e: Object) {
  	e.preventDefault()

  	$('#modalEnterCode').modal('show')
  }

  render() {
    return(
			<Fragment>
				<footer className="main-footer">
					<div className="container">
						<div className="row">
							<div className="col-md-3 col-sm-4 col-xs-12">
								<div className="footer-logo-container">
									<div>
										<a className="footer-logo" href={`/`} title="ASTRNT">Astronaut</a>
									</div>
									<div className="social-media m-t-24">
										<div className="flex-container">
											<a href="https://www.facebook.com/astronaut.tech" className="icons fb" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
											<a href="https://www.youtube.com/channel/UC5jBZ2JxU5uQumssWHzyVew" className="icons youtube" target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube-play"></i></a>
											<a href="https://www.linkedin.com/company/astronaut-technologies" className="icons linkedin" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a>
											<a href="https://www.instagram.com/astronaut_tech/" className="icons instagram" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-9 col-sm-8 col-xs-12 footer-menu">
								<div className="row">
									<div className="col-md-3 col-sm-7 col-xs-12">
										<h4 className="footer-link-heading">Company</h4>
										<ul className="list-plain">
									
											<li><Link to={`/about`}>About</Link></li>
											<li><Link to={`/partners`}>Partners</Link></li>
											<li><a href="mailto:help@astrnt.co">Talk to us, anytime</a></li>
										</ul>
									</div>
									<div className="col-md-3 col-sm-5 col-xs-12">
										<h4 className="footer-link-heading">Products</h4>
										<ul className="list-plain">
											<li><a href={`${initializers.dev_host}/api-documentation`} target="_blank" rel="noopener noreferrer">API Documentation</a></li>
											<li><Link to={`/help`}>Need help?</Link></li>
											<li><Link to={`/termsofservice`}>Terms of Service</Link></li>
											<li><Link to={`/privacypolicy`}>Privacy Policy</Link></li>
										</ul>
									</div>
									<div className="col-md-3 col-sm-7 col-xs-12">
										<h4 className="footer-link-heading">For Candidates</h4>
										<ul className="list-plain">
											<li><Link to={'/faq'}>Candidate FAQ</Link></li>
										</ul>
									</div>
									<div className="col-md-3 col-sm-5 col-xs-12">
										<div className="download-box">
											<h4 className="footer-link-heading"><a onClick={this.handleEnterCodeClick}>Enter Code</a></h4>
											<h4 className="footer-link-heading m-t-20">Download our mobile app:</h4>
											<div className="footer-link-download">
												<div className="flex">
													<a href="https://itunes.apple.com/id/app/astronaut-always-be-interviewing/id1098124247?mt=8" className="m-r-8 m-t-8" target="_blank" rel="noopener noreferrer"><img src={imgAppStore} alt="App store" /></a>
													<a href="https://play.google.com/store/apps/details?id=co.astrnt.androidqa" className="m-t-8" target="_blank" rel="noopener noreferrer"><img src={imgGooglePlay} alt="Google Play" /></a>
												</div>
											</div>
										</div>
										<div className="m-t-20">
											<a href="https://aws.amazon.com/what-is-cloud-computing"><img width="150" src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" alt="Powered by AWS Cloud Computing"/></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row footer-copyright">
							<div className="col-md-12">
								<center>
									<p>Copyright &copy; 2018 All Rights Reserved by ASTRNT</p>
								</center>
							</div>
						</div>
					</div>
				</footer>
				<ModalEnterCode />
			</Fragment>
		)
  }
}

export default Footer
