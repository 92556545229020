import React from 'react'

const options = [
	{ val: "Recruitment & Assessment", title: "Recruitment & Assessment" },
	{ val: "University Admissions", title: "University Admissions" },
	{ val: "I’m An Applicant", title: "I’m An Applicant" },
]

class RenderSelectAstrntUse extends React.Component {

	render() {
		const { input, label, meta: { touched, error }, change, val } = this.props

		return(
			<div className={`form-group ${touched && error && 'has-error'}`}>
		    <label>{label}</label>
		    <div className="select-plan">
		    	<select
		    		{...input}
		    		className="form-control"
					onChange={(e) => change(e)}
					value={ val }
					>
					{ options.map((o,index) => <option key={index} value={o.val}>{o.title}</option>) }
		    	</select>
		      {touched && error && <div className="help-block">{error}</div>}
		    </div>
		  </div>
		)
	}
}


export default RenderSelectAstrntUse
