// @flow

import React from 'react'
import $ from 'jquery'
import Select from 'react-select'
import bindAll from 'lodash/bindAll'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import imgHelper from '../../images/public/helper.svg'
import imgFAQ from '../../images/public/faq.svg'
import imgHow from '../../images/public/how-to-use.svg'
import imgContact from '../../images/public/contact-us.svg'
import { Link } from 'react-router-dom'

import icon7 from '../../styles/img/icon-homepage/icon-7.svg'
import icon4 from '../../styles/img/icon-homepage/icon-4.svg'

type Props = {};

type State = {
	searchValue: string
};

class HelpPageNew extends React.Component<Props, State> {
	constructor() {
		super()

		this.state = {
			searchValue: ''
		}

		bindAll(this, [
			'handleSearchChange'
		])
	}

	componentDidMount() {
		window.scrollTo(0, 0)
		var liLenght;
	    
	    $('.search-help input').keyup(function(){
	        var searchVal = $(this).val().toLowerCase();

	        $('ul.list-help > li').addClass('hide').hide();
	        
	//        $('.misc-content').each(function(){
	            liLenght = $('ul.list-help > li').length;
	            
	            $('ul.list-help > li').each(function(index){
	                var searchText = ($(this).text().toLowerCase()).replace('arrow-mid', '');

	                if(searchText.indexOf(searchVal) != -1){
	                    $(this).removeClass('hide').show();
	                }
	                
	                if(index+1 == liLenght){
	//                    console.log($(this).parent().find('> li').length + ' ' + $(this).parent().find('> li.hide').length);
	                    if($(this).parent().find('> li').length == $(this).parent().find('> li.hide').length){
	                        $(this).parent().parent().find('.help-empty').slideDown('fast').find('strong').text(searchVal);
	                    }else{
	                        $(this).parent().parent().find('.help-empty').slideUp('fast');
	                    }
	                }
	            });
	//        });
	    });
	    
	//    var thisTab,
	//        $thisDiv;
	//    
	//    $('.sec-faq ul li').on('click', 'a', function(event){
	//        event.preventDefault();
	//        
	//        if(!$(this).hasClass('is-on')){
	//           thisTab = $(this).attr('class').split('-')[1];
	//        
	//            $('.misc-content:visible').slideUp('fast');
	//            $('.misc-content#sec-' + thisTab).slideDown('fast');
	//
	//            $('.sec-faq ul li a.is-on').removeClass('is-on');
	//            $(this).addClass('is-on');
	//        }
	//    });
	    
	    $('ul.list-help li a.dropdown-new').click(function(event){
	        event.preventDefault();
	        
	        if(!$(this).hasClass('is-on')){
	            $(this).addClass('is-on').parent().find('div').slideDown('fast');
	        }else{
	            $(this).removeClass('is-on').parent().find('div').slideUp('fast');
	        }
	    });
	}

	handleSearchChange(e: Object) {
		this.setState({searchValue: e.value})

		window.location.href = `/help/faq#${e.value}`
	}

  render() {
  	const faqOptions: Array<Object> = [
			{value: 'faq-what-is-astronaut', label: 'What is ASTRNT (and what is it not?)'},
			{value: 'faq-interview-feature', label: 'Does ASTRNT have a live video interview feature?'},
			{value: 'faq-why-video', label: 'Why video?'},
			{value: 'faq-anti-fraud', label: 'How to overcome candidates for cheating?'},
			{value: 'faq-how-to-answer', label: 'How do candidates answer the questions?'},
			{value: 'faq-likes-astronaut', label: 'Do candidates like the experience of using ASTRNT?'},
			{value: 'faq-screening-time', label: 'Can I use ASTRNT for screening 1000s candidates in only a few days?'},
			{value: 'faq-internet-connection', label: 'What if the internet connection is not very good? What is the candidate participation rate? '},
			{value: 'faq-contact-us', label: 'If candidates have questions about ASTRNT, who should they contact?'},
			{value: 'htu-getting-started', label: 'Getting started'},
			{value: 'htu-create-jobs', label: 'Create jobs'},
			{value: 'htu-candidates', label: 'Candidates'},
			{value: 'htu-question-page', label: 'Question Page'},
			{value: 'htu-sending-invites', label: 'Sending Invites'},
			{value: 'htu-filtering-candidate', label: 'Filtering candidate'},
			{value: 'htu-reports', label: 'Reports'},
			{value: 'htu-hiring-team-collaboration', label: 'Hiring team collaboration'}
		]

    return (
			<div id="help">
	        <HeaderNewClear />
	        <div className="banner">
	          <section>
	            <div className="ban-text">
	              <div className="big-text">We’re here to help, <em>always</em>.</div>
	              <div className="search-help">
	                <input type="text" placeholder="Type keywords to get our help" />
	                <button><i className="fa fa-search" /></button>
	              </div>
	              Feel free to poke around these help topics to find just what you're looking for.
	            </div>
	          </section>
	          <div className="ban-img" />
	        </div>
	        <section>
	          <div className="help-empty" style={{display: 'none'}}>
	            <span><img src={icon7} /></span>
	            Sorry we've not found result for keywords "<strong>123</strong>".
	          </div>
	          <ul className="list-help">
	            <li>
	              <a className="dropdown-new" href="#">
	                Getting Started
	                <i className="fa fa-chevron-circle-down" />
	              </a>
	              <div style={{display: 'none'}}>
	                <ul>
	                  <li>
	                    <strong>Creating an account</strong><br />
	                    Set up your account by contact us on support@astrnt.co or use our self sign up feature by click this link <a href="https://app.astrnt.co/auth/login" target="_blank" rel="noopener noreferrer">https://app.astrnt.co/auth/login</a>.
	                  </li>
	                  <li>
	                    <strong>Updating company profile</strong><br />
	                    Complete the important information of your company that candidate will see. Company name, website, about company and company logo.
	                  </li>
	                </ul>
	              </div>
	            </li>
	            <li>
	              <a className="dropdown-new" href="#">
	                Create Jobs
	                <i className="fa fa-chevron-circle-down" />
	              </a>
	              <div style={{display: 'none'}}>
	                <ul>
	                  <li>
	                    <strong>Job Title</strong><br />
	                    Fill in the field of the position you are looking for.
	                  </li>
	                  <li>
	                    <strong>Candidate Language Interface</strong><br />
	                    Choose the language the candidate will see on the Invitation, ASTRNT App or Web Browser.
	                  </li>
	                  <li>
	                    <strong>Required Document</strong><br />
	                    Choose the document you wish the candidate to submit in PDF format. Click Mandatory, click the Document type, the candidate will receipt daily email reminder until the candidate submit the document.
	                  </li>
	                  <li>
	                    <strong>Screening vs sourcing</strong>
	                    <ul>
	                      <li>Change recruitment type to screening if you already have pool of candidate data.</li>
	                      <li>Change recruitment type to sourcing if you aim to create tools where your candidate can directly apply to the job landing page that you can customize. Set up your Q&amp;A Session, and implement the URL link to your home page, web career or other pages you need.</li>
	                    </ul>
	                  </li>
	                  <li>
	                    <strong>Job landing page</strong><br />
	                    Create Job landing page where candidate can directly applied. Adding new field of candidate info that you need, and set the deadline. Contact us if you keen for full brand Job landing page.
	                  </li>
	                  <li>
	                    <strong>Landing page video</strong><br />
	                    Attract those talent! Time for you to show your candidate about your company, the culture, and about the role you are looking for.
	                  </li>
	                  <li>
	                    <strong>Select Job Type</strong><br />
	                    Ie Full Time, Part time, Internship, Contract or Fellowship.
	                  </li>
	                  <li>
	                    <strong>Select Job Industry</strong><br />
	                    Select industry field of your company.
	                  </li>
	                  <li>
	                    <strong>Location</strong><br />
	                    Fill in the location where this position is placed.
	                  </li>
	                  <li>
	                    <strong>About the job</strong><br />
	                    Fill in the Job description that describes the general tasks, or other related duties of the position that the candidate will see in Job Landing page.
	                  </li>
	                  <li>
	                    <strong>Responsibilities</strong><br />
	                    Fill in the Job responsibilities of the positions that the candidate will see in Job Landing page. It may specify the functionary to whom the position reports, specifications such as the qualifications or skills needed.
	                  </li>
	                  <li>
	                    <strong>Job Requirements</strong><br />
	                    Fill in the specific skills, types and amounts of work experience, personal qualities, educational credentials, professional certifications, or areas of knowledge.
	                  </li>
	                  <li>
	                    <strong>Save Change Button</strong><br />
	                    Click save change button to continue the next step.
	                  </li>
	                  <li>
	                    <strong>Back Button</strong><br />
	                    Click Back button to go back to the Job list page.
	                  </li>
	                </ul>
	              </div>
	            </li>
	            <li>
	              <a className="dropdown-new" href="#">
	                Candidates
	                <i className="fa fa-chevron-circle-down" />
	              </a>
	              <div style={{display: 'none'}}>
	                <ul>
	                  <li>
	                    <strong>Add manually</strong><br />
	                    Click 'Add Candidate' button and complete the field. All you need is their Name, Preferred Name Email address and Location.
	                  </li>
	                  <li>
	                    <strong>Import from file</strong><br />
	                    If you’re adding more than 5 candidates we suggest you use the Bulk upload option. Download the template Form we provide for you and fill the the from of candidate data.
	                  </li>
	                  <li>
	                    <strong>Send Question</strong><br />
	                    Click select all or click the small box on the top right corner, click send question.
	                  </li>
	                  <li>
	                    <strong>Candidates Preview</strong><br />
	                    Make sure the list of candidate that you are wish to sent the question, click next.
	                  </li>
	                </ul>
	              </div>
	            </li>
	            <li>
	              <a className="dropdown-new" href="#">
	                Question Page
	                <i className="fa fa-chevron-circle-down" />
	              </a>
	              <div style={{display: 'none'}}>
	                <ul>
	                  <li>
	                    <strong>Choosing questions/create Q&amp;A sessions</strong><br />
	                    Different question formats and test structures you can properly assess thousands of candidates in only a few days.
	                  </li>
	                  <li>
	                    <strong>Section</strong><br />
	                    Create unique tests that combine the different formats. For example, you can create a structured test with 2 video answers, 10 multiple-choice questions.
	                  </li>
	                  <li>
	                    <strong>Add Section</strong><br />
	                    Click Add Section, named your section, choose the time duration of your section test, add image if you wish the section needed images as part of the instruction of the test, add instruction for the test.
	                    <ul>
	                      <li>
	                        <strong>Choose Video Format to see the candidate’s facial expressions, and hear the clarity of their language skills</strong><br />
	                        Click add question, Add questions and you can create the pressure of a real interview with “1 attempt only” to ensure the candidates give a spontaneous response.
	                      </li>
	                      <li>
	                        <strong>Choose MCQ Format Create your own basic culture and profiling tests – or test for any purpose</strong><br />
	                        Add questions, set up the test timer, choose “single option for test” if there is only one correct answer, or choose “multiple option for test” if there is more than two correct answer.
	                      </li>
	                      <li>Randomize question.</li>
	                    </ul>
	                  </li>
	                </ul>
	              </div>
	            </li>
	            <li>
	              <a className="dropdown-new" href="#">
	                Sending Invites
	                <i className="fa fa-chevron-circle-down" />
	              </a>
	              <div style={{display: 'none'}}>
	                <ul>
	                  <li>
	                    <strong>Invitation</strong><br />
	                    This is email invitation that your candidate will received. Using the default template you can set the email subject, the sender and the deadline and add you personal message to the candidate.
	                  </li>
	                  <li>
	                    <strong>Custom HTML templates</strong><br />
	                    Upload HTML templates if you wish to have fully branded invitation.
	                  </li>
	                  <li>
	                    <strong>Adjust the subject and the sender</strong><br />
	                    Click the field on email subject and sender that is suitable for you company.
	                  </li>
	                  <li>
	                    <strong>Deadline</strong><br />
	                    Choose the date of the deadline your candidate can answer, in every 24 hours your candidate will receipt email notification ask them to do the test or your candidate can click ‘not interested’ button on the invitation and we ask why.
	                  </li>
	                  <li>
	                    <strong>Sent invitation</strong><br />
	                    Click Sent invitation button, your candidate will received the email in 45 - 60 sec.
	                  </li>
	                </ul>
	              </div>
	            </li>
	            <li>
	              <a className="dropdown-new" href="#">
	                Filtering candidate
	                <i className="fa fa-chevron-circle-down" />
	              </a>
	              <div style={{display: 'none'}}>
	                <ul>
	                  <li>
	                    <strong>Viewing videos</strong><br />
	                    Play the video to see the candidate answer, see the candidate expression, listen the audio of your candidate answer.
	                  </li>
	                  <li>
	                    <strong>Viewing answers</strong><br />
	                    Make fast collaborative screening decisions by using star-ratings, leave a comment for your colleagues to see and respond or thumbs up or down.
	                  </li>
	                  <li>
	                    <strong>Candidate’s documents</strong><br />
	                    Click document button to see the document that candidate sent when you Set “Require Document” to mandatory in creating the job.
	                  </li>
	                  <li>
	                    <strong>Commenting</strong><br />
	                    In candidate page, click your candidate name to view their answer, leave a comment for your colleagues to see.
	                  </li>
	                  <li>
	                    <strong>Mentioning others</strong><br />
	                    Type your comment and tag your team member so they can get email notification about your comment.
	                  </li>
	                  <li>
	                    <strong>Rating</strong><br />
	                    Make fast collaborative screening decisions by using star-ratings.
	                  </li>
	                  <li>
	                    <strong>Thumbs up/down</strong><br />
	                    Respond to thumbs up/down for overall candidate answer.
	                  </li>
	                  <li>
	                    <strong>Recruitment stages</strong><br />
	                    Organize your candidate by move candidates to recruitment stages (sourced, applied, shortlisted, offer or disqualified), click the small the box on the top right corner, click the move to button and choose the stages you wish.
	                  </li>
	                </ul>
	              </div>
	            </li>
	            <li>
	              <a className="dropdown-new" href="#">
	                Reports
	                <i className="fa fa-chevron-circle-down" />
	              </a>
	              <div style={{display: 'none'}}>
	                <ul>
	                  <li>
	                    <strong>Available report types</strong>
	                  </li>
	                  <li>
	                    <strong>How to create</strong>
	                  </li>
	                  <li>
	                    <strong>Sending reports</strong>
	                  </li>
	                </ul>
	              </div>
	            </li>
	            <li>
	              <a className="dropdown-new" href="#">
	                Hiring Team Collaboration
	                <i className="fa fa-chevron-circle-down" />
	              </a>
	              <div style={{display: 'none'}}>
	                <ul>
	                  <li>
	                    <strong>Add, edit, delete</strong><br />
	                    Make collaborative screening decisions with your hiring team member, add their email address, edit the notification that team member will receipt, and if you wish you can remove the team member from certain on job.
	                  </li>
	                  <li>
	                    <strong>Roles &amp; permissions</strong><br />
	                    Select the roles of your recruiter team in the job you wish them to involve to “Recruiter” so they can add candidate, sent questions and view candidate answers.<br />
	                    Select the roles to “Guest” if you wish to make fast decisions from user or Top level manager where they can view only of candidate answer.
	                  </li>
	                  <li>
	                    <strong>Company Account</strong><br />
	                    Quota<br />
	                    API key<br />
	                    Branding <br />
	                    Suspended account
	                  </li>
	                </ul>
	              </div>
	            </li>
	          </ul>
	        </section>
	        <section className="cant">
	          Can’t find the answer you’re looking for? Please email us at <a href="mailto:help@astrnt.co">help@astrnt.co</a>.
	        </section>
	        <div className="sec-white">
	          <section>
	            <ul className="the-other">
	              <li>
	                <img src={icon7} />
	                <span>Frequently Asked Questions</span>
	                Find the answers to the most commonly asked questions about our recruiter experience.
	                <Link to={'/faq'}>Learn More <i className="fas fa-arrow-right" /></Link>
	              </li>
	              <li>
	                <img src={icon4} />
	                <span>Contact us</span>
	                if you dont have what you need? Contact us to: 
	                <a href="mailto:support@astrnt.co"><i className="fas fa-envelope" /> support@astrnt.co</a>
	              </li>
	            </ul>
	          </section>
	        </div>
	        <FooterNew />
	      </div>
		)
  }
}

export default HelpPageNew
