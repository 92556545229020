import React from 'react'
import bindAll from 'lodash/bindAll'
import Select, { components, OptionProps } from "react-select";


class RenderReactSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		bindAll(this, [
		])
	}

	render() {
		const { input, label, meta: { touched, error }, change, val, options, disabled } = this.props
		const Option = (props) => {
			return (
			  <div>
				<components.Option {...props}>
				<label className="inline normal checkbox-container">
					<input
						type="checkbox"
						checked={props.isSelected}
						class="checkbox"
					/>{props.label}<span className="checkmark"></span>
				  	<div><p>{props.data.description}</p></div>
				  </label>
				</components.Option>
			  </div>
			);
		};

		return(
			<div className={`form-group ${touched && error && 'has-error'}`}>
				<label>{label}</label>
				<div className="select-plan">
					{
						val.length > 0 ? <Select
						value={val}
						components={{ Option }}
						isMulti
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						isClearable={false}
						isSearchable={false}
						options={options}
						classNamePrefix="select"
						onChange={change}
						isDisabled={disabled}
					/>:
					<Select
						components={{ Option }}
						isMulti
						closeMenuOnSelect={false}
						hideSelectedOptions={false}
						isClearable={false}
						isSearchable={false}
						options={options}
						classNamePrefix="select"
						onChange={change}
						isDisabled={disabled}
					/>
					}
					
				</div>
		  	</div>
		)
	}
}


export default RenderReactSelect
