// @flow

import React from 'react'
// import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import _axios from '../../dependencies/_axios'

type Props = {}

type State = {
	blogs: Array<Object>
};

class BlogList extends React.Component<Props, State> {
	constructor() {
		super()

		this.state = {
			blogs: []
		}
	}

	componentDidMount() {
		_axios({
			url: `/blogs`,
			timeout: 200000,
			method: 'get'
		})
		.then((response) => {
			this.setState({blogs: response.data})
		})
		.catch((error) => {
			console.error(error)
		})
	}

  render() {
		const { blogs } = this.state

		let cols = ''
		let blogPosts

		if (blogs.length) {
			if (blogs.length === 1) {
				cols = 'col-sm-12'
			} else if ( blogs.length === 2 ) {
				cols = 'col-sm-6'
			} else {
				cols = 'col-sm-4'
			}

			blogPosts =	blogs
				.map((item, index) => (
					<div className={cols} key={index}>
						<div className="blog-box">
							<div className="blog-image">
								<a href={item.blog_url}>
									<img src={item.thumbnail} alt={item.blog_title} />
								</a>
							</div>
							<div className="blog-text">
								<div className="blog-category">{item.category}</div>
								<a href={item.blog_url}><div className="blog-title">{item.blog_title}</div></a>
								<a href={item.blog_url}>{item.blog_content.substring(0, 250)}</a>
							</div>
						</div>
					</div>
				))
			}
    return (
			<section className="blog-wrapper">
				<div className="container">
					<div className="row">
						{!isEmpty(blogs)? <h2 className="heading1 center m-b-24">Latest news</h2> : '' }
						{blogPosts}
					</div>
				</div>
			</section>
		);
  }
}

export default BlogList
