import _axios from '../dependencies/_axios'


export function companiesSwitchApi(id){
    return _axios({
    method: 'post',
    url: `/companies/${id}/switch`,
    timeout: 20000
  })
}

export function publicCompaniesFetchDataApi(params){
  return _axios({
      url: '/public/companies/filter',
			timeout: 20000,
			method: 'get',
			responseType: 'json',
			params
  })
}