// @flow
import React,{Fragment} from 'react'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import HeaderNewClear from '../partials/HeaderNewClear.jsx'
import FooterNew from '../partials/FooterNew.jsx'
import $ from 'jquery'
import { Prompt } from 'react-router-dom'

import bindAll from 'lodash/bindAll'
import UAParser from 'ua-parser-js'
import _axios from '../../dependencies/_axios'
import initializers from '../../dependencies/initializers'
import {getOSInfo} from '../../helpers/global'

import AstrntFavicon from '../../styles/img/favicon.svg'

class EnterCodePage extends React.Component<Props>{
    constructor(props) {
      super(props)

      this.state = {
        isBlocking: false
      }

      bindAll(this, [
        'removeCharacter',
        'handleCloseClick',
        'handleCheckCodeClick',
        'handleChange',
        'onUnload'
      ])
    }

    handleChange(e) {
      this.setState({isBlocking: e.target.value!==''})
    }

    onUnload(e) {
      e.preventDefault()
      e.returnValue = ''
    }

    componentDidUpdate() {
      const { isBlocking } = this.state
      if(isBlocking) {
        window.addEventListener('beforeunload', this.onUnload)
      } else {
        window.removeEventListener('beforeunload', this.onUnload)
      }
    }

    componentWillUnmount() {
      window.removeEventListener('beforeunload', this.onUnload)
    }

    handleCheckCodeClick(e) {
      console.log(getOSInfo())
      e.preventDefault()

      const enterCode = $('[name="code"]').val()

      window.removeEventListener('beforeunload', this.onUnload)

      $('.wrapper-entercode').hide()
      $('.loader-submit').show()

      _axios({
        url: `/api/popupcode`,
        timeout: 200000,
        method: 'post',
        crossdomain:true,
        data:{code:enterCode}
      })
      .then((data) => {
          const interviewCode = data.data.interview_code
          const interviewUrl = data.data.url
          const interview = data.data.interview
          const userAgent = navigator.userAgent || navigator.vendor
          const parser = new UAParser()
          const isChrome = /chrome/i.test(parser.getBrowser().name)
          const isFirefox = /firefox/i.test(parser.getBrowser().name)
          const isSafari = /safari/i.test(parser.getBrowser().name)
          const device_type = data.data.device_type
          if (device_type == 3) {
            return window.location = interviewUrl
          }
          if (/android/i.test(userAgent)) {

            if (isChrome || isFirefox) {

              if (!interview) {
                window.location = 'astronaut://astrnt.co/code/' + interviewCode

                setTimeout(function() {
                  window.location = 'https://play.google.com/store/apps/details?id=co.astrnt.androidqa'
                }, 5000)
              } else {
                console.log(interviewCode)
                window.location = 'astronaut://astrnt.co/code/' + interviewCode

                setTimeout(function() {
                  window.location = 'https://play.google.com/store/apps/details?id=co.astrnt.androidqa'
                }, 5000)
              }

            } else {

              window.location = 'astronaut://astrnt.co/code/' + interviewCode
            }
          } else if (/ipad|iphone|ipod/i.test(userAgent) && !window.MSStream) {

            if (isChrome || isSafari || isFirefox) {

              if (!interview) {
                  window.location = 'astronaut://entercode?code=' + interviewCode

                  setTimeout(function() {
                  window.location = 'https://itunes.apple.com/id/app/astronaut-always-be-interviewing/id1098124247?mt=8'
                }, 5000)
              } else {
                window.location = 'astronaut://entercode?code=' + interviewCode

                setTimeout(function() {
                  window.location = 'https://itunes.apple.com/id/app/astronaut-always-be-interviewing/id1098124247?mt=8'
                }, 5000)
              }

            }
          }  else if(getOSInfo().name == 'Android'){
            //check desktop mode android
            $('.loader-submit').hide()
            this.setState({isMobile:true})
              window.location = 'astronaut://astrnt.co/code/' + interviewCode


                    window.location = 'https://play.google.com/store/apps/details?id=co.astrnt.androidqa'


          }else if(getOSInfo().name == 'iOS'){
            //check desktop mode android
            $('.loader-submit').hide()
            this.setState({isMobile:true})
              window.location = 'astronaut://astrnt.co/code/' + interviewCode


                    window.location = 'https://itunes.apple.com/id/app/astronaut-always-be-interviewing/id1098124247?mt=8'


          } else {
            window.location = interviewUrl

          }
          // console.log(initializers.api_host+interviewUrl)
      })
      .catch((error) => {

          $('.wrapper-entercode').show()
          $('.loader-submit').hide()

          const errors = error.response.data
          if (errors.url) {
            $('#errEnterCode').html(errors.url)
          } else {
            $("#errEnterCode").html("")
          }
      })

    }

    handleCloseClick(e) {
      e.preventDefault()

      if ($('[name="code"]').val()) {
        $('[name="code"]').val('')
        $("#errEnterCode").html("")
      }
    }

    removeCharacter(e) {
      const enterCode = e.target.value.replace(/[^a-zA-Z0-9]/g, '')

      if ($('[name="code"]').val()) {
        $('[name="code"]').val(enterCode)
      }
    }

    componentDidMount() {
	    window.scrollTo(0, 0)
      $('.loader-submit').hide()
    }

    render(){
        return(
            <div id="code">
            <HeaderNewClear isOnStatusCode="is-on" />
            <div className="pad-top" />
            <section className="sec-code">
              <div className="box-code">
                <div className="tit-code">Enter Your <img src={AstrntFavicon} /> Code</div>
                  <Prompt
                    when={this.state.isBlocking}
                    message={location => `Data is not saved! Are you sure you want to leave?`}
                  />
                <ul className="the-form">
                  <li>
                    <input
                        type="text"
                        name="code"
                        onBlur={this.removeCharacter}
                        onChange={this.handleChange}
                        placeholder="Enter Code" />
                      <div className="center error-code" htmlFor="code" id="errEnterCode"></div>
                  </li>
                  <li>
                    <button type="button" onClick={this.handleCheckCodeClick}><i className="fa fa-spin fa-spinner loader-submit"></i>Submit</button>
                  </li>
                </ul>
              </div>
            </section>
            <FooterNew />
          </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
	return {

	}
}


export default connect(mapStateToProps, null)(EnterCodePage)