// @flow

import React from 'react'
import Select from 'react-select'
import bindAll from 'lodash/bindAll'
import Header from '../partials/Header.jsx'
import Footer from '../partials/Footer.jsx'
import $ from 'jquery'

type Props = {};

type State = {
	searchValue: string
};

class FaqRecruiter extends React.Component<Props, State> {
	constructor() {
		super()

		this.state = {
			searchValue: ''
		}

		bindAll(this, [
			'handleSearchChange',
		])
	}

	componentDidMount() {
		window.scrollTo(0, 0)

		if (window.location.hash.startsWith('#faq')) {
			$('#faq').collapse('show')
			$('#htu').collapse('hide')
		} else if (window.location.hash.startsWith('#htu')) {
			$('#htu').collapse('show')
			$('#faq').collapse('hide')
		}

		$('html, body')
		var htmlBody = 	$('html, body')
		console.log(htmlBody.length)
		if (htmlBody.length) {
			if (window.location.hash) {
				htmlBody.animate(
				{scrollTop: $(window.location.hash).offset().top},
				'slow'
			)
			}
		
		}
		
	}

	handleSearchChange(e: Object) {
		this.setState({searchValue: e.value})

		window.location.href = `/help/faq#${e.value}`
	}

  render() {
		const faqOptions: Array<Object> = [
			{value: 'faq-what-is-astronaut', label: 'What is ASTRNT (and what is it not?)'},
			{value: 'faq-interview-feature', label: 'Does ASTRNT have a live video interview feature?'},
			{value: 'faq-why-video', label: 'Why video?'},
			{value: 'faq-anti-fraud', label: 'How to overcome candidates for cheating?'},
			{value: 'faq-how-to-answer', label: 'How do candidates answer the questions?'},
			{value: 'faq-likes-astronaut', label: 'Do candidates like the experience of using ASTRNT?'},
			{value: 'faq-screening-time', label: 'Can I use ASTRNT for screening 1000s candidates in only a few days?'},
			{value: 'faq-internet-connection', label: 'What if the internet connection is not very good? What is the candidate participation rate? '},
			{value: 'faq-contact-us', label: 'If candidates have questions about ASTRNT, who should they contact?'},
			{value: 'htu-getting-started', label: 'Getting started'},
			{value: 'htu-create-jobs', label: 'Create jobs'},
			{value: 'htu-candidates', label: 'Candidates'},
			{value: 'htu-question-page', label: 'Question Page'},
			{value: 'htu-sending-invites', label: 'Sending Invites'},
			{value: 'htu-filtering-candidate', label: 'Filtering candidate'},
			{value: 'htu-reports', label: 'Reports'},
			{value: 'htu-hiring-team-collaboration', label: 'Hiring team collaboration'}
		]

    return (
			<main className="help-page">
				<section>
					<Header isNavbarFixed={false} />
					<div className="subheader">
	          <div className="container">
	            <div className="horizontal-item--between">
	              <div>
	              </div>
	              <div className="search">
									<Select
										className="search-form hide-options"
										id="search-username"
										value={this.state.searchValue}
										onChange={this.handleSearchChange}
										options={faqOptions}
										placeholder="How can we help?"
										clearable={false}
										isSearchable={true} />
	              </div>
	            </div>
	          </div>
	        </div>
				</section>
				<section className="main-content">
	        <div className="container">
	          <div className="row">
	            <div className="col-md-4 sticky">
	              <div className="well well-lg navbar-aside">
	                <div className="panel-group" id="help-center">
	                  <ul className="panel list-plain p-l-16 m-b-0" role="tablist">
	                    <li>
	                      <a className="bold" data-toggle="collapse" data-parent="#help-center" href="#faq" role="tab">Frequently Asked Question</a>
	                    </li>
	                    <li id="faq" className="panel-collapse collapse in">
	                      <ul className="list-plain">
	                        <li><a href="#faq-what-is-astronaut">What is ASTRNT (and what is it not?)</a></li>
	                        <li><a href="#faq-interview-feature">Does ASTRNT have a live video interview feature?</a></li>
	                        <li><a href="#faq-why-video">Why video?</a></li>
	                        <li><a href="#faq-anti-fraud">How to overcome candidates for cheating?</a></li>
	                        <li><a href="#faq-how-to-answer">How do candidates answer the questions?</a></li>
	                        <li><a href="#faq-likes-astronaut">Do candidates like the experience of using ASTRNT?</a></li>
	                        <li><a href="#faq-screening-time">Can I use ASTRNT for screening 1000s candidates in only a few days?</a></li>
	                        <li><a href="#faq-internet-connection">What if the internet connection is not very good? What is the candidate participation rate? </a></li>
	                        <li><a href="#faq-participation-rate">What is the candidate participation rate? </a></li>
	                        <li><a href="#faq-contact-us">If candidates have questions about ASTRNT, who should they contact?</a></li>
	                      </ul>
	                    </li>
	                  </ul>
	                  <ul className="panel list-plain m-b-0">
	                    <li>
	                      <a className="bold" data-toggle="collapse" data-parent="#help-center" href="#how-to-use" role="tab">How to use product</a>
	                    </li>
	                    <li id="how-to-use" className="panel-collapse collapse">
	                      <ul className="list-plain">
	                        <li><a href="#htu-getting-started">Getting started</a></li>
	                        <li><a href="#htu-create-jobs">Create jobs</a></li>
	                        <li><a href="#htu-candidates">Candidates</a></li>
	                        <li><a href="#htu-question-page">Question Page</a></li>
	                        <li><a href="#htu-sending-invites">Sending Invites</a></li>
	                        <li><a href="#htu-filtering-candidate">Filtering candidate</a></li>
	                        <li><a href="#htu-reports">Reports</a></li>
	                        <li><a href="#htu-hiring-team-collaboration">Hiring team collaboration</a></li>
	                      </ul>
	                    </li>
	                  </ul>
	                </div>
	              </div>
	            </div>
	            <div className="col-md-8" id="faq-content">
	              <div className="media">
	                <div className="media-content">
	                  <h2 className="heading2 normal m-b-24">Frequently Asked Questions</h2>
	                  Please know that we’re here to help, always. You can email us if you have questions, or you can also see if your question (and answer) is below! Take a look below for answers to the most commonly asked questions.
	                </div>
	              </div>
	              <div className="media" id="faq-what-is-astronaut">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">What is ASTRNT? (And what is it not?)</h3>
	                  ASTRNT is Q&A platform for fast, effective talent screening with video format, and MCQ (multiple choice question) format. ASTRNT in not a Jobs board, and not ATS, but we have API that can integrate with other systems like these. Also, ASTRNT is not a recruitment agency but recruitment agencies can use ASTRNT.
	                </div>
	              </div>
	              <div className="media" id="faq-interview-feature">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">Does ASTRNT have a live video interview feature?</h3>
	                  No. Our system uses “asynchronous video”, meaning that the system sends questions to the candidate and the candidate answers when they ready, not live. This allows the recruiter to invite many candidates at the same time, and get answers from them all very fast.
	                </div>
	              </div>
	              <div className="media" id="faq-why-video">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">Why video?</h3>
	                  CVs are very ineffective for effective screening, and are often full of exaggerated or false data. This why recruiters traditionally do time consuming and slow phone screening and initial screening face-to-face interviews that have high no-show rates. With video you can see the candidate’s facial expressions, and hear the clarity of their language skills and also see what they want to say as the answer to your question. Screen the people not CVs.
	                </div>
	              </div>
	              <div className="media" id="faq-anti-fraud">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">How to overcome candidate from cheating?</h3>
	                  When you design your Q&A Session, you can create the pressure of a real interview with “1 attempt only” to ensure the candidates give a spontaneous response. How do candidates answer the questions? Candidates will receive the email inviting them to answer the questions in either the "ASTRNT Q&A” iOS mobile app, the Android mobile app or their desktop web browser.
	                </div>
	              </div>
	              <div className="media" id="faq-how-to-answer">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">How do candidates answer the questions?</h3>
	                  Candidates will receive the email inviting them to answer the questions in either the “ASTRNT Q&A” iOS mobile app, the Android mobile app or their desktop web browser.
	                </div>
	              </div>
								<div className="media" id="faq-likes-astronaut">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">Do candidates like the experience of using ASTRNT? Why? </h3>
	                  Yes. Candidate love it because it saves them so much time, and it’s easy to use. They can answer the questions at a time suitable to their schedule, with no travelling or waiting.
	                </div>
	              </div>
	              <div className="media" id="faq-screening-time">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">Can I use ASTRNT for screening 1000’s of candidates in only a few days?  </h3>
	                  Yes, it doesn’t matter where your candidates are in the world, they can respond as long as they have internet access. There is no need for an appointment.
	                </div>
	              </div>
	              <div className="media" id="faq-internet-connection">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">What if the internet connection is not very good?</h3>
											With the iOS and Android mobile apps the internet is required to download the apps, and start the interview. However, if internet cuts out while the candidate is answering it won't be a problem because candidate answers are still in the mobile app and will be uploaded automatically when the candidates reconnect to the internet.
											For the candidates using their desktop web browser our system checked the interview speed and stability and if problematic the candidate will be informed to use either the iOS and Android mobile apps.
	                </div>
	              </div>
								<div className="media" id="faq-participation-rate">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">What is the candidate participation rate? </h3>
											Due to ASTRNT being much easier, faster and more time-saving than live calls and face-to-face interviews, the participation rate is always much higher than if conducted manually. Each case is different, and it is very normal for participation to be over 90%.
	                </div>
	              </div>
	              <div className="media" id="faq-contact-us">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">If candidates have question about ASTRNT, who should they contact?</h3>
	                  Candidates can either view <a href="http://astrnt.co/faq" target="_blank" rel="noopener noreferrer">http://astrnt.co/faq</a> or send an email to <a href="mailto:help@astrnt.co">help@astrnt.co</a>. During office hour Team ASTRNT support will respond fast, and often respond on weekends also.
	                </div>
	              </div>
	            </div>
							<div className="col-md-offset-4 col-md-8" id="htu-content">
	              <div className="media">
	                <div className="media-content">
	                  <h2 className="heading2 normal">How to Use Product</h2>
	                </div>
	              </div>
	              <div className="media" id="htu-getting-started">
									<div className="media-content">
	                  <h3 className="heading5 m-0">1. Getting Started</h3>
										<ul>
											<li>- Creating an account<br />Set up your account by contact us on <a href="mailto:support@astrnt.co">support@astrnt.co</a> or use our self sign up feature by click this link <a href="https://app.astrnt.co/auth/login">{`https://app.astrnt.co/auth/login`}</a></li>
											<li>- Updating company profile <br/>Complete the important information of your company that candidate will see. Company name, website, about company and company logo. </li>
										</ul>
	                </div>
	              </div>
	              <div className="media" id="htu-create-jobs">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">2. Create Jobs</h3>
											<ul>
												<li>- Job Title <br />
												Fill in the field of the position you are looking for
												</li>
												<li>- Candidate Language Interface <br />
												Choose the language the candidate will see on the Invitation, ASTRNT App or Web Browser.
												</li>
												<li>- Required Document <br />
													Choose the document you wish the candidate to submit in PDF format. Click Mandatory, click the Document type, the candidate will receipt daily email reminder until the candidate submit the document.
												</li>
												<li>- Screening vs sourcing</li>
												<li>- Change recruitment type to screening if you already have pool of candidate data.</li>
												<li>- Change recruitment type to sourcing if you aim to create tools where your candidate can directly apply to the  job landing page that you can customize. Set up your Q&A Session, and  implement the URL link to your home page, web career or other pages you need.</li>
												<li>- Job landing page <br />Create Job landing page where candidate can directly applied. Adding new field of candidate info that you need, and set the deadline. Contact us if you keen for full brand Job landing page.</li>
												<li>- Landing page video <br />Attract those talent! Time for you to show your candidate about your company, the culture, and about the role you are looking for.</li>
												<li>- Select Job Type <br />Ie Full Time, Part time, Internship, Contract or Fellowship</li>
												<li>- Select Job Industry <br />Select industry field of your company</li>
												<li>- Location <br />Fill in the location where this position is placed</li>
												<li>- About the job <br />Fill in the Job description that describes the general tasks, or other related duties of the position that the candidate will see in Job Landing page</li>
												<li>- Responsibilities <br />Fill in the Job responsibilities of the positions that the candidate will see in Job Landing page. It may specify the functionary to whom the position reports, specifications such as the qualifications or skills needed.</li>
												<li>- Job Requirements <br />Fill in the specific skills, types and amounts of work experience, personal qualities, educational credentials, professional certifications, or areas of knowledge.</li>
												<li>- Save Change Button <br />Click save change button to continue the next step</li>
												<li>- Back Button <br />Click Back button to go back to the Job list page.</li>
											</ul>
	                </div>
	              </div>
	              <div className="media" id="htu-candidates">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">3. Candidates </h3>
										<ul>
											<li>- Add manually <br />Click 'Add Candidate' button and complete the field. All you need is their Name, Preferred Name Email address and Location.</li>
											<li>- Import from file <br />If you’re adding more than 5 candidates we suggest you use the Bulk upload option. Download the template Form we provide for you and fill the the from of candidate data.</li>
											<li>- Send Question<br />Click select all or click the small box on the top right corner, click send question.</li>
											<li>- Candidates Preview <br />Make sure the list of candidate that you are wish to sent the question, click next.</li>
										</ul>
	                </div>
	              </div>
	              <div className="media" id="htu-question-page">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">4. Question Page</h3>
											<ul>
												<li>- Choosing questions/create Q&A sessions <br />Different question formats and test structures you can properly assess thousands of candidates in only a few days. </li>
												<li>- Section <br />Create unique tests that combine the different formats. For example, you can create a structured test with 2 video answers, 10 multiple-choice questions.</li>
												<li>- Add Section <br />Click Add Section, named your section, choose the time duration of your section test, add image if you wish the section needed images as part of the instruction of the test, add instruction for the test.</li>
												<li>- Choose Video Format to see the candidate’s facial expressions, and hear the clarity of their language skills. <br />Click add question, Add questions and you can create the pressure of a real interview with “1 attempt only” to ensure the candidates give a spontaneous response.</li>
												<li>- Choose MCQ Format Create your own basic culture and profiling tests – or test for any purpose. <br />Add questions, set up the test timer, choose “single option for test” if there is only one correct answer, or choose “multiple option for test” if there is more than two correct answer.</li>
												<li>- Randomize question.</li>
											</ul>
	                </div>
	              </div>
	              <div className="media" id="htu-sending-invites">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">5. Sending Invites</h3>
										<ul>
											<li>- Invitation <br />This is email invitation that your candidate will received.  Using the default template you can set the email subject, the sender and the deadline and add you personal message to the candidate.</li>
											<li>- Custom HTML templates <br />Upload HTML templates if you wish to have fully branded invitation.</li>
											<li>- Adjust the subject and the sender <br />Click the field on email subject and sender that is suitable for you company.</li>
											<li>- Deadline <br />Choose the date of the deadline your candidate can answer, in every 24 hours your candidate will receipt email notification ask them to do the test or your candidate can click ‘not interested’ button on the invitation and we ask why.</li>
											<li>- Sent invitation <br />Click Sent invitation button, your candidate will received the email in 45 - 60 sec.</li>
										</ul>
	                </div>
	              </div>
	              <div className="media" id="htu-filtering-candidate">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">6. Filtering candidate</h3>
										<ul>
											<li>- Viewing videos <br />Play the video to see the candidate answer, see the candidate expression, listen the audio of your candidate answer.</li>
											<li>- Viewing answers <br />Make fast collaborative screening decisions by using star-ratings, leave a comment for your colleagues to see and respond or thumbs up or down.</li>
											<li>- Candidate’s documents <br />Click document button to see the document that candidate sent when you Set “Require Document” to mandatory in creating the job.</li>
											<li>- Commenting <br />In candidate page, click your candidate name to view their answer,  leave a comment for your colleagues to see.</li>
											<li>- Mentioning others <br />Type your comment and tag your team member so they can get email notification about your comment. </li>
											<li>- Rating <br />Make fast collaborative screening decisions by using star-ratings.</li>
											<li>- Thumbs up/down <br />Respond to thumbs up/down for overall candidate answer.</li>
											<li>- Recruitment stages <br />Organize your candidate by move candidates to recruitment stages (sourced, applied, shortlisted, offer or disqualified), click the small the box on the top right corner, click the move to button and choose the stages you wish.</li>
											<li></li>
										</ul>
	                </div>
	              </div>
	              <div className="media" id="htu-reports">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">7. Reports</h3>
										<ul>
											<li>- Available report types</li>
											<li>- How to create</li>
											<li>- Sending reports.</li>
										</ul>
	                </div>
	              </div>
	              <div className="media" id="htu-hiring-team-collaboration">
	                <div className="media-content">
	                  <h3 className="heading5 m-0">8. Hiring Team Collaboration</h3>
										<ul>
											<li>- Add, edit, delete <br />Make collaborative screening decisions with your hiring team member,  add their email address, edit the notification that team member will receipt, and if you wish you can remove the team member from certain on job.</li>
											<li>- Roles & permissions <br />Select the roles of your recruiter team in the job you wish them to involve to “Recruiter” so they can add candidate, sent questions and view candidate answers. <br />Select the roles to “Guest” if you wish to make fast decisions from user or Top level manager where they can view only of candidate answer.</li>
											<li><br />- Company Account <br />Quota<br />API key<br />Branding <br />Suspended account</li>
										</ul>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	      </section>

				<Footer />
			</main>
		)
  }
}

export default FaqRecruiter
