import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Select from 'react-select'

class RenderSelectV2 extends React.Component {

    renderOptions(options=[]){
        const option = []

        options.map((obj,index)=>{
            option.push(<option key={obj.id} value={obj.value}>{obj.label}</option>)
        })
        
        return option
    }
	render() {
		const { input, label, formClass, placeholder, type, PassReqMobile, isValidatingEmail, options, meta: { touched, error } } = this.props

		return(
			<div className={`form-group ${formClass} ${touched && error && 'has-error'} ${isValidatingEmail && 'has-warning'}`}>
		    <label>{label}</label>
		    {PassReqMobile}
		    <div>
                
                <select
		    		{...input}
		    		className="form-control">
                <option value="" disabled selected>{placeholder}</option>
                {this.renderOptions(options)}
		    	
		    	</select>

		      {touched && error && <div className="help-block"><div dangerouslySetInnerHTML={{__html: error}}></div></div>}
		      {isValidatingEmail && <div className="help-block">Checking your email...</div>}
		    </div>
		  </div>
		)
	}
}


export default RenderSelectV2


// <Select
// // className={`form-control ${touched && error && 'error-form'}``}
// className={`select2 select-white `}
// // id="job-form_timezone"
// options={options}
// searchable={true}
// clearable={false}
// // menuPlacement="top"
// placeholder={placeholder}
// {...input}
// />
