import React from 'react'
import imgSupport from '../../images/public/support.svg'
import imgDevices from '../../images/public/devices.svg'
import imgCalChecked from '../../images/public/calendar-checked.svg'
import { connect } from 'react-redux'
import homeIcon4 from '../../images/icon-support-1.svg'
import homeIcon5 from '../../images/icon-support-2.svg'
import homeIcon6 from '../../images/icon-support-3.svg'
import {getOSInfo , translate } from '../../helpers/global'

class SupportListNew extends React.Component {
  render() {
    const{globalGetLanguages} = this.props
    return (
			<div className="sec-purple">
          <section>
            <ul className="feature">
              <li>
                <img src={homeIcon4} />
                <p>
                {translate(globalGetLanguages,'SUPPORT_CARD_LABEL_1')}
                  </p>
              </li>
              <li>
                <img src={homeIcon5} />
                <p>
                {translate(globalGetLanguages,'SUPPORT_CARD_LABEL_2')}
                  </p>
              </li>
              <li>
                <img src={homeIcon6} />
                <p>
                {translate(globalGetLanguages,'SUPPORT_CARD_LABEL_3')}
                 </p>
              </li>
            </ul>
          </section>
        </div>
		);
  }
}

const mapStateToProps = (state) => {
  return{ 
    globalGetLanguages:state.globalGetLanguages,
  }
  
}


export default connect(mapStateToProps, null )(SupportListNew)
